import React, {useEffect, useState} from "react";
import {makeStyles, useTheme} from "@mui/styles";
import {Header, Body, TitleBox} from "./Reporting.S";
import FlexContainer from "../common/FlexContainer";
import Button from "@mui/material/Button";
import {CircularProgress} from "@mui/material";
import RadarServiceFactory, {RadarServices} from "../../services";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import moment from "moment";

const ContentBox = styled.div`
  padding-top: 15px;
`

export function Reporting(props){
    
    const defaultCriteria = {
        startDate: null,
        endDate: null,
    }
    
    const theme = useTheme()
    const [fetchExcelStarted, setFetchExcelStarted] = useState(false)
    const [searchCriteria, setSearchCriteria] = useState(defaultCriteria)

    const useStyles = makeStyles(theme => ({
        formControl: {
            minWidth: 150,
            maxWidth: 150,
        },
        button: {
            color: '#FFF',
            marginTop: '10px',
        },
    }))
    
    useEffect(() => {
        const exportToExcel = async () => {
            const service = RadarServiceFactory.create(RadarServices.Reviews);
            service.exportReport(searchCriteria).then(res=>{
                setFetchExcelStarted(false)
            });
        }
        if (fetchExcelStarted){
            exportToExcel()
        }
    }, [fetchExcelStarted])
    
    const handleExportClick = async () => {

        setFetchExcelStarted(true);
    }

    const handleStartDateChange = date => {
        let startDate = null
        if (date) {
            startDate = moment(date).format('MM/DD/YYYY');
            setSearchCriteria({...searchCriteria, startDate})
        }
    }

    const handleEndDateChange = date => {
        let endDate = null
        if (date) {
            endDate = moment(date).format('MM/DD/YYYY');
            setSearchCriteria({...searchCriteria, endDate})
        }{}
    }
    
    const classes = useStyles()
    
    return(
        <FlexContainer
            className={theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}
            direction='column'
            padding='15px 15px 0px'
            alignItems='baseline'
            height='calc(100vh - 120px)'
            addProps='overflow-x: hidden; overflow-y: auto'>
            <div className='ml-5 col-md-12'>
                <TitleBox>
                    <Header>Reporting</Header>
                    <Body>Export sales reps' performance metrics</Body>
                </TitleBox>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <div className='row'>
                            <ContentBox className='col-md-6' style={{ marginTop: 0 }}>
                                <DatePicker
                                    maxDate={new Date()}
                                    label='Start Date'
                                    value={moment(searchCriteria.startDate)}
                                    onChange={handleStartDateChange}
                                    inputFormat='MM/DD/yyyy'
                                    renderInput={params => <TextField {...params} />}
                                />
                                <div style={{ display: 'inline', padding: '20px' }}>&nbsp;</div>
                                <DatePicker
                                    maxDate={new Date()}
                                    label='End Date'
                                    value={moment(searchCriteria.endDate)}
                                    onChange={handleEndDateChange}
                                    inputFormat='MM/DD/yyyy'
                                    renderInput={params => <TextField {...params} />}
                                />
                            </ContentBox>
                        </div>
                </LocalizationProvider>
                <div className='row' style={{ marginTop: 10 }}>
                    <div className='col-12'>
                        <Button
                            disableElevation
                            variant='contained'
                            className={`${classes.button}`}
                            color='success'
                            disabled={fetchExcelStarted || (!searchCriteria.startDate || !searchCriteria.endDate)}
                            onClick={handleExportClick}>
                            <div style={{display:'flex',alignItems:'center'}}>
                                {(fetchExcelStarted) ? <><span>Exporting...</span> <CircularProgress className={'ml-2'} style={{width:16,height:16}} /></>:<span>Export to Excel</span>}
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </FlexContainer>
        )
}