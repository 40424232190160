import React from 'react'
import { InputControl, Label } from './Input.S'

function Input(props) {
  return (
    <>
      {props.label && <Label htmlFor={props.inputId}>{props.label}</Label>}
      <InputControl
        id={props.inputId}
        name={props.name}
        key={props.inputId}
        disabled={props.disabled}
        ref={props.inputRef}
        required={props.required}
        min={props.min}
        maxlength={props.maxlength}
        type={props.inputType || 'text'}
        center={props.center}
        className={props.className}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onKeyUp={props.onKeyUp}
        height={props.height}
        padding={props.padding}
        margin={props.margin}
        width={props.width}
        variant={props.variant}>
        {props.children}
      </InputControl>
    </>
  )
}

export default Input
