import styled from 'styled-components'

export const Nav = styled.nav`
  position: relative;
  display: flex;
  flex-flow: row nowrap !important;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${props => props.theme.background.dark};
`
