import React, { useEffect } from 'react'
import * as FullStory from '@fullstory/browser'
import styled, { keyframes } from 'styled-components'

function FullStoryIndicator({ enable, userId, displayName }) {
  useEffect(() => {
    if (enable) {
      console.log(`starting fullstory for ${displayName}}`)
      FullStory.restart()
      FullStory.identify(userId, { displayName })
    } else {
      console.log(`stopping fullstory for ${displayName}}`)
      FullStory.shutdown()
    }
  }, [enable])

  return null
}

export default FullStoryIndicator

/** Saving this Pulse effect may come in handly later
  
 const pulseAnimiation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(26,223,117, 0.4);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(26,223,117, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(26,223,117, 0);
  }
`

const Pulse = styled.span`
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #17c768;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(26, 223, 117, 0.4);
  animation: ${pulseAnimiation} 2s infinite;
  :hover {
    animation: none;
  }
`

 */
