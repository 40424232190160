import React from 'react'
import SimpleModal from '../../common/SimpleModal'
import BodyText from '../../common/BodyText'
import FlexContainer from '../../common/FlexContainer'
import Button from '../../common/Button'

export default function ValidationModal({ show, message, onClose }) {
  return (
    <SimpleModal title='Missing Required Fields' open={show} onClick={onClose}>
      <FlexContainer>
        <BodyText>{message}</BodyText>
      </FlexContainer>
      <FlexContainer className='pt-4'>
        <div className='col-md-9'></div>
        <Button className='col-md-2' variant='main' onClick={onClose}>
          Close
        </Button>
      </FlexContainer>
    </SimpleModal>
  )
}
