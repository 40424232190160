import React, { useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { EditorContainer, SavingText } from './Shared.S'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import ButtonSpinner from '../../common/ButtonSpinner'
import TextEditor from '../../common/TextEditor'
import ValidationModal from '../common/ValidationModal'

function NewTemplateEditor(props) {
  const { onClose, onSave, updateStarted } = props
  const [emailTemplate, setEmailTemplate] = useState({ title: '', subject: '', content: '' })
  const [showModal, setShowModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const showErrorDialog = msg => {
    setErrorMessage(msg)
    setShowModal(true)
  }

  const handleClose = () => setShowModal(false)

  const handleTitleChanged = e => {
    let updated = { ...emailTemplate }
    updated.title = e.target.value
    setEmailTemplate(updated)
  }

  const handleSubjectChanged = e => {
    let updated = { ...emailTemplate }
    updated.subject = e.target.value
    setEmailTemplate(updated)
  }

  const handleBodyChanged = (data, delta, source, editor) => {
    let updated = { ...emailTemplate }
    if (editor.getText().length <= 1) data = ''
    updated.content = data
    setEmailTemplate(updated)
  }

  const handleSave = () => {
    if (emailTemplate.title.length === 0) {
      showErrorDialog('Title is required please fill in this area')
      return
    }

    if (emailTemplate.subject.length === 0) {
      showErrorDialog('Subject is required please fill in this area')
      return
    }

    if (emailTemplate.content.length === 0) {
      showErrorDialog('Content is required please fill in this area')
      return
    }

    onSave(emailTemplate)
  }

  return (
    <>
      <EditorContainer>
        <div className='header'>
          <p>
            <span className='bolder'>ADD NEW</span> Email Template
          </p>
          <FaTimes className='closeButton' onClick={onClose} />
        </div>
        <div className='inputs'>
          <TextField
            className='col-md-5'
            placeholder='Email Template Title'
            value={emailTemplate.title}
            onChange={handleTitleChanged}
            variant='outlined'
            margin='dense'
            required
          />
          &nbsp;
          <TextField
            className='col-md-7'
            placeholder='Email Template Subject'
            value={emailTemplate.subject}
            onChange={handleSubjectChanged}
            variant='outlined'
            margin='dense'
            required
          />
        </div>
        <TextEditor
          placeholder='Draft Email Template HERE'
          body={emailTemplate.content}
          onChange={handleBodyChanged}
        />
        <div className='controls pull-right'>
          <Button disableElevation variant='outlined' color='primary' onClick={onClose}>
            CANCEL
          </Button>
          <ButtonSpinner
            disableElevation
            style={{ marginLeft: '20px', color: '#FFF' }}
            variant='contained'
            color='primary'
            spin={updateStarted}
            spinContent={<SavingText>Saving..</SavingText>}
            onClick={handleSave}>
            Save
          </ButtonSpinner>
        </div>
      </EditorContainer>
      <ValidationModal show={showModal} message={errorMessage} onClose={handleClose} />
    </>
  )
}

export default NewTemplateEditor
