import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FaEarlybirds, FaAngellist, FaFortAwesomeAlt, FaJediOrder } from 'react-icons/fa'
import RadarServiceFactory, { RadarServices } from '../../../services'
import { setAchievementAction } from '../../../store/actions/achievement'
import { Container } from './index.S'

function AchievementIndicator({ achievementCount, setAchievementCount }) {
  const [message, setMessage] = useState('')

  const getMetrics = async () => {
    let service = RadarServiceFactory.create(RadarServices.Reviews),
      result = await service.getMetrics()
    if (result.Ok) {
      let count = result.Content.filter(
        a => a.unitName === 'ManuallyPosted' || a.unitName === 'PublishedDirectly'
      )
        .map(a => a.value)
        .reduce((prev, curr) => prev + curr)
      setAchievementCount(count)
    }
  }

  const generateBadge = count => {
    if (count >= 20000) return <FaJediOrder className='icon' />
    else if (count >= 15000) return <FaFortAwesomeAlt className='icon' />
    else if (count >= 7000) return <FaAngellist className='icon' />
    else if (count >= 1000) return <FaEarlybirds className='icon' />
    else return null
  }

  useEffect(() => {
    getMetrics()
  }, [])

  useEffect(() => {
    setMessage(`Great Job! You've closed ${achievementCount} reviews`)
  }, [achievementCount])

  return (
    <Container>
      <span className='tooltiptext'>{message}</span>
      {generateBadge(achievementCount)}
    </Container>
  )
}

const mapStateToProps = state => {
  return {
    achievementCount: state.achievement.count,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAchievementCount: count => dispatch(setAchievementAction(count)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AchievementIndicator)
