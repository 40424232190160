import { handleAction } from 'redux-actions'
import reduceReducers from 'reduce-reducers'
import { ActionTypes, ActionIDs } from '../actions/reviews'

const defaultState = {}
const defaultFolders = [
  { displayName: 'My Queue', actionId: ActionIDs.MY_QUEUE, count: 0 },
  { displayName: 'Proposed', actionId: ActionIDs.PROPOSED, count: 0 },
  { displayName: 'Expired Proposals', actionId: ActionIDs.EXPIRED_PROPOSALS, count: 0 },
  { displayName: 'Escalated', actionId: ActionIDs.ESCALATED, count: 0 },
  { displayName: 'Disputed', actionId: ActionIDs.DISPUTED, count: 0 },
]
const defaultFilters = {
  clients: { filters: [] },
  tags: { exclude: false, filters: [] },
    postedDate: { startDate: null, endDate: null, startDateActual:null , endDateActual:null },
  sentiments: { filters: [] },
  miscellaneous: { filters: [] },
  sites: { filters: [] },
  statuses: { filters: [] },
}

// ------------------------- Results Paging

const handleFetchedPagedReviewsReducer = handleAction(
  ActionTypes.REVIEWS_FETCH_PAGED_REVIEWS,
  {
    next(state, action) {
      return {
        ...state,
        page: action.payload.page,
      }
    },
  },
  defaultState
)

// ------------------------- Reviews Filters

const handleFilterSearchSelectedReducer = handleAction(
  ActionTypes.REVIEWS_FILTERS_SELECTED,
  {
    next(state, action) {
      return {
        ...state,
        showFilter: false,
        activeFolderId: ActionIDs.MY_QUEUE,
        filters: action.payload.filters,
        filterQuery: action.payload.filterQuery,
        fetchQuery: action.payload.filterQuery,
        pageSize: action.payload.pageSize,
        page: action.payload.page,
        clear: false,
      }
    },
  },
  defaultState
)

const handleClearFilteredReviewsReducer = handleAction(
  ActionTypes.REVIEWS_FILTERS_CLEAR_REVIEWS,
  {
    next(state, action) {
      return {
        ...state,
        clear: true,
        filters: action.payload.filters,
        folders: defaultFolders,
        fetchQuery: null,
        filterQuery: null,
        showFilter: false,
      }
    },
  },
  defaultState
)

// ------------------------- Navbar Filters

const handleNavbarFilterSelectedReducer = handleAction(
  ActionTypes.REVIEWS_NAVBAR_FILTER_SELECTED,
  {
    next(state, action) {
      return {
        ...state,
        showFilter: action.payload.active,
      }
    },
  },
  defaultState
)

// ------------------------- Navbar Folders

const handleNavbarFolderSelectedReducer = handleAction(
  ActionTypes.REVIEWS_NAVBAR_FOLDER_SELECTED,
  {
    next(state, action) {
      if (action.payload.query) {
        return {
          ...state,
          activeFolderId: action.payload.folderId,
          fetchQuery: action.payload.query,
          pageSize: action.payload.pageSize,
          page: action.payload.page,
        }
      } else {
        return { ...state, activeFolderId: action.payload.folderId }
      }
    },
  },
  defaultState
)

const handleNavbarFoldersLoadStartedReducer = handleAction(
  ActionTypes.REVIEWS_NAVBAR_LOAD_FOLDERS,
  {
    next(state, action) {
      return {
        ...state,
        foldersLoaded: false,
      }
    },
  },
  defaultState
)

const handleNavbarFoldersLoadCompletedReducer = handleAction(
  ActionTypes.REVIEWS_NAVBAR_LOAD_FOLDERS_COMPLETED,
  {
    next(state, action) {
      return {
        ...state,
          folders: (action.payload != null && action.payload.folders) || defaultFolders,
        foldersLoaded: true,
      }
    },
  },
  defaultState
)

const initialState = {
  page: 1,
  pageSize: 100,
  showFilter: false,
  reviewId: 0,
  fetchCompleted: true,
  fetchStarted: false,
  filterQuery: null,
  fetchQuery: null,
  filters: defaultFilters,
  activeFolderId: ActionIDs.MY_QUEUE,
  foldersLoaded: false,
  folders: defaultFolders,
  clear: true,
}

export default () =>
  reduceReducers(
    initialState,
    handleNavbarFolderSelectedReducer,
    handleFetchedPagedReviewsReducer,
    handleFilterSearchSelectedReducer,
    handleNavbarFilterSelectedReducer,
    handleNavbarFoldersLoadStartedReducer,
    handleNavbarFoldersLoadCompletedReducer,
    handleClearFilteredReviewsReducer
  )
