import styled, { css, keyframes } from 'styled-components'

export const Container = styled.div`
  display: inline-block;
  padding: 0 45px 0 30px;
  border-right: 1px solid
    ${props => (props.theme.type === 'dark' ? props.theme.background.dark : props.theme.foreground.lightest)};
  margin-right: 30px;
  margin-top: 18px;

  ul {
    list-style-type: none;
    margin-top: 0;
  }
`

export const TitleContainer = styled.div`
  text-align: left;
  margin: 20px 0 0 70px;
  width: 100%;
  min-height: 28px;
  font-family: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
`

export const ConfiguratorContainer = styled.div`
  position: relative;
  z-index: 4;
  display: flex;
  justify-content: top;
  margin-top: 18px;
  width: 100%;
`

export const HrLineBreak = styled.hr`
  display: block;
  position: relative;
  padding: 0;
  margin: 8px 0;
  height: 0;
  width: 120px;
  max-height: 0;
  font-size: 1px;
  line-height: 0;
  clear: both;
  border: none;
  border-top: 1px solid ${props => (props.theme.type === 'dark' ? props.theme.background.darker : '#dedede')};
  border-bottom: 1px solid ${props => (props.theme.type === 'dark' ? props.theme.background.dark : '#ffffff')};
`

export const TabContainer = styled.ul``

const hoverStyles = props => {
  const color = props.selected ? props.theme.primary.main : props.theme.foreground.main
  return `
        border-left: 2px solid ${color};
        color: ${color};
        font-weight: bold;
        span {
            color: ${color};
        }
    `
}

export const TabItemContainer = styled.li`
  span {
    text-decoration: none;
    color: ${props => props.theme.foreground.lighter};
  }

  border-left: 2px solid transparent;
  font-size: 18px;
  margin-bottom: 16px;
  padding-left: 16px;
  width: 150px;
  ${props => (props.selected ? hoverStyles(props) : '')}
  &:hover {
    cursor: pointer;
    transition: border-left 0.15s ease-in-out;
    ${props => hoverStyles(props)}
  }
`
