import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@mui/material/Typography'
import Comments from './Comments'
import CommentLine from './CommentLine'
import { CommentView, CommentChatBubble, CommentContainer } from './ResponseComments.S'
import RadarSession from '../../../lib/session'
import {
  saveCommentStartedAction,
  saveCommentCompletedAction,
  clearCommentStateAction,
} from '../../../store/actions/comments'
import { addNotificationAction, NotificationType } from '../../../store/actions/notifications'

function ResponseComments(props) {
  const {
    review,
    saveStarted,
    saveCompleted,
    newComment,
    comments,
    saveComment,
    addNotification,
    resetCommentState,
  } = props
  const [editIndex, setEditIndex] = useState(-1)

  useEffect(() => {
    if (saveCompleted) {
      if (newComment) addNotification('Comment Saved!', NotificationType.SUCCESS_NOTIFICATION)
      else addNotification('Failed to save comment!', NotificationType.ERROR_NOTIFICATION)
      resetCommentState()
      setEditIndex(-1)
    }
  }, [saveStarted])

  const handleSaveComment = comment => {
    if (!saveStarted) {
      saveComment(review.reviewId, { comment: comment })
    }
  }

  const handleUpdateClick = (text, comment) => {
    if (text.length > 0) {
      comment.content = text
      saveComment(review.reviewId, { comment: comment })
    }
  }

  const handleEditClick = comment => {
    if (comment) {
      setEditIndex(comment.id)
    } else {
      setEditIndex(-1)
    }
  }

  return (
    <>
      <CommentView className='d-flex flex-row pt-2 pb-2 justify-content-start mt-2'>
        <div className='align-self-center'>
          <CommentChatBubble />
        </div>
        <div className='align-self-center pl-3 pr-3'>
          <Typography variant='subtitle2'>Comments</Typography>
        </div>
        <div className='flex-grow-1 '>
          <Comments
            saveStarted={saveStarted}
            saveSucceeded={newComment !== null}
            onSaveComment={handleSaveComment}
          />
        </div>
      </CommentView>
      <div className='d-flex flex-column ml-5'>
        {comments.map((comment, index) => (
          <CommentContainer key={index}>
            <CommentLine
              comment={comment}
              editMode={comment.id === editIndex}
              saveStarted={saveStarted}
              onEditClick={handleEditClick}
              onUpdateClick={handleUpdateClick}
            />
          </CommentContainer>
        ))}
      </div>
    </>
  )
}

function mapStateToProps(state) {
  let session = new RadarSession(),
    sessionState = session.getSessionState(),
    review = state.review.review

  let comment = state.comments.newComment
  if (comment && state.comments.saveCompleted) {
    let exists = review.comments.filter(c => c.reviewCommentId === comment.reviewCommentId)
    if (exists.length === 0) review.comments.splice(0, 0, comment)
  }

  if (sessionState) {
    review.comments
      .filter(comment => comment.author.email === sessionState.profile.email)
      .forEach(comment => (comment.isUserComment = true))
  }

  return {
    review: state.review.review,
    comments: state.review.review.comments,
    saveStarted: state.comments.saveStarted,
    saveCompleted: state.comments.saveCompleted,
    newComment: state.comments.newComment,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveComment: (reviewId, comment) => {
      dispatch(saveCommentStartedAction())
      dispatch(saveCommentCompletedAction(reviewId, comment))
    },
    resetCommentState: () => dispatch(clearCommentStateAction()),
    addNotification: (text, status) => dispatch(addNotificationAction(text, status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseComments)
