import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import RadarServiceFactory, { RadarServices } from '../../services'
import { addNotificationAction, NotificationType } from '../../store/actions/notifications'
import { pageSelectedAction } from '../../store/actions/tags'
import TagEditor from './TagsComponents/TagEditor'
import ProgressLoader from '../common/ProgressLoader'
import FlexContainer from '../common/FlexContainer'
import Button from '../common/Button'
import { ItemContainer, TitleBox, Header, SearchInput } from './TagsComponents/Shared.S'
import { makeStyles, useTheme } from '@mui/styles'
import Pagination from '@mui/lab/Pagination'

const useStyles = makeStyles({
  pager: {
    '& .Mui-selected': {
      color: '#FFF !important',
    },
  },
})

function Tags(props) {
  const classes = useStyles()
  const theme = useTheme()
  const { addNotification, pageSelected, page, pageCount } = props
  const [searchData, setSearchData] = useState('')
  const [showEditor, setShowEditor] = useState(false)
  const [newTag, setNewTag] = useState({})
  const [tags, setTags] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [fetchStarted, setFetchStarted] = useState(false)
  const [updateStarted, setUpdateStarted] = useState(false)
  const containerRef = useRef(null)

  const handleNewTemplateClick = () => {
    setNewTag({
      dsiplayName: '',
      description: '',
      clients: [],
    })
    setShowEditor(true)
    containerRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const fetchTags = async page => {
    setFetchStarted(true)

    const service = RadarServiceFactory.create(RadarServices.Tags),
      retval = await service.getTags({ page, count: pageCount })
    if (retval.Ok) {
      setTags(retval.Content.tags)
      setTotalPages(Math.ceil(retval.Content.totalCount / pageCount))
      setTotalCount(retval.Content.totalCount)
    } else {
      setTags([])
      setTotalCount(0)
      setTotalPages(0)
    }

    setFetchStarted(false)
  }

  const handleNewSaveClick = async tag => {
    if (!updateStarted) {
      setUpdateStarted(true)

      const service = RadarServiceFactory.create(RadarServices.Tags),
        retval = await service.createTag(tag)
      if (retval.Ok) {
        setShowEditor(false)
        setTags([...tags, retval.Content])
        setTotalCount(totalCount + 1)

        let newpage = Math.ceil((totalCount + 1) / pageCount)
        pageSelected(newpage)

        addNotification({
          type: NotificationType.SUCCESS_NOTIFICATION,
          text: 'Update completed successfully',
        })
      } else {
        addNotification({
          type: NotificationType.ERROR_NOTIFICATION,
          text: 'Update failed!',
        })
      }

      setUpdateStarted(false)
    }
  }

  const handleEditSaveClick = async tag => {
    if (!updateStarted) {
      setUpdateStarted(true)

      const service = RadarServiceFactory.create(RadarServices.Tags),
        retval = await service.updateTag(tag)
      if (retval.Ok) {
        setShowEditor(false)

        let ndx = tags.findIndex(a => a.tagId === tag.tagId)
        tags.splice(ndx, 1, tag)
        setTags(tags)

        addNotification({
          type: NotificationType.SUCCESS_NOTIFICATION,
          text: 'Update completed successfully',
        })
      } else {
        addNotification({
          type: NotificationType.ERROR_NOTIFICATION,
          text: 'Update failed miserably.. Go tell someone please',
        })
      }

      setUpdateStarted(false)
    }
  }

  const handleDeleteClick = async tag => {
    if (!updateStarted) {
      setUpdateStarted(true)

      const service = RadarServiceFactory.create(RadarServices.Tags),
        retval = await service.deleteTag(tag.tagId)
      if (retval.Ok) {
        setShowEditor(false)
        setTotalCount(totalCount - 1)

        let filtered = tags.filter(a => a.tagId !== tag.tagId)
        setTags(filtered)

        let newpage = Math.ceil((totalCount - 1) / pageCount)
        pageSelected(newpage)

        addNotification({
          type: NotificationType.SUCCESS_NOTIFICATION,
          text: 'Update completed successfully',
        })
      } else {
        addNotification({
          type: NotificationType.ERROR_NOTIFICATION,
          text: 'Update failed miserably.. Go tell someone please',
        })
      }

      setUpdateStarted(false)
    }
  }

  const handlePageChangeClick = async (e, value) => {
    pageSelected(value)
  }

  useEffect(() => {
    fetchTags(page)
  }, [page])

  return (
    <FlexContainer padding='15px 15px 0px' alignItems='baseline' width='100%'>
      <div className='ml-5 col-md-6'>
        <TitleBox style={{ paddingLeft: '10px' }}>
          <Header>Tags</Header>
          <p>
            A grouping of client accounts that can be used to quickly filter reviews in the queue. Tags are
            available in the Review Workbench.
          </p>
        </TitleBox>
        <FlexContainer direction='row'>
          <SearchInput
            className='col-md-8'
            type='text'
            placeholder='Search Tags'
            value={searchData}
            onChange={e => setSearchData(e.target.value)}
            disabled={showEditor}
          />
          <Button
            className='btn das-primary-bg text-white col-md-3'
            disabled={showEditor}
            type='button'
            onClick={handleNewTemplateClick}>
            Create New
          </Button>
        </FlexContainer>
        <ProgressLoader Hide={!fetchStarted} />
        <ItemContainer
          ref={containerRef}
          className={theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}>
          {showEditor && (
            <TagEditor
              title='New Tag'
              tag={newTag}
              editing={true}
              handleSubmit={handleNewSaveClick}
              handleClose={() => setShowEditor(!showEditor)}
              updateStarted={updateStarted}
            />
          )}
          {tags
            .filter(tag => {
              if (!searchData) return true
              let data = searchData.toLowerCase()
              return (
                tag.displayName.toLowerCase().includes(data) || tag.description.toLowerCase().includes(data)
              )
            })
            .map(tag => (
              <TagEditor
                title='Edit Tag'
                tag={tag}
                key={tag.tagId}
                editing={showEditor}
                updateStarted={updateStarted}
                handleSubmit={handleEditSaveClick}
                handleDelete={handleDeleteClick}
              />
            ))}
        </ItemContainer>
        {totalPages > 1 && (
          <div className='col-12 d-flex justify-content-center bg-light border pt-3 pb-3'>
            <Pagination
              className={classes.pager}
              page={page}
              count={totalPages}
              color='primary'
              onChange={handlePageChangeClick}
            />
          </div>
        )}
      </div>
    </FlexContainer>
  )
}

function mapStateToProps(state) {
  return {
    page: state.tags.page,
    pageCount: 25,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addNotification: notification => {
      dispatch(addNotificationAction(notification.text, notification.type))
    },
    pageSelected: page => {
      dispatch(pageSelectedAction(page))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tags)
