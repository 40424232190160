export default class Service {
  async get(url) {
    return await this.__executeMethod('GET', url)
  }

  async post(url, data) {
    return await this.__executeMethod('POST', url, data)
  }

  async put(url, data) {
    return await this.__executeMethod('PUT', url, data)
  }

  async delete(url) {
    return await this.__executeMethod('DELETE', url)
  }

  onError(result) {
    console.error(result)
  }

  async __executeMethod(method, url, data = null) {
    let noop = function () {}

    let settings = {
      headers: this.__getHeaders(),
      method: method,
      redirect: 'manual',
      beforeSend: noop,
      success: noop,
      error: this.onError,
    }

    if (data) {
      settings.body = JSON.stringify(data)
      settings.headers['Content-Type'] = 'application/json'
    }

    let result = {},
      resp = await fetch(encodeURI(url), settings)
    if (resp.status >= 200 && resp.status <= 226) {
      if (method === 'GET' || method === 'POST') {
        result = { Ok: true, Content:  (resp.status != 204) ? await resp.json():null }
        settings.success(result, resp)
      } else {
        result = { Ok: true, Content: {} }
        settings.success(result, resp)
      }
    } else {
      let content = await resp.json()
      result = {
        Ok: false,
        Message: content.reason ?? resp.statusText,
        StatusCode: resp.type === 'opaqueredirect' ? 307 : resp.status,
        Content: content,
      }

      settings.error(result, resp)
    }

    return result
  }

  __getHeaders() {
    return {
      Authorization: `Bearer ${window.AccessToken}`,
      'Cache-Control': 'no-cache',
      Connection: 'keep-alive',
      'Accept-Encoding': 'gzip, deflate, br',
    }
  }
}
