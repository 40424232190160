import styled from 'styled-components'

export const AccountContainer = styled.div`
  & :hover {
    background-color: ${props => (props.theme.type === 'dark' ? props.theme.background.dark : '#eae6e3')};
    border-radius: 20px;
  }
`

export const AccountMenu = styled.div`
  min-width: 175px;
  border-radius: 5px;
  border: 1px solid ${props => (props.theme.type === 'dark' ? props.theme.background.dark : '#eae6e3')};
  background-color: ${props => (props.theme.type === 'dark' ? props.theme.background.main : '#FFF')};
  z-index: 901;

  & :hover {
    background-color: ${props => (props.theme.type === 'dark' ? props.theme.background.dark : '#eae6e3')};
  }
`
