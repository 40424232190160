import React from 'react'
import * as ModalStyles from './SimpleModal.S'

function SimpleModal(props) {
  const { id, children, open, title, onClick } = props

  const handleClick = e => {
    e.preventDefault()
    if (onClick) onClick()
  }

  return (
    <ModalStyles.Container id={id} open={open} onClick={handleClick}>
      <ModalStyles.Main>
        <ModalStyles.Title>{title}</ModalStyles.Title>
        {children}
      </ModalStyles.Main>
    </ModalStyles.Container>
  )
}

export default SimpleModal
