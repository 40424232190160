import styled from 'styled-components'
import SimpleModal from '../../../common/SimpleModal'

export const Editor = styled.div`
  margin-bottom: 20px;
  max-height: 325px;
`

export const EmailTemplateModal = styled(SimpleModal)`
  max-height: 325px;
  height: 50%;
`

export const InternalEditor = styled.div`
  max-height: 225px;
  overflow-y: scroll;
`

export const EmailSubjectLine = styled.div`
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: solid 1px hsl(0, 0%, 80%);
  margin-bottom: 5px;

  input {
    border: none;
    height: 40px;
    margin: 0px;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    background-color: ${props => props.theme.background.main};
    color: ${props => props.theme.foreground.main};
  }

  input::-webkit-input-placeholder {
    /* Edge */
    color: ${props => props.theme.foreground.lighter};
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${props => props.theme.foreground.lighter};
  }

  input::placeholder {
    color: ${props => props.theme.foreground.lighter};
  }
`
