import './ReviewSite.css'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import FlexContainer from '../../common/FlexContainer'
import CheckBox from '../../common/CheckBox'
import ButtonSpinner from '../../common/ButtonSpinner'
import ProgressLoader from '../../common/ProgressLoader'
import { ConfigPrimaryText, ConfigItem, TabBox, ConfigBox } from './ReviewSite.S'
import { TitleBox, Header, Body, ContentBox, SavingText } from './Shared.S'
import { updateAccountStartedAction, updateAccountCompletedAction } from '../../../store/actions/account'
import ReviewServiceFactory, { RadarServices } from '../../../services'

function ReviewSites(props) {
  const { account, updateStarted, updatedAccount, saveAccount } = props

  const [selectAll, setSelectAll] = useState(false)
  const [accountSites, setAccountSites] = useState(account.reviewSites)
  const [sites, setSites] = useState([])
  const [fetchStarted, setFetchStarted] = useState(true)

  const ReviewSiteEntryView = ({ id, displayName, checked }) => (
    <ConfigItem key={id}>
      <CheckBox id={id} onChanged={handleCheckClick} checked={checked} />
      <div style={{ position: 'absolute', margin: '-29px 0 0 30px' }}>
        <ConfigPrimaryText>{displayName}</ConfigPrimaryText>
      </div>
    </ConfigItem>
  )

  const handleCheckClick = (e, checked) => {
    const siteId = e.id
    if (checked) {
      let site = sites.find(a => a.reviewSiteId === siteId)
      setAccountSites([...accountSites, site])
    } else {
      let rndx = accountSites.findIndex(a => a.reviewSiteId === siteId)
      setAccountSites(accountSites.filter((a, ndx) => ndx !== rndx))
    }
  }

  const handleSelectAllClick = (e, checked) => {
    setSelectAll(checked)
    setAccountSites(checked ? sites.flat() : [])
  }

  const handleSaveClick = () => {
    if (!updateStarted) {
      saveAccount(account.clientId, {
        reviewSites: accountSites,
      })
    }
  }

  useEffect(() => {
    if (updatedAccount?.succeeded) {
      account.reviewSites = updatedAccount.content.reviewSites
    }
  }, [updatedAccount])

  useEffect(() => {
    const doFetchSites = async () => {
      let service = ReviewServiceFactory.create(RadarServices.ReviewSites),
        result = await service.getReviewSites()
      if (result.Ok) setSites(result.Content)

      setFetchStarted(false)
    }

    doFetchSites()
  }, [])

  return (
    <>
      {fetchStarted ? (
        <ProgressLoader />
      ) : (
        <FlexContainer justifyContent='start' direction='column' width='70%'>
          <ContentBox>
            <TitleBox>
              <Header>Review Sites</Header>
              <Body>Add or remove review sites from which reviews will be ingested.</Body>
            </TitleBox>
          </ContentBox>
          <ContentBox className='pt-3'>
            <TabBox>
              <CheckBox checked={selectAll} onChanged={handleSelectAllClick} />
              <div className='das-primary header pl-1'>{selectAll ? 'Deselect All' : 'Select All'}</div>
            </TabBox>
          </ContentBox>
          <ContentBox>
            <ConfigBox>
              {sites.map(rs => {
                let checked =
                  accountSites.findIndex(a => a.enabled && a.reviewSiteId === rs.reviewSiteId) !== -1
                return (
                  <ReviewSiteEntryView
                    key={rs.reviewSiteId}
                    id={rs.reviewSiteId}
                    checked={checked}
                    displayName={rs.displayName}
                  />
                )
              })}
            </ConfigBox>
          </ContentBox>
          <ContentBox>
            <ButtonSpinner
              disableElevation
              style={{ color: '#FFF' }}
              variant='contained'
              color='primary'
              spin={updateStarted}
              spinContent={<SavingText>Saving..</SavingText>}
              onClick={handleSaveClick}>
              Save
            </ButtonSpinner>
          </ContentBox>
        </FlexContainer>
      )}
    </>
  )
}

function mapStateToProps(state) {
  return {
    account: state.account.account,
    updateStarted: state.account.updateStarted,
    updatedAccount: state.account.updatedAccount,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveAccount: (clientId, account) => {
      dispatch(updateAccountStartedAction())
      dispatch(updateAccountCompletedAction(clientId, account))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSites)
