import styled from 'styled-components'
import Title from '../../common/Title'
import BodyText from '../../common/BodyText'

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
`

export const TitleBox = styled.div`
  display: inline-block;
  font-size: 0.9em;
`

export const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  label {
    padding-right: 50px;
    font-size: 0.98em;
    margin-top: 3px;
  }
`

export const Header = styled(Title)`
  font-size: 1.4em;
  padding-bottom: 10px;
  color: ${props => props.theme.text.secondary};
`

export const Body = styled(BodyText)`
  line-height: 1.5;
`

export const SavingText = styled.div`
  color: #fff;
  padding-top: 1px;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => (props.padding ? props.padding : '')};
  ${props => (props.border ? `border: ${props.border};` : '')}
  align-items: flex-start;
  justify-content: flex-start;
  background: ${props =>
    props.theme.type === 'dark' ? props.theme.background.dark : props.theme.background.main};
  max-height: ${props => (props.maxHeight ? props.maxHeight : '40px')};
  width: ${props => (props.width ? props.width : '100%')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '3px')};
  border: solid 1px ${props => props.theme.foreground.lightest};
  margin: ${props => (props.margin ? props.margin : '5px')};
  transition: all 0.3s linear;
  ${props =>
    props.noHover
      ? null
      : `&:hover {
                box-shadow: 0px 0px 10px rgba(57, 58, 60, 0.1);
                background: ${props.theme.background.dark};
                cursor: pointer;
            }`}
  ${props => (props.height ? props.height : null)}
    ${props => (props.override ? props.override : null)}
`

export const IconButton = styled.span`
  ${props => (props.addProps ? props.addProps : null)}
  svg {
    color: ${props => props.theme.foreground.lighter};
  }
  &:hover,
  &:active {
    transition: all 0.3s ease;
    cursor: pointer;

    svg {
      color: ${props => props.theme.primary.light};
    }
  }
`

export const HrLineBreak = styled.hr`
  display: block;
  position: relative;
  padding: 0;
  margin: 8px auto;
  height: 0;
  width: ${props => (props.width ? props.width : '95%')};
  max-height: 0;
  font-size: 1px;
  line-height: 0;
  clear: both;
  border: none;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #ffffff;
`
