import React, { useEffect } from 'react'

function SLATimer({ review, onUpdate }) {
  const hasResponded = ['Posted', 'RemovedAtReviewSite', 'NotRemovedAtReviewSite', 'DoNotRespond', 'Closed', 'Ready'].includes(
    review.status
  )

  const datePostedSeconds =
    review.status === 'Proposed'
      ? Date.parse(review.reviewProposedDate) / 1000
      : Date.parse(review.reviewDate) / 1000

  const responseDueDateSeconds =
    review.status === 'Proposed'
      ? Date.parse(review.proposalApprovalExpirationDate) / 1000
      : Date.parse(review.lastModifiedByReviewRate ? review.lastModifiedByReviewRate.modifiedDate : review.responseDueDate) / 1000

  const getPercentage = time => {
    const slaSeconds = responseDueDateSeconds - datePostedSeconds,
      percentage = Math.floor(((slaSeconds - time) / slaSeconds) * 100)
    return Math.min(Math.max(0, percentage), 100) // clamp
  }

  const getTimeText = time => {
    let days = Math.abs(Math.ceil(time / 86400)),
      hours = Math.abs(Math.floor(time / 3600)),
      minutes = Math.abs(Math.floor((time % (60 * 60)) / 60)),
      seconds = Math.abs(Math.floor(time % 60))

    if (time >= 600) {
      return `${hours !== 0 ? `${hours}h ` : ''}${minutes !== 0 ? `${minutes}m ` : ''}`
    } else if (time <= -600) {
      return days <= 0
        ? `${hours !== 0 ? `${hours}h ` : ''}${minutes !== 0 ? `${minutes}m ` : ''}`
        : `${days} day${days > 1 ? 's' : ''}`
    } else {
      return `${minutes !== 0 ? `${minutes}m ` : ''}${seconds !== 0 ? `${seconds}s ` : ''}`
    }
  }

  const getTimeLeft = () => responseDueDateSeconds - Date.now() / 1000

  const updateView = () => {
    let time = getTimeLeft()
    onUpdate({
      time,
      timeLeft: getTimeLeft(),
      hasResponded,
      percentage: getPercentage(time),
      timeText: getTimeText(time),
    })
  }

  useEffect(() => {
    let timer,
      timeleft = getTimeLeft() // We don't want to run this interval on anything longer than a day
    if (!hasResponded && timeleft > -86400) timer = setInterval(updateView, 1000)
    else updateView()
    return function done() {
      clearInterval(timer)
    }
  }, [review])

  return null
}

export default SLATimer
