import React, { useState } from 'react'
import moment from 'moment'
import { makeStyles } from '@mui/styles'
import { deepOrange } from '@mui/material/colors'
import Avatar from '@mui/material/Avatar'
import ButtonSpinner from '../../common/ButtonSpinner'
import { Line } from './CommentLine.S'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}))

function CommentLine({ comment, editMode, saveStarted, onEditClick, onUpdateClick }) {
  const [editText, setEditText] = useState('')
  const classes = useStyles()

  const handleKeyDown = e => {
    if (e.keyCode === 9) e.preventDefault()
  }

  const handleEditKeyUp = e => {
    if (e.keyCode === 9 && editText.length === 0) setEditText(comment.content)
    else if (e.keyCode === 13 && editText.length > 0) onUpdateClick(editText, comment)
  }

  const handleEditCommentChange = content => setEditText(content)

  const handleUpdateClick = () => {
    if (editText.length > 0) {
      onUpdateClick(editText, comment)
    }
  }

  const SavingMessage = () => (
    <div style={{ color: '#FFF', zIndex: 2 }}>
      <span>{'Saving...'}</span>
    </div>
  )

  return (
    <div className='d-inline-flex'>
      <Avatar className={classes.orange}>{comment.author.fullname[0]}</Avatar>
      <Line>
        <ul>
          <li>
            <strong>{comment.author.fullname}</strong>
          </li>
          <li style={{ paddingLeft: '10px' }}>{moment(comment.postDate).format('MMM DD hh:mm A')}</li>
        </ul>
        <br />
        {!editMode ? (
          <>
            <span>{comment.content}</span>
            {comment.isUserComment && (
              <div className='d-flex'>
                <p
                  className='underline hand-cursor text-secondary'
                  style={{ padding: '0px', margin: '0px' }}
                  onClick={() => onEditClick(comment)}>
                  Edit
                </p>
              </div>
            )}
          </>
        ) : (
          <>
            <input
              className='comments-input'
              placeholder={comment.content}
              value={editText}
              onKeyDown={handleKeyDown}
              onKeyUp={handleEditKeyUp}
              onChange={e => handleEditCommentChange(e.target.value)}
              style={{ padding: '10px', display: 'inline-block', width: '600px' }}
            />
            <div className='d-inline-flex pt-1 pl-2'>
              <button className='btn btn-light mr-1' onClick={() => onEditClick(null)}>
                Cancel
              </button>
              <ButtonSpinner
                disableElevation
                style={{ color: '#FFF' }}
                variant='contained'
                color='primary'
                onClick={handleUpdateClick}
                spin={saveStarted}
                spinContent={<SavingMessage />}>
                Save
              </ButtonSpinner>
            </div>
          </>
        )}
      </Line>
    </div>
  )
}

export default CommentLine
