import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { FaHashtag } from 'react-icons/fa'
import { useTheme } from '@mui/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Chip from '@mui/material/Chip'
import KeywordEditor from './KeywordsComponents/KeywordEditor'
import ProgressLoader from '../common/ProgressLoader'
import FlexContainer from '../common/FlexContainer'
import SimpleModal from '../common/SimpleModal'
import BodyText from '../common/BodyText'
import { TitleBox, Header, useStyles } from './Keywords.S'
import RadarServiceFactory, { RadarServices } from '../../services'
import { addNotificationAction, NotificationType } from '../../store/actions/notifications'

function Keywords({ addNotification }) {
  const theme = useTheme()
  const [searchData, setSearchData] = useState('')
  const [showEditor, setShowEditor] = useState(false)
  const [fetchStarted, setFetchStarted] = useState(false)
  const [updateStarted, setUpdateStarted] = useState(false)
  const [keywords, setKeywords] = useState([])
  const [categories, setCategories] = useState([])
  const [keyword, setKeyword] = useState({})
  const [modal, setModal] = useState({})
  const classes = useStyles()

  const fetchKeywords = async () => {
    setFetchStarted(true)

    const service = RadarServiceFactory.create(RadarServices.Keywords),
      keywords = await service.getKeywords(),
      categories = await service.getKeywordCategories()

    if (keywords.Ok) setKeywords(keywords.Content)
    if (categories.Ok) setCategories(categories.Content)

    setFetchStarted(false)
  }

  const updateKeyword = async keyword => {
    const service = RadarServiceFactory.create(RadarServices.Keywords)
    return await service.saveKeyword(keyword.id, {
      categories: keyword.categories.map(a => a.id),
      enabled: keyword.enabled,
      sentiments: keyword.sentiments,
    })
  }

  const createKeyword = async keyword => {
    const service = RadarServiceFactory.create(RadarServices.Keywords)
    return await service.createKeyword({
      text: keyword.text,
      categories: keyword.categories.map(a => a.id),
      enabled: keyword.enabled,
      sentiments: keyword.sentiments,
    })
  }

  const scoreKeyword = async keyword => {
    const service = RadarServiceFactory.create(RadarServices.Keywords)
    return await service.scoreKeyword(keyword)
  }

  const saveKeyword = keyword => {
    setUpdateStarted(true)

    let waiter = keyword.new ? createKeyword(keyword) : updateKeyword(keyword)
    waiter.then(retval => {
      if (retval.Ok) {
        addNotification({
          type: NotificationType.SUCCESS_NOTIFICATION,
          text: `${keyword.new ? 'Created New Keyword' : 'Update Keyword'} completed successfully`,
        })

        if (keyword.new) {
          keywords.push(retval.Content)
          setShowEditor(false)
        } else {
          let ndx = keywords.findIndex(a => a.id === keyword.id)
          keywords.splice(ndx, 1, { ...keyword })
        }

        setKeywords([...keywords])
      } else {
        addNotification({
          type: NotificationType.ERROR_NOTIFICATION,
          text: `Failed to ${keyword.new ? 'created new keyword' : 'update keyword'}`,
        })
      }

      setUpdateStarted(false)
    })
  }

  const deleteKeyword = async keyword => {
    setUpdateStarted(true)
    const service = RadarServiceFactory.create(RadarServices.Keywords),
      retval = await service.deleteKeyword(keyword.id)
    if (retval.Ok) {
      addNotification({
        type: NotificationType.SUCCESS_NOTIFICATION,
        text: 'Delete keyword completed successfully',
      })

      let ndx = keywords.findIndex(a => a.id === keyword.id)
      keywords.splice(ndx, 1)
      setKeywords([...keywords])

      setShowEditor(false)
    } else {
      addNotification({
        type: NotificationType.ERROR_NOTIFICATION,
        text: 'Failed to delete keyword please try again',
      })
    }

    setUpdateStarted(false)
  }

  const handleValueChange = e => setSearchData(e.target.value)

  const handleKeywordChanged = updates => {
    if (updates.text !== undefined) keyword.text = updates.text

    if (updates.category) {
      let ndx = keyword.categories.findIndex(i => i.id === updates.category.id)
      if (ndx !== -1) keyword.categories.splice(ndx, 1)
      else keyword.categories.push(updates.category)
    }

    if (updates.sentiment) {
      let ndx = keyword.sentiments.findIndex(i => i === updates.sentiment)
      if (ndx !== -1) keyword.sentiments.splice(ndx, 1)
      else keyword.sentiments.push(updates.sentiment)
    }

    if (updates.enabled !== undefined) {
      keyword.enabled = updates.enabled
    }

    setKeyword({ ...keyword })
  }

  const handleScoreKeyword = (keyword, callback) => {
    callback(scoreKeyword(keyword))
  }

  const handleNewKeywordClick = () => {
    setKeyword({
      new: true,
      text: '',
      enabled: true,
      categories: [],
      sentiments: ['Negative', 'Neutral', 'Positive'],
    })
    setShowEditor(true)
  }

  const handleDeleteKeywordClick = keyword => {
    setModal({
      showModal: true,
      title: `Action: DELETE Keyword`,
      content: `Are you sure that you want to delete keyword "${keyword.text}"?`,
      handleModalClick: () => deleteKeyword(keyword),
    })
  }

  const handleSaveKeywordClick = keyword => {
    if (!updateStarted) {
      let keywordLowercase = keyword.text.toLowerCase(),
        exists =
          !keyword.id && keywords.filter(word => word.text.toLowerCase() === keywordLowercase).length > 0
      if (exists) {
        addNotification({
          type: NotificationType.ERROR_NOTIFICATION,
          text: `Keyword '${keyword.text}' already exists in the list please try again `,
        })
        return
      }

      setUpdateStarted(true)

      let waiter = scoreKeyword(keyword.text)
      waiter.then(result => {
        if (result.Ok) {
          let score = Math.floor(result.Content.score * 10)
          console.log(score)
          if (score <= 4) {
            saveKeyword(keyword)
          } else {
            addNotification({
              type: NotificationType.ERROR_NOTIFICATION,
              text: `Keyword '${keyword.text}' score is too high please adjust keyword`,
            })
            setUpdateStarted(false)
          }
        } else {
          addNotification({
            type: NotificationType.ERROR_NOTIFICATION,
            text: `An occured scoring keyword please try again`,
          })
          setUpdateStarted(false)
        }
      })
    }
  }

  const handleKeywordClick = keyword => {
    setKeyword({ ...keyword })
    setShowEditor(true)
  }

  const handleCancelClick = () => setShowEditor(false)

  useEffect(() => {
    fetchKeywords()
  }, [])

  return (
    <FlexContainer
      className={theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}
      direction='column'
      padding='15px 15px 0px'
      alignItems='baseline'
      height='calc(100vh - 120px)'
      addProps='overflow-x: hidden; overflow-y: auto'>
      <div className='ml-5 col-md-12'>
        <TitleBox>
          <Header>Keywords</Header>
          <p>Words and phrases describing rules for flaging reviews that require escalation.</p>
        </TitleBox>
        <div className='row'>
          <div className='col-4'>
            <TextField
              className={classes.textField}
              name='reviewTitle'
              label='Search Keywords'
              onChange={handleValueChange}
              margin='dense'
              variant='outlined'
              disabled={showEditor}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              className={classes.button}
              disableElevation
              variant='contained'
              color='primary'
              disabled={showEditor}
              onClick={handleNewKeywordClick}>
              Create New Keyword
            </Button>
            {fetchStarted ? (
              <ProgressLoader />
            ) : (
              <div className={classes.chips}>
                {keywords
                  .sort((a, b) => {
                    if (a.text < b.text) return -1
                    if (a.text === b.text) return 0
                    return 1
                  })
                  .filter(keyword => {
                    if (!searchData) return true
                    let data = searchData.toLowerCase()
                    return keyword.text.toLowerCase().includes(data)
                  })
                  .map(keyword => (
                    <Chip
                      classes={{
                        root: classes.keywordChip,
                        colorPrimary: classes.keywordChipColor,
                        clickableColorPrimary: classes.keywordChipColor,
                      }}
                      key={keyword.id}
                      clickable
                      icon={<FaHashtag className={classes.avatar} />}
                      label={keyword.text}
                      color={keyword.enabled ? 'primary' : 'default'}
                      onClick={() => handleKeywordClick(keyword)}
                      onDelete={() => handleDeleteKeywordClick(keyword)}
                      size='medium'
                    />
                  ))}
              </div>
            )}
          </div>
          <div className='col-md-5' style={{ padding: '0px 15px' }}>
            {showEditor && (
              <KeywordEditor
                keyword={keyword}
                categories={categories}
                saveStarted={updateStarted}
                onUpdate={handleKeywordChanged}
                onCancel={handleCancelClick}
                onDelete={handleDeleteKeywordClick}
                onSubmit={handleSaveKeywordClick}
                onScore={handleScoreKeyword}
              />
            )}
          </div>
        </div>
      </div>
      <SimpleModal
        title={modal.title}
        open={modal.showModal}
        onClick={() => setModal({ ...modal, showModal: false })}>
        <FlexContainer>
          <BodyText>{modal.content}</BodyText>
        </FlexContainer>
        <FlexContainer className='pt-4'>
          <div className='col-md-7'></div>
          <Button className='col-md-2' variant='outlined' onClick={() => setModal({ showModal: false })}>
            No
          </Button>
          <Button className='col-md-2' color='primary' variant='outlined' onClick={modal.handleModalClick}>
            Yes
          </Button>
        </FlexContainer>
      </SimpleModal>
    </FlexContainer>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    addNotification: notification => {
      dispatch(addNotificationAction(notification.text, notification.type))
    },
  }
}

export default connect(null, mapDispatchToProps)(Keywords)
