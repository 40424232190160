import { createAction } from 'redux-actions'
import ReviewServiceFactory, { RadarServices } from '../../services'

export const ActionTypes = {
  COMMENT_SAVE_STARTED: 'COMMENT_SAVE_STARTED',
  COMMENT_SAVE_COMPLETED: 'COMMENT_SAVE_COMPLETED',
  COMMENT_CLEAR_STATE: 'COMMENT_CLEAR_STATE',
}

//----------------------------  Comments Actions

export const saveCommentStartedAction = createAction(ActionTypes.COMMENT_SAVE_STARTED)

export const saveCommentCompletedAction = createAction(
  ActionTypes.COMMENT_SAVE_COMPLETED,
  async (reviewId, comment) => {
    let service = ReviewServiceFactory.create(RadarServices.Comments)
    let result = await service.saveComment(reviewId, comment)
    if (result.Ok) return result.Content
    return null
  }
)

export const clearCommentStateAction = createAction(ActionTypes.COMMENT_CLEAR_STATE)
