import { css } from 'styled-components'

export const hover = (selected, color, variant, theme) => {
  color = color || 'foreground'
  variant = variant || 'dark'
  return css`
    transition: background-color 0.15s ease-in-out;
    &:hover {
      ${!selected && `background-color: ${theme[color][variant]}`};
      cursor: pointer;
    }
  `
}
