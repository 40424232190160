import { createTheme } from '@mui/material/styles'

const DASTheme = {
  primary: {
    lightest: '#FBD1A7',
    lighter: '#FABA7B',
    light: '#F8A34F',
    main: '#F68C23',
    dark: '#DD7E20',
    darker: '#C5701C',
  },
  secondary: {
    lightest: '#CCEAF8',
    lighter: '#66C0E9',
    light: '#33ABE2',
    main: '#0096DB',
    dark: '#0087C5',
  },
  success: {
    lightest: '#B3DE94',
    lighter: '#A3D77F',
    light: '#85C954',
    main: '#66BC29',
    dark: '#5CA925',
  },
  warning: {
    lightest: '#FBE1A7',
    lighter: '#F9D17C',
    light: '#F7C250',
    main: '#F5B324',
    dark: '#DDA120',
  },
  error: {
    lightest: '#F8C1CB',
    lighter: '#F18397',
    light: '#ED5A74',
    main: '#E83151',
    dark: '#D12C49',
  },
  foreground: {
    lightest: '#EDEDED',
    lighter: '#CACACA',
    light: '#828282',
    main: '#4D4D4D',
    dark: '#454545',
  },
  background: {
    main: '#FFF',
    dark: '#e7e7e7',
    darker: '#E9E9E9',
  },
  text: {
    primary: '#4D4D4D',
    secondary: '#828282',
    disabled: '#A2A2A2',
  },
}

export const DASThemeDark = {
  type: 'dark',
  primary: {
    lightest: '#FBD1A7',
    lighter: '#FABA7B',
    light: '#F8A34F',
    main: '#F68C23',
    dark: '#DD7E20',
    darker: '#C5701C',
  },

  secondary: {
    lightest: '#CCEAF8',
    lighter: '#66C0E9',
    light: '#33ABE2',
    main: '#0096DB',
    dark: '#0087C5',
  },

  success: {
    lightest: '#B3DE94',
    lighter: '#A3D77F',
    light: '#85C954',
    main: '#66BC29',
    dark: '#5CA925',
  },

  warning: {
    lightest: '#FBE1A7',
    lighter: '#F9D17C',
    light: '#F7C250',
    main: '#F5B324',
    dark: '#DDA120',
  },

  error: {
    lightest: '#F8C1CB',
    lighter: '#F18397',
    light: '#ED5A74',
    main: '#E83151',
    dark: '#D12C49',
  },

  foreground: {
    lightest: '#A9A9A9',
    lighter: '#7F7F7F',
    light: '#545454',
    main: '#FFF',
    dark: '#292929',
  },

  background: {
    main: '#4D4D4D',
    dark: '#454545',
    darker: '#3E3E3E',
  },

  text: {
    primary: '#FFF',
    secondary: '#EDEDED',
    disabled: '#CACACA',
    },
}

export default DASTheme

export const materialUITheme = createTheme({
  palette: DASTheme,
  typography: {
    fontFamily: [
      'Roboto',
      'Nunito',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  },
})
