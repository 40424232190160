import React, { useState } from 'react'
import { connect } from 'react-redux'
import FlexContainer from '../../common/FlexContainer'
import ButtonSpinner from '../../common/ButtonSpinner'
import { updateAccountStartedAction, updateAccountCompletedAction } from '../../../store/actions/account'
import { TitleBox, Header, Body, ContentBox, SavingText } from './Shared.S'
import { Editor } from './Notes.S'

function Notes(props) {
  const { account, updateStarted, saveAccount } = props
  const [notes, setNotes] = useState(account.notes || '')

  const handleNoteChange = e => setNotes(e.target.value)

  const handleSaveClick = () => {
    if (!updateStarted) saveAccount(account.clientId, { notes: notes })
  }

  return (
    <FlexContainer alignItems='left' direction='column'>
      <ContentBox className='ml-3'>
        <TitleBox>
          <Header>Client Notes</Header>
          <Body>
            Notes related to special requests an account makes for how we respond to reviews. You can edit
            this directly.
          </Body>
        </TitleBox>
      </ContentBox>
      <div className='col-3'>
        <Editor rows='10' cols='75' value={notes} onChange={handleNoteChange} />
      </div>
      <ContentBox className='pt-5'>
        <ButtonSpinner
          disableElevation
          style={{ color: '#FFF' }}
          variant='contained'
          color='primary'
          spin={updateStarted}
          spinContent={<SavingText>Saving..</SavingText>}
          onClick={handleSaveClick}>
          Save
        </ButtonSpinner>
      </ContentBox>
    </FlexContainer>
  )
}

function mapStateToProps(state) {
  return {
    account: state.account.account,
    updateStarted: state.account.updateStarted,
    updatedAccount: state.account.updatedAccount,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveAccount: (clientId, account) => {
      dispatch(updateAccountStartedAction())
      dispatch(updateAccountCompletedAction(clientId, account))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notes)
