import reduceReducers from 'reduce-reducers'
import { handleAction } from 'redux-actions'
import { ActionTypes } from '../actions/account'
import { NotificationType } from '../actions/notifications'

const defaultState = {}

const handleFetchStartedReducer = handleAction(
  ActionTypes.ACCOUNT_FETCH_STARTED,
  {
    next(state, action) {
      return {
        ...state,
        fetchStarted: true,
        updateStarted: false,
        account: null,
      }
    },
  },
  defaultState
)

const handleFetchCompletedReducer = handleAction(
  ActionTypes.ACCOUNT_FETCH_COMPLETED,
  {
    next(state, action) {
      return {
        ...state,
        fetchStarted: false,
        account: action.payload,
        updatedAccount: null,
      }
    },
  },
  defaultState
)

const handleUpdateStartedReducer = handleAction(
  ActionTypes.ACCOUNT_UPDATE_STARTED,
  {
    next(state, action) {
      return {
        ...state,
        fetchStarted: false,
        updateStarted: true,
        notification: null,
        updatedAccount: null,
      }
    },
  },
  defaultState
)

const handleUpdateCompletedReducer = handleAction(
  ActionTypes.ACCOUNT_UPDATE_COMPLETED,
  {
    next(state, action) {
      let content = action.payload.content,
        account = state.account

      return {
        ...state,
        account: action.payload.ok ? { ...account, ...content } : account,
        updateStarted: false,
        notification: {
          text: action.payload.ok ? 'Account updated successfully' : 'Failed to update Account',
          type: action.payload.ok
            ? NotificationType.SUCCESS_NOTIFICATION
            : NotificationType.ERROR_NOTIFICATION,
        },
        updatedAccount: {
          succeeded: action.payload.ok,
          content: action.payload.content,
        },
      }
    },
  },
  defaultState
)

const handleTabSelectedReducer = handleAction(
  ActionTypes.ACCOUNT_TAB_SELECTED,
  {
    next(state, action) {
      return {
        ...state,
        updatedAccount: {
          succeeded: false,
        },
        activeTabId: action.payload,
      }
    },
  },
  defaultState
)

const handleClearNotificationReducer = handleAction(
  ActionTypes.ACCOUNT_CLEAR_NOTIFICATION,
  {
    next(state, action) {
      return {
        ...state,
        notification: null,
      }
    },
  },
  defaultState
)

const initialState = {
  fetchStarted: false,
  updateStarted: false,
  account: null,
  notification: null,
  updatedAccount: null,
  activeTabId: 0,
}

export default () =>
  reduceReducers(
    initialState,
    handleFetchStartedReducer,
    handleFetchCompletedReducer,
    handleUpdateStartedReducer,
    handleUpdateCompletedReducer,
    handleTabSelectedReducer,
    handleClearNotificationReducer
  )
