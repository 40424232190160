import styled, { css } from 'styled-components'

export const FolderItem = styled.div`
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.background.dark};
  min-width: 185px;
  height: 40px;
  margin: 0px 10px;
  margin-top: 5px;
  padding: 4px;
  transition: background-color 0.15s ease-in-out 0s;

  &:hover {
    background: ${props =>
      props.theme.type === 'dark' ? props.theme.background.dark : props.theme.primary.lighter};
    border: 1px solid ${props => props.theme.primary.main};
  }

  ${props =>
    props.active &&
    css`
      background: ${props =>
        props.theme.type === 'dark' ? props.theme.background.dark : props.theme.primary.lighter};
      border: 1px solid
        ${props => (props.theme.type === 'dark' ? props.theme.background.darker : props.theme.primary.main)};
      font-weight: 600 !important;

      h6 {
        color: #fff;
      }
      #queue-item-count {
        background-color: ${props => props.theme.primary.main} !important;
        h6 {
          color: #fff;
        }
      }
    `}

  #queue-item-count {
    background-color: ${props => props.theme.background.dark};
  }
`

export const FilterItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: 0.51rem;
  align-items: center;
  border: 1px solid ${props => props.theme.background.dark};
  border-radius: 50%;
  color: ${props => props.theme.text.primary};

  &:hover {
    background: ${props =>
      props.theme.type === 'dark' ? props.theme.background.dark : props.theme.primary.lighter};
    border: 1px solid ${props => props.theme.primary.main};
    color: #fff;
  }

  ${props =>
    props.active &&
    css`
      background: ${props.theme.primary.main};
      color: #fff;
      &:hover {
        background: ${props.theme.primary.main};
      }
    `}
`
