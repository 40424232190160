import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

/**
 * @param props.isResponded Status of SLA being responded
 * @param props.strokeWidth Width of the circular line in the progress timer
 * @param props.sqSize Size of the entire progress timer (in a square)
 * @param props.percentage Completeness of the timer --> 100% = full circle
 * @param props.timeText Time display text which positions at the center of the timer
 * @param props.descriptionText (Extra) status time display text (remaining or overdue)
 */
export default props => {
  const { isResponded, strokeWidth, sqSize, percentage, timeText, descriptionText, subText } = props
  const theme = useContext(ThemeContext)
  const styles = {
    circleBackground: {
      fill: 'none',
      stroke: theme.background.darker,
    },
    circleProgress: {
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
    circleTime: {
      fontWeight: 'bold',
      fill: theme.secondary.main,
    },
    circleText: {
      fill: theme.text.primary,
    },
    timeColoring: {
      Good: theme.secondary.main,
      Warning: theme.warning.main,
      Alert: theme.error.main,
      Success: theme.success.main,
    },
  }

  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2

  // Enclose circle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`

  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2

  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100

  // Timer Icon which goes inside the timer circle
  const timerIcon = {
    checkMark: '\u2714',
    exclamationMark: '\u0021',
  }

  function colorChange() {
    if (isResponded) {
      return styles.timeColoring.Success
    } else {
      if (percentage <= 50) {
        return styles.timeColoring.Good
      } else if (percentage <= 75) {
        return styles.timeColoring.Warning
      }
      return styles.timeColoring.Alert
    }
  }

  function fontSizeChange() {
    if (isResponded || sqSize <= 50) {
      return 2.6 * (sqSize / 200) + 'em'
    }
    return 1.6 * (sqSize / 200) + 'em'
  }

  function timeTextChange() {
    if (isResponded) {
      return timerIcon.checkMark
    } else {
      if (sqSize <= 50) {
        return percentage < 100 ? '' : timerIcon.exclamationMark
      } else {
        return timeText
      }
    }
  }

  function textPositioning() {
    if (!descriptionText || isResponded || sqSize <= 50) {
      return '0.35em'
    }
    return '0em'
  }

  return (
    <svg width={sqSize} height={sqSize} viewBox={viewBox}>
      <circle
        className='circle-background'
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${Math.log(strokeWidth * sqSize)}px`}
        fill={colorChange() + '1F'}
        stroke={styles.circleBackground.stroke + '3F'}
      />
      <circle
        className='circle-progress'
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${Math.log(strokeWidth * sqSize)}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
        }}
        fill={styles.circleProgress.fill}
        stroke={colorChange()}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <text
        className='circle-text'
        x='50%'
        y={subText ? '29%' : '50%'}
        dy={textPositioning()}
        textAnchor='middle'
        fontSize={fontSizeChange()}
        fontWeight={styles.circleTime.fontWeight}
        fill={colorChange()}>
        {timeTextChange()}
      </text>
      <text
        className='circle-text'
        x='50%'
        y={subText ? '29%' : '50%'}
        dy='2.9em'
        textAnchor='middle'
        fontSize={sqSize / 200 + 'em'}
        fill={styles.circleText.fill}>
        {descriptionText}
      </text>
      {subText && <text
          className='circle-text'
          x='50%'
          y='30%'
          dy='3.9em'
          textAnchor='middle'
          fontSize={(1.3 * (sqSize / 200)) + 'em'}
          fontWeight={styles.circleTime.fontWeight}
          fill={colorChange()}>
        {subText}
      </text>}
    </svg>
  )
}
