import React from 'react'
import { connect } from 'react-redux'
import ClientSearchInput from '../../common/ClientSearchInput'
import { fetchAccountStartedAction, fetchAccountCompletedAction } from '../../../store/actions/account'

function SettingsAccountSearch(props) {
  const { onAccountSearchClick, fetchAccount } = props

  const handleAccountsChange = client => {
    onAccountSearchClick(client.id)
    fetchAccount(client.id)
  }

  return (
    <div style={{ position: 'absolute', zIndex: 99, top: 60, right: 0 }}>
      <ClientSearchInput width={380} marginTop={5} marginRight={8} onClientSelected={handleAccountsChange} />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAccount: accountId => {
      dispatch(fetchAccountStartedAction())
      dispatch(fetchAccountCompletedAction(accountId))
    },
  }
}

export default connect(null, mapDispatchToProps)(SettingsAccountSearch)
