import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useTheme } from '@mui/styles'
import ProgressLoader from '../common/ProgressLoader'
import NewTemplateEditor from './ResponseTemplateComponents/NewTemplateEditor'
import EditableTemplateEditor from './ResponseTemplateComponents/EditableTemplateEditor'
import FlexContainer from '../common/FlexContainer'
import SideLegend from './ResponseTemplateComponents/SideLegend'
import Button from '../common/Button'
import { ContentBox, TitleBox, Header, ItemContainer, FilterButton, SearchInput } from './ResponseTemplates.S'
import { addNotificationAction, NotificationType } from '../../store/actions/notifications'
import { pageSelectedAction } from '../../store/actions/response-templates'
import RadarServiceFactory, { RadarServices } from '../../services'

function ResponseTemplates({ page, pageSize, pageSelected, addNotification }) {
  const [searchData, setSearchData] = useState('')
  const [filterData, setFilterData] = useState({parent:'',child:''})
  const [showEditor, setShowEditor] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [fetchStarted, setFetchStarted] = useState(false)
  const [updateStarted, setUpdateStarted] = useState(false)
  const [deletionStarted, setDeletionStarted] = useState(false)
  const [templates, setTemplates] = useState([])
  const containerRef = useRef(null)
  const theme = useTheme()

  const fetchTemplates = async page => {
    if (!fetchStarted) {
      setFetchStarted(true)

      let service = RadarServiceFactory.create(RadarServices.ResponseTemplates),
        result = await service.getTemplates(page, pageSize),
        { totalCount, templates } = result.Ok ? result.Content : { templates: [], totalCount: 0 }

      setTemplates(templates)
      setTotalCount(totalCount)
      setFetchStarted(false)
    }
  }

  const handleNewTemplateClick = () => {
    setShowEditor(true)
    containerRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const handleCreateTemplateClick = async template => {
    if (!updateStarted) {
      setUpdateStarted(true)

      let service = RadarServiceFactory.create(RadarServices.ResponseTemplates),
        result = await service.createTemplate(template),
        notification = {
          text: result.Ok ? 'Response Template created successfully' : 'Failed to create Response Template',
          type: result.Ok ? NotificationType.SUCCESS_NOTIFICATION : NotificationType.ERROR_NOTIFICATION,
        }

      if (result.Ok) {
        setShowEditor(false)
        setTemplates([...templates, result.Content])
        setTotalCount(totalCount + 1)

        let newpage = Math.ceil((totalCount + 1) / pageSize)
        pageSelected(newpage)
      }

      addNotification(notification)
      setUpdateStarted(false)
    }
  }

  const handleEditTemplateClick = async template => {
    if (!updateStarted) {
      setUpdateStarted(true)

      let service = RadarServiceFactory.create(RadarServices.ResponseTemplates),
        result = await service.updateTemplate(template),
        notification = {
          text: result.Ok ? 'Response Template updated successfully' : 'Failed to update Response Template',
          type: result.Ok ? NotificationType.SUCCESS_NOTIFICATION : NotificationType.ERROR_NOTIFICATION,
        }
      if (result.Ok) {
        setShowEditor(false)
      }

      addNotification(notification)
      setUpdateStarted(false)
    }
  }

  const handleDeleteTemplateClick = async template => {
    if (!deletionStarted) {
      setDeletionStarted(true)

      let service = RadarServiceFactory.create(RadarServices.ResponseTemplates),
        result = await service.deleteTemplate(template),
        notification = {
          text: result.Ok ? 'Response Template deleted successfully' : 'Failed to delete Response Template',
          type: result.Ok ? NotificationType.SUCCESS_NOTIFICATION : NotificationType.ERROR_NOTIFICATION,
        }

      if (result.Ok) {
        setShowEditor(false)
        setTotalCount(totalCount - 1)

        let filtered = templates.filter(a => a.templateId !== template.templateId)
        setTemplates(filtered)
      }

      addNotification(notification)
      setDeletionStarted(false)
    }
  }

  useEffect(() => {
    fetchTemplates(page)
  }, [page])

  return (
    <FlexContainer
      className={theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}
      padding='15px 15px 0px'
      alignItems='baseline'
      width='100%'
      height='calc(100vh - 120px)'
      addProps='overflow-y: auto'>
      <ContentBox className='ml-5 col-lg-6'>
        <TitleBox className='ml-2'>
          <Header>Radar Templates</Header>
          <p>
            Pre-written responses that can be used to quickly respond to common reviews. Templates become
            available in the Review Workbench.
          </p>
        </TitleBox>
        <FlexContainer>
          <SearchInput
            className='col-md-8'
            type='text'
            placeholder='Search Templates'
            value={searchData}
            onChange={e => setSearchData(e.target.value)}
            disabled={showEditor}
          />
          <Button
            className='btn das-primary-bg text-white col-md-3'
            disabled={showEditor}
            type='button'
            onClick={handleNewTemplateClick}>
            Create New
          </Button>
        </FlexContainer>
        <div>
          <FilterButton
              hover='lightest'
              className={filterData.parent === '' ? 'btn das-primary-bg text-white' : 'btn das-white-bg'}
              type='button'
              disabled={showEditor}
              onClick={e => setFilterData({...filterData, parent:''})}>
            All
          </FilterButton>
          <FilterButton
              hover='lightest'
              className={filterData.parent === 'Sales' ? 'btn das-primary-bg text-white' : 'btn das-white-bg'}
              type='button'
              disabled={showEditor}
              onClick={e => setFilterData({...filterData, parent:'Sales'})}>
            Sales
          </FilterButton>
          <FilterButton
              hover='lightest'
              className={filterData.parent === 'Service' ? 'btn das-primary-bg text-white' : 'btn das-white-bg'}
              type='button'
              disabled={showEditor}
              onClick={e => setFilterData({...filterData, parent:'Service'})}>
            Service
          </FilterButton>
          <FilterButton
              hover='lightest'
              className={filterData.parent === 'Strategic' ? 'btn das-primary-bg text-white' : 'btn das-white-bg'}
              type='button'
              disabled={showEditor}
              onClick={e => setFilterData({...filterData, parent:'Strategic'})}>
            Strategic
          </FilterButton>
        </div>
        <div className="mt-2"></div>
        <div>
          <FilterButton
            hover='lightest'
            className={filterData.child === '' ? 'btn das-primary-bg text-white' : 'btn das-white-bg'}
            type='button'
            disabled={showEditor}
            onClick={e => setFilterData({...filterData,child:''})}>
            All
          </FilterButton>
          <FilterButton
            hover='lightest'
            className={filterData.child === 'Positive' ? 'btn das-primary-bg text-white' : 'btn das-white-bg'}
            type='button'
            disabled={showEditor}
            onClick={e => setFilterData({...filterData,child:'Positive'})}>
            Positive
          </FilterButton>
          <FilterButton
            hover='lightest'
            className={filterData.child === 'Neutral' ? 'btn das-primary-bg text-white' : 'btn das-white-bg'}
            type='button'
            disabled={showEditor}
            onClick={e => setFilterData({...filterData,child:'Neutral'})}>
            Neutral
          </FilterButton>
          <FilterButton
            hover='lightest'
            className={filterData.child === 'Negative' ? 'btn das-primary-bg text-white' : 'btn das-white-bg'}
            type='button'
            disabled={showEditor}
            onClick={e => setFilterData({...filterData,child:'Negative'})}>
            Negative
          </FilterButton>
        </div>
        <ItemContainer
          ref={containerRef}
          className={theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}>
          {showEditor && (
            <NewTemplateEditor
              updateStarted={updateStarted}
              onCancel={() => setShowEditor(false)}
              onSave={handleCreateTemplateClick}
            />
          )}
          {fetchStarted && <ProgressLoader />}
          {templates
            .filter(template => {
              console.log("filtered",JSON.stringify(filterData))
              if (!filterData.parent && !filterData.child) return true
              let filtered = false;
              if (filterData.parent){
                if (template.types?.indexOf(filterData.parent) > -1) filtered = true;
                if (filtered && filterData.child){
                  if (template.sentiments.indexOf(filterData.child) > -1) return true
                  return false;
                }
                else{
                  return filtered;
                }
              }
              else if (template.sentiments.indexOf(filterData.child) > -1) return true
              return false
            })
            .filter(template => {
              if (!searchData) return true
              if (template.title.toLowerCase().includes(searchData.toLocaleLowerCase())) return true
              return false
            })
            .map(template => (
              <EditableTemplateEditor
                key={template.templateId}
                template={template}
                updateStarted={updateStarted}
                deletionStarted={deletionStarted}
                onSave={handleEditTemplateClick}
                onDelete={handleDeleteTemplateClick}
              />
            ))}
        </ItemContainer>
      </ContentBox>
      <SideLegend className='col-md-2' />
    </FlexContainer>
  )
}

function mapStateTopProps(state) {
  return {
    page: state.responseTemplates.page,
    pageSize: state.responseTemplates.pageSize,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    pageSelected: page => dispatch(pageSelectedAction(page)),
    addNotification: notification => dispatch(addNotificationAction(notification.text, notification.type)),
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(ResponseTemplates)
