import styled from 'styled-components'

export const TimelineSwitch = styled.div`
  position: absolute;
  left: 5px;
  top: -25px;
  padding: 5px;
  background-color: ${props => props.theme.error.main};
  color: #fff;
  z-index: 9;
  cursor: pointer;
  transition: all 0.2s;

  :before {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 0;
    height: 0;
    border-top: 1em solid ${props => props.theme.error.main};
    border-right: 1.2em solid transparent;
    z-index: 9;
  }

  :after {
    content: '';
    position: absolute;
    bottom: -15px;
    right: 0;
    width: 0;
    height: 0;
    border-top: 1em solid ${props => props.theme.error.main};
    border-left: 1.2em solid transparent;
    z-index: 9;
  }

  &:hover {
    top: 0px;
  }
`
