import React, { useState } from 'react'
import { FaHashtag, FaTimes } from 'react-icons/fa'
import { useTheme } from 'styled-components'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import HelpIcon from '@mui/icons-material/Help'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ButtonSpinner from '../../common/ButtonSpinner'
import ToolTip from '../../common/Tooltip'
import { EditorContainer, ScoreContainer, TitleBox, SavingText, useStyles } from '../Keywords.S'

export default function ({
  keyword,
  categories,
  saveStarted,
  onScore,
  onUpdate,
  onSubmit,
  onCancel,
  onDelete,
}) {
  const classes = useStyles()
  const theme = useTheme()
  const sentiments = ['Positive', 'Neutral', 'Negative']
  const [scoring, setScoring] = useState(false)
  const [score, setScore] = useState(-1)

  const handleTextChanged = e => onUpdate({ text: e.target.value })

  const handleScoreClick = () => onScore(keyword.text, handleScoreResult)

  const handleScoreResult = promise => {
    setScoring(true)
    setScore(-1)
    promise.then(result => {
      if (result.Ok) {
        setScore(Math.floor(result.Content.score * 10))
      } else {
        setScore(-1)
      }
      setScoring(false)
    })
  }

  const handleCategoryClick = e =>
    onUpdate({ category: categories.find(a => a.id === e.currentTarget.dataset['catid']) })

  const handleSentimentClick = e =>
    onUpdate({ sentiment: sentiments.find(a => a === e.currentTarget.dataset['sentid']) })

  const handleEnableChange = e => onUpdate({ enabled: e.target.checked })

  const canSave = () =>
    keyword.text.trim().length > 2 && keyword.categories.length > 0 && keyword.sentiments.length > 0

  const canScore = () => keyword.text.trim().length > 2

  return (
    <EditorContainer className='col-md-11'>
      <div className='header'>
        <span>{keyword.new ? 'Create New Keyword' : 'Edit Keyword'}</span>
        <FaTimes className='closeButton' onClick={onCancel} />
      </div>
      {!keyword.new ? (
        <div className='col-md-12 mt-4'>
          <Chip
            className={classes.headerChip}
            icon={<FaHashtag className={classes.avatar} />}
            label={keyword.text}
          />
        </div>
      ) : (
        <TextField
          className='col-md-12 mt-4'
          label='Enter the keyword or phrase'
          value={keyword.text}
          margin='dense'
          variant='outlined'
          onChange={handleTextChanged}
        />
      )}
      <FormControlLabel
        className='mt-4 das-bold'
        control={<Switch color='primary' checked={keyword.enabled} onChange={handleEnableChange} />}
        label='Enabled'
      />
      <div className='mt-2'>
        <TitleBox>Sentiments</TitleBox>
        <ToolTip
          tooltip='Select from the following sentiments that the keyword should be applied to'
          className='pt-1'
          style={{ marginTop: '-5px' }}>
          <HelpIcon color='primary' />
        </ToolTip>
      </div>
      <div className='mt-2'>
        {sentiments.map(item => {
          return (
            <Chip
              data-sentid={item}
              className={classes.categoryChip}
              key={item}
              color='primary'
              clickable
              label={item}
              size='small'
              variant={keyword.sentiments.findIndex(i => i === item) !== -1 ? 'default' : 'outlined'}
              onClick={handleSentimentClick}
            />
          )
        })}
      </div>
      <div className='mt-4'>
        <TitleBox>Categories:</TitleBox>
        <ToolTip
          tooltip='Select from the following categories that the keyword fits into'
          className='pt-1'
          style={{ marginTop: '-5px' }}>
          <HelpIcon color='primary' />
        </ToolTip>
      </div>
      <div className='d-flex flex-wrap pt-2'>
        {categories.map(item => {
          item.selected = keyword.categories.findIndex(i => i.id === item.id) !== -1
          return (
            <Chip
              data-catid={item.id}
              className={classes.categoryChip}
              key={item.id}
              color='primary'
              clickable
              label={item.name}
              size='small'
              variant={item.selected ? 'default' : 'outlined'}
              onClick={handleCategoryClick}
            />
          )
        })}
      </div>
      <div className='row mt-5'>
        <div className='col-md-7'>
          <ButtonSpinner
            disabled={!canScore()}
            disableElevation
            variant='contained'
            color='secondary'
            spin={scoring}
            spinContent={<SavingText>Scoring..</SavingText>}
            onClick={handleScoreClick}>
            Score
          </ButtonSpinner>
          <ToolTip
            tooltip='Determines how common the word is used'
            className='pt-1'
            style={{ marginTop: '-5px' }}>
            <HelpIcon color='primary' />
          </ToolTip>
          <ScoreContainer
            className='ml-1'
            color={theme.success.main}
            style={{ display: score >= 0 && score < 2 ? 'inline' : 'none' }}>
            <CheckCircleIcon />
            <span>Good Keyword</span>
          </ScoreContainer>
          <ScoreContainer
            className='ml-1'
            color={theme.warning.main}
            style={{ display: score >= 2 && score <= 4 ? 'inline' : 'none' }}>
            <CheckCircleIcon />
            <span>Moderately common keyword</span>
          </ScoreContainer>
          <ScoreContainer
            className='ml-1'
            color={theme.error.main}
            style={{ display: score > 4 ? 'inline' : 'none' }}>
            <CheckCircleIcon />
            <span>Keyword is too common</span>
          </ScoreContainer>
        </div>
        <div className='col-md-5 d-flex justify-content-end'>
          {keyword.id ? (
            <Button disableElevation variant='outlined' color='primary' onClick={() => onDelete(keyword)}>
              Delete Keyword
            </Button>
          ) : (
            <Button disableElevation variant='outlined' color='primary' onClick={onCancel}>
              CANCEL
            </Button>
          )}
          <ButtonSpinner
            style={{ marginLeft: '5px', color: canSave() ? '#FFF' : '' }}
            disableElevation
            variant='contained'
            color='primary'
            disabled={!canSave()}
            spin={saveStarted}
            spinContent={<SavingText>Saving..</SavingText>}
            onClick={() => onSubmit(keyword)}>
            SAVE
          </ButtonSpinner>
        </div>
      </div>
    </EditorContainer>
  )
}
