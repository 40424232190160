import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { ThemeContext } from 'styled-components'
import SettingsNavBar from './SettingsNavBar'
import EmailTemplates from './EmailTemplates'
import ResponseTemplates from './ResponseTemplates'
import SettingsAccount from './SettingsAccount'
import Tags from './Tags'
import Keywords from './Keywords'
import {Reporting} from "./Reporting";

function SettingsWorkspace({ activeSection }) {
  const theme = useContext(ThemeContext)
  const getActiveSection = () => {
    switch (activeSection) {
      case 0:
        return <SettingsAccount />
      case 1:
        return <ResponseTemplates />
      case 2:
        return <EmailTemplates />
      case 3:
        return <Tags />
      case 4:
        return <Keywords />
      case 5:
        return <Reporting />
      default:
        break
    }
  }

  return (
    <>
      <div className='row no-gutters overflow-hidden'>
        <SettingsNavBar />
      </div>
      <div className='row no-gutters' style={{ backgroundColor: theme.background.main }}>
        {getActiveSection()}
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    activeSection: state.settings.activeTabId,
  }
}

export default connect(mapStateToProps, null)(SettingsWorkspace)
