import styled from 'styled-components'

export const ConfigBox = styled.ul`
  padding: 12px;
  background: ${props =>
    props.theme.type === 'dark' ? props.theme.background.dark : props.theme.background.main};
  box-shadow: 0px 0px 10px rgba(57, 58, 60, 0.1);
  border-radius: 4px;
  width: 85%;
  margin: 0px 0px 25px 0px;
  display: table;
`

export const ConfigItem = styled.li`
  display: inline-block;
  border: 1px solid
    ${props => (props.theme.type === 'dark' ? props.theme.primary.lightest : props.theme.primary.lightest)};
  align-items: left;
  height: 60px;
  width: 250px;
  border-radius: 4px;
  margin: 3px 3px 3px 3px;
  padding: 0 8px;
  cursor: pointer;
  font-size: 1em;
  &:hover {
    box-shadow: 0px 0px 10px rgba(57, 58, 60, 0.1);
    background: ${props =>
      props.theme.type === 'dark' ? props.theme.background.darker : props.theme.primary.lightest};
    border: 1px solid ${props => props.theme.primary.lighter};
  }
`

export const ConfigPrimaryText = styled.span`
  font-size: 16px;
  color: ${props => props.theme.text.primary};
  &::after {
    display: block;
    content: '';
    clear: both;
  }
`

export const ConfigSecondaryText = styled.span`
  font-size: 12px;
  font-family: 'Fira Code', monospace;
  font-weight: 100;
  color: ${props => props.theme.foreground.lightest};
`

export const TabBox = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  height: 50px;
  margin-left: 15px;
  background: ${props =>
    props.theme.type === 'dark' ? props.theme.background.dark : props.theme.background.main};
  box-shadow: 0px 0px 10px rgba(57, 58, 60, 0.1);
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
  padding: 0 8px;
`
