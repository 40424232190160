// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from 'react'
import { BsPencil } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'
import { ContentBox, TitleBox } from '../ResponseTemplates.S'
import FlexContainer from '../../common/FlexContainer'
import { useTheme } from '@mui/styles'
import TextField from '@mui/material/TextField'
import CheckBox from '../../common/CheckBox'
import Button from '../../common/Button'
import ButtonSpinner from '../../common/ButtonSpinner'
import SimpleModal from '../../common/SimpleModal'
import BodyText from '../../common/BodyText'
import ValidationModal from '../common/ValidationModal'
import TextEditor from '../../common/TextEditor'
import { Card, EditorContainer, SentimentSpan, ButtonSpinnerText, HTMLContentText } from './Shared.S'

function EditableTemplateEditor(props) {
  const { updateStarted, deleteStarted, template, onDelete, onSave } = props
  const [isEditing, setIsEditing] = useState(false)
  const [editTemplate, setEditTemplate] = useState(template)
  const [showModal, setShowModal] = useState(false)
  const [showValidationModal, setShowValidationModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const theme = useTheme()

  const showErrorDialog = msg => {
    setErrorMessage(msg)
    setShowValidationModal(true)
  }

  const handleClose = () => setShowValidationModal(false)

  const handleTypeCheckClick = (e, checked) => {
    let updated = { ...editTemplate }
    if (checked) {
      updated.types = [...updated.types.flat(), e.id]
    } else {
      updated.types = updated.types.filter(a => a !== e.id)
    }

    setEditTemplate(updated)
  }
  
  const handleCheckClick = (e, checked) => {
    let updated = { ...editTemplate }
    if (checked) {
      updated.sentiments = [...updated.sentiments.flat(), e.id]
    } else {
      updated.sentiments = updated.sentiments.filter(a => a !== e.id)
    }

    setEditTemplate(updated)
  }

  const handleTitleChanged = e => {
    let updated = { ...editTemplate }
    updated.title = e.target.value
    setEditTemplate(updated)
  }

  const handleContentChanged = (data, delta, source, editor) => {
    let updated = { ...editTemplate }
    if (editor.getText().length <= 1) data = ''
    updated.content = data
    setEditTemplate(updated)
  }

  const handleSave = () => {
    if (editTemplate.title.length === 0) {
      showErrorDialog('Title is required please fill in this area')
      return
    }

    if (editTemplate.sentiments.length === 0) {
      showErrorDialog('Sentiments are required please select one or more')
      return
    }

    if (editTemplate.content.length === 0) {
      showErrorDialog('Content is required please fill in this area')
      return
    }

    onSave(editTemplate)
  }

  const handleTemplateClose = () => {
    setEditTemplate({ ...template })
    setIsEditing(false)
  }

  useEffect(() => {
    setEditTemplate(template)
  }, [template])

  useEffect(() => {
    if (!deleteStarted) {
      setIsEditing(false)
    }
  }, [deleteStarted])

  useEffect(() => {
    if (!updateStarted) {
      setIsEditing(false)
    }
  }, [updateStarted])

  return isEditing ? (
    <>
      <EditorContainer>
        <div className='header'>
          <p>
            <span className='bolder'>EDIT</span> Response Template
          </p>
          <FaTimes className='closeButton' onClick={handleTemplateClose}></FaTimes>
        </div>
        <TextField
            required
            className='col-md-12'
            placeholder='Response template name'
            value={editTemplate.title}
            onChange={handleTitleChanged}
            variant='outlined'
            margin='dense'
        />
        <div className="row">
          <div className="col-md-12 d-flex align-items-center justify-content-between">
            <div className='sentiments'>
              <CheckBox
                  id='Sales'
                  onChanged={handleTypeCheckClick}
                  checked={editTemplate.types.indexOf('Sales') !== -1}
              />{' '}
              Sales
              <CheckBox
                  id='Service'
                  onChanged={handleTypeCheckClick}
                  checked={editTemplate.types.indexOf('Service') !== -1}
              />{' '}
              Service
              <CheckBox
                  id='Strategic'
                  onChanged={handleTypeCheckClick}
                  checked={editTemplate.types.indexOf('Strategic') !== -1}
              />{' '}
              Strategic
            </div>
            <div className='sentiments'>
              <CheckBox
                  id='Positive'
                  onChanged={handleCheckClick}
                  checked={editTemplate.sentiments.indexOf('Positive') !== -1}
              />{' '}
              Positive
              <CheckBox
                  id='Neutral'
                  onChanged={handleCheckClick}
                  checked={editTemplate.sentiments.indexOf('Neutral') !== -1}
              />{' '}
              Neutral
              <CheckBox
                  id='Negative'
                  onChanged={handleCheckClick}
                  checked={editTemplate.sentiments.indexOf('Negative') !== -1}
              />{' '}
              Negative
            </div>
          </div>
        </div>
        <TextEditor
          placeholder='Draft Response Template Here'
          body={editTemplate.content}
          onChange={handleContentChanged}
        />
        <div className='controls pull-right'>
          <ButtonSpinner
            variant='outlined'
            color='primary'
            spin={deleteStarted}
            spinContent={<ButtonSpinnerText>Deleting...</ButtonSpinnerText>}
            onClick={() => setShowModal(true)}>
            Delete Template
          </ButtonSpinner>
          <ButtonSpinner
            style={{ marginLeft: '20px', color: '#FFF' }}
            disableElevation
            variant='contained'
            color='primary'
            spin={updateStarted}
            spinContent={<ButtonSpinnerText>Saving...</ButtonSpinnerText>}
            onClick={handleSave}>
            Save
          </ButtonSpinner>
        </div>
      </EditorContainer>
      <SimpleModal
        title='Are you sure you want to delete?'
        open={showModal}
        onClick={() => setShowModal(false)}>
        <FlexContainer>
          <BodyText>
            This will permanently delete <b>{editTemplate.title}</b> response template?
          </BodyText>
        </FlexContainer>
        <FlexContainer className='pt-4'>
          <div className='col-md-7'></div>
          <Button className='col-md-2' variant='main' onClick={() => setShowModal(false)}>
            No
          </Button>
          <Button className='col-md-2' color='error' variant='main' onClick={() => onDelete(editTemplate)}>
            Yes
          </Button>
        </FlexContainer>
      </SimpleModal>
      <ValidationModal show={showValidationModal} message={errorMessage} onClose={handleClose} />
    </>
  ) : (
    <Card noHover maxHeight='250px' maxWidth='1245px' width='97%' padding='12px 15px' margin='10px 10px 15px'>
      <ContentBox>
        <FlexContainer>
          <TitleBox>
            {editTemplate.title}
            <span className='pl-3' style={{ lineHeight: 1.5 }}>
              {editTemplate.types?.map(type => (
                  <SentimentSpan key={type} sentiment={type} />
              ))}
              {editTemplate.sentiments.map(sentiment => (
                <SentimentSpan key={sentiment} sentiment={sentiment} />
              ))}
            </span>
            <BsPencil className='iconButton pull-right' onClick={e => setIsEditing(!isEditing)} />
          </TitleBox>
        </FlexContainer>
        <HTMLContentText
          className={theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}
          dangerouslySetInnerHTML={{ __html: editTemplate.content }}
        />
      </ContentBox>
    </Card>
  )
}

export default EditableTemplateEditor
