import styled from 'styled-components'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(theme => ({
  fabButton: {
    color: '#FFF',
    borderRadius: '40px',
    minWidth: '20px',
    width: '40px',
    height: '40px',
  },
  progress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -23,
    marginLeft: -15,
  },
}))

export const CommentTextarea = styled.input`
  background-color: ${props => props.theme.background.dark + '4F'};
  color: ${props => props.theme.text.primary};
  padding: 10px;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
`
