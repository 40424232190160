import React from 'react'
import { useTheme } from '@mui/styles'
import { SideBar } from './Shared.S'
import LegendTranslation from '../../../translations/v2/TemplateLegend.json'

const SideLegend = () => {
  const theme = useTheme()
  return (
    <SideBar>
      <h5>Legend</h5>
      <p>{LegendTranslation.description}</p>
      <div
        className={`legends ${theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}`}>
        {Object.keys(LegendTranslation.legends).map(_key => [
          <h3 key={_key}>[{_key}]</h3>,
          <p key={_key + 'a'}>{LegendTranslation.legends[_key]}</p>,
        ])}
      </div>
    </SideBar>
  )
}

export default SideLegend
