import styled from 'styled-components'
import { makeStyles } from '@mui/styles'
import Title from '../../common/Title'
import Input from '../../common/Input'

export const useStyles = makeStyles(theme => ({
  chips: {
    maxHeight: '150px',
    display: 'flex',
    justifyContent: 'left',
    overflowY: 'auto',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  button: {
    color: '#FFF',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  textField: {
    width: '100%',
  },
  avatar: {
    color: '#FFF !important',
  },
}))

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: ${props => (props.padding ? props.padding : '')};
  ${props => (props.border ? `border: ${props.border};` : '')}
  align-items: flex-start;
  justify-content: flex-start;
  background: ${props =>
    props.theme.type === 'dark' ? props.theme.background.dark : props.theme.background.main};
  max-height: ${props => (props.maxHeight ? props.maxHeight : '40px')};
  width: ${props => (props.width ? props.width : '100%')};
  border: solid 1px ${props => props.theme.foreground.lightest};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '3px')};
  margin: ${props => (props.margin ? props.margin : '5px')};
  transition: all 0.3s linear;
  ${props =>
    props.noHover
      ? null
      : `&:hover {
                box-shadow: 0px 0px 10px rgba(57, 58, 60, 0.1);
                background: ${props.theme.background.dark};
                cursor: pointer;
            }`}
  ${props => (props.height ? props.height : null)}
    ${props => (props.override ? props.override : null)}
    position: relative;

  .iconButton {
    font-size: 25px;
    right: 15px;
    top: 15px;
    position: absolute;
    color: gray;

    &:hover {
      color: #fe9143;
      cursor: pointer;
    }
  }

  .cardContent {
    height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;

    p {
      font-size: 13px;
      color: black;
    }
  }
`

export const TitleBox = styled.div`
  display: inline-block;
  padding: 5px 0px;

  > p {
    color: rgb(144, 141, 138);
    font-size: 14px;
    line-height: 2;
  }
`

export const Header = styled(Title)`
  font-size: 1.4em;
  padding-bottom: 10px;
`

export const SearchInput = styled(Input)`
  &:disabled,
  &.disabled {
    opacity: 0.5;
  }
  width: 100%;
`
export const ItemContainer = styled.div`
    height: calc(100vh - 315px);
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    margin-top: 5px;
}`

export const EditorContainer = styled.div`
  border: solid 1px ${props => props.theme.foreground.lightest};
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 10px;
  margin-right: 0px;
  padding: 15px;
  width: 97%;
  background: ${props =>
    props.theme.type === 'dark' ? props.theme.background.dark : props.theme.background.main};

  .header {
    position: relative;
    > p {
      margin-bottom: 5px;
      > span {
        font-weight: bolder;
        text-transform: uppercase;
      }
    }

    > .closeButton {
      position: absolute;
      top: 0px;
      right: 0px;
      color: gray;
      border: none;
      font-weight: bold;
      font-size: 20px;

      &:hover {
        color: rgb(254, 145, 67);
        cursor: pointer;
      }
    }
  }

  .controls {
    margin: 5px 10px;
    right: 10px;
    display: flex;
    justify-content: flex-end;
  }
`
export const SavingText = styled.div`
  color: #fff;
  padding-top: 1px;
`

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 0px 20px;
  -webkit-box-align: center;

  .mainContent {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 0px auto;
  }

  label {
    margin-right: 50px;
    font-size: 0.98em;
    margin-top: 3px;
  }

  > .templateContent {
    color: rgb(144, 141, 138);
    border-top: solid 1px rgb(222, 222, 222);
    padding-top: 10px;
  }
`
