import React, { useState } from 'react'
import { FaCheck } from 'react-icons/fa'

function CheckBox(props) {
  const { checked, onChanged, id } = props
  const [checker, setChecker] = useState(checked)

  const handleClick = e => {
    let changed = !checker
    setChecker(changed)
    if (onChanged) onChanged(e.currentTarget, changed)
  }

  return (
    <>
      <div
        id={id}
        className={`checkbox ${checker ? 'das-primary-bg' : 'background-color-white'}`}
        onClick={handleClick}>
        <FaCheck size={'.85em'} color={'white'} onClick={e => e.preventDefault()} />
      </div>
    </>
  )
}

export default CheckBox
