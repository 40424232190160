import React, { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { BsPencil } from 'react-icons/bs'
import { useStyles, Card, EditorContainer, SavingText, TitleBox } from './Shared.S'
import ButtonSpinner from '../../common/ButtonSpinner'
import FlexContainer from '../../common/FlexContainer'
import ClientSearchInput from '../../common/ClientSearchInput'
import SimpleModal from '../../common/SimpleModal'
import BodyText from '../../common/BodyText'
import { useTheme } from '@mui/styles'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import DoneIcon from '@mui/icons-material/Done'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

function TagEditor(props) {
  const { tag, title, editing, handleClose, handleDelete, handleSubmit, updateStarted } = props
  const [isEditing, setIsEditing] = useState(editing)
  const [editTag, setEditTag] = useState(tag)
  const [selectedTag, setSelectedTag] = useState(null)
  const [modal, setModal] = useState({})
  const theme = useTheme()
  const classes = useStyles()

  const handleDisplayNameChanged = e => {
    editTag.displayName = e.target.value
    setEditTag({ ...editTag })
  }

  const handleDescriptionChanged = e => {
    editTag.description = e.target.value
    setEditTag({ ...editTag })
  }

  const handleCloseClick = e => {
    setIsEditing(false)
    if (handleClose) handleClose()
  }

  const handleClientSelected = client => {
    setSelectedTag(client)
  }

  const handleClientCleared = client => {
    setSelectedTag(null)
  }
  const handleDeleteClientClick = client => {
    editTag.clients = editTag.clients.filter(a => a.clientId !== client.clientId)
    setEditTag({ ...editTag })
  }

  const handleAddClient = () => {
    if (!selectedTag) return
    let exists = editTag.clients.find(a => a.clientId === selectedTag.id)
    if (!exists) {
      editTag.clients.push({
        clientId: selectedTag.id,
        clientName: selectedTag.name,
      })
      setEditTag({ ...editTag })
    }
  }

  const handleDeleteTagClick = () => {
    setModal({
      showModal: true,
      title: `Action: DELETE TAG`,
      content: `Are you sure that you want to delete "${tag.displayName}" tag?`,
      handleModalClick: () => {
        handleDelete(tag)
      },
    })
  }

  useEffect(() => {
    if (isEditing) {
      setEditTag({ ...tag, clients: [...tag.clients] })
      setSelectedTag(null)
    }
  }, [isEditing, tag])

  return isEditing ? (
    <EditorContainer>
      <div className='header'>
        <p>
          <span>{title}</span>
        </p>
        <FaTimes className='closeButton' onClick={handleCloseClick} />
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <TextField
            className={classes.textField}
            label='Display Name'
            value={editTag.displayName}
            margin='dense'
            variant='outlined'
            onChange={handleDisplayNameChanged}
          />
        </div>
        <div className='col-md-6'>
          <TextField
            className={classes.textField}
            label='Description'
            value={editTag.description}
            margin='dense'
            variant='outlined'
            onChange={handleDescriptionChanged}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 mt-3'>
          <ClientSearchInput width='100%' height={10} onClientSelected={handleClientSelected} onCleared={handleClientCleared}/>
        </div>
        <div className='col-md-3 mt-3'>
          <Button
            disableElevation
            className={classes.button}
            variant='contained'
            color='primary'
            disabled={!editTag.displayName || !selectedTag}
            onClick={handleAddClient}>
            Add Client
          </Button>
        </div>
      </div>
      <div className='row'>
        <div
          className={`col-md-12 mt-3 ${classes.chips} ${
            theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'
          }`}>
          {editTag.clients.map(client => (
            <Chip
              className={classes.chip}
              key={client.clientId}
              clickable
              avatar={<Avatar className={classes.avatar}>{client.clientName.charAt(0)}</Avatar>}
              label={client.clientName}
              color='primary'
              variant='outlined'
              onDelete={() => handleDeleteClientClick(client)}
              size='medium'
            />
          ))}
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='mt-5 float-right'>
            {editTag.tagId ? (
              <Button disableElevation variant='outlined' color='primary' onClick={handleDeleteTagClick}>
                DELETE TAG
              </Button>
            ) : (
              <Button disableElevation variant='outlined' color='primary' onClick={handleCloseClick}>
                CANCEL
              </Button>
            )}

            <ButtonSpinner
              style={{ marginLeft: '20px', color: '#FFF' }}
              disableElevation
              variant='contained'
              color='primary'
              spin={updateStarted}
              spinContent={<SavingText>Saving..</SavingText>}
              onClick={() => handleSubmit(editTag)}>
              SAVE
            </ButtonSpinner>
          </div>
        </div>
      </div>
      <SimpleModal
        title={modal.title}
        open={modal.showModal}
        onClick={() => setModal({ ...modal, showModal: false })}>
        <FlexContainer>
          <BodyText>{modal.content}</BodyText>
        </FlexContainer>
        <FlexContainer className='pt-4'>
          <div className='col-md-7'></div>
          <Button className='col-md-2' variant='outlined' onClick={() => setModal({ showModal: false })}>
            No
          </Button>
          <Button className='col-md-2' color='primary' variant='outlined' onClick={modal.handleModalClick}>
            Yes
          </Button>
        </FlexContainer>
      </SimpleModal>
    </EditorContainer>
  ) : (
    <Card noHover maxHeight='300px' width='97%' padding='12px 15px' margin='10px 5px 15px'>
      <TitleBox className='p-2'>
        {tag.displayName}
        <p>{tag.description}</p>
        <BsPencil
          className='iconButton pull-right'
          onClick={e => {
            setIsEditing(!isEditing)
          }}
        />
      </TitleBox>
      <div className='p-2'>
        <div
          className={`${classes.chips} ${
            theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'
          }`}>
          {tag.clients.map(client => (
            <Chip
              key={client.clientId}
              clickable
              avatar={<Avatar className={classes.avatar}>{client.clientName.charAt(0)}</Avatar>}
              label={client.clientName}
              color='primary'
              variant='outlined'
              deleteIcon={<DoneIcon />}
              size='medium'
            />
          ))}
        </div>
      </div>
    </Card>
  )
}

export default TagEditor
