// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles, useTheme } from '@mui/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import SimpleModal from '../../common/SimpleModal'
import FlexContainer from '../../common/FlexContainer'
import BodyText from '../../common/BodyText'
import AccountsTableRow from './AccountsTableRow'

const useStyles = makeStyles(theme => ({
  button: {
    color: '#FFF',
  },
  checkbox_header: {
    color: theme.palette.common.white,
  },
  checkbox: {
    color: theme.palette.primary.light,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

export default function AccountsTableView(props) {
  const {
    data,
    count,
    rows,
    page,
    descending,
    orderBy,
    onPageChange,
    onRowCountChange,
    onRowSelected,
    onRowSelectedClick,
    onHeaderClick,
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const cellHeaders = [
    {
      id: 'accountName',
      sortbyField: 'client.clientName',
      label: 'Account Name',
      width: 30,
      sortable: true,
      align: 'left',
    },
    {
      id: 'accountId',
      sortbyField: 'client.clientId',
      label: 'Account ID',
      width: 30,
      sortable: true,
      align: 'left',
    },
    { id: 'accountStatus', sortbyField: 'enabled', label: 'Account Status', width: 50, sortable: true, align: 'left' },
    { id: 'site', sortbyField: 'reviewSites.reviewSiteId', label: 'Site', width: 30, sortable: true, align: 'left' },
    {
      id: 'sentiment',
      sortbyField: 'sentiment',
      label: 'Sentiment',
      width: 30,
      sortable: true,
      align: 'left',
    },
    {
      id: 'proposals',
      sortbyField: 'proposals',
      label: 'Proposals',
      width: 30,
      sortable: true,
      align: 'left',
    },
    {
      id: 'tag',
      sortbyField: 'tag',
      label: 'Tag',
      width: 30,
      sortable: true,
      align: 'left',
    },
  ]


  const [selectAll, setSelectAll] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [modal, setModal] = useState({})

  const handleChangePage = (event, newPage) => {
    onPageChange(newPage)
  }

  const handleChangeRowsPerPage = event => {
    onRowCountChange(parseInt(event.target.value, 10))
  }

  const handleSelectAllChange = event => {
    let checked = event.target.checked,
      selected = []

    if (checked) selected = data.map(a => a.id)

    setSelectedRows(selected)
    setSelectAll(checked)
    onRowSelected(selected)
  }

  const handleRowCheckChange = event => {
    let id = event.target.name,
      filtered = null

    if (event.target.checked) {
      filtered = [...selectedRows, id]
      setSelectedRows(filtered)
    } else {
      filtered = selectedRows.filter(id => id !== id)
      setSelectedRows(filtered)
    }
    onRowSelected(filtered)
  }

  const handleRowClick = item => {
    let action = item.status === 'Closed' ? 'reopen' : 'close'
    setModal({
      showModal: true,
      title: `Action: Review ${item.id}`,
      content: `Are you sure that you want to ${action} item ${item.id}?`,
      item,
    })
  }

  const handleHeaderClick = cell => {
    if (cell.sortbyField === orderBy) onHeaderClick(cell.sortbyField, !descending)
    else onHeaderClick(cell.sortbyField, true)
  }

  const handleModalYes = () => {
    onRowSelectedClick(modal.data)
  }

  useEffect(() => {
    setSelectedRows([])
  }, [data])

  return (
    <Paper>
      <TableContainer
        className={`das-account-grid ${
          theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'
        }`}>
        <Table stickyHeader padding='checkbox'>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Checkbox
                  className={classes.checkbox_header}
                  color='default'
                  value={selectAll}
                  onChange={handleSelectAllChange}
                />
              </StyledTableCell>
              {cellHeaders.map(cell => (
                <StyledTableCell
                  key={cell.id}
                  align={cell.align}
                  sortDirection={orderBy === cell.sortbyField ? (descending ? 'desc' : 'asc') : false}
                  width={cell.width}>
                  <TableSortLabel
                    active={orderBy === cell.sortbyField}
                    direction={descending ? 'desc' : 'asc'}
                    onClick={() => handleHeaderClick(cell)}>
                    {cell.label}
                    {orderBy === cell.sortbyField ? (
                      <span className={classes.visuallyHidden}>
                        {descending ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </StyledTableCell>
              ))}
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(item => (
              <AccountsTableRow
                key={item.id}
                model={item}
                selectedRows={selectedRows}
                onRowCheckChange={handleRowCheckChange}
                onRowClick={handleRowClick}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        count={count}
        rowsPerPage={rows}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
      />
      <SimpleModal title={modal.title} open={modal.showModal} onClick={() => setModal({ showModal: false })}>
        <FlexContainer>
          <BodyText>{modal.content}</BodyText>
        </FlexContainer>
        <FlexContainer className='pt-4'>
          <div className='col-md-7'></div>
          <Button className='col-md-2' variant='outlined' onClick={() => setModal({ showModal: false })}>
            No
          </Button>
          <Button className='col-md-2' color='primary' variant='outlined' onClick={handleModalYes}>
            Yes
          </Button>
        </FlexContainer>
      </SimpleModal>
    </Paper>
  )
}
