import ReviewServiceFactory from './reviews'
import ConfigurationsFactory from './configurations'
import ResponseTemplatesServiceFactory from './response-templates'
import SignatureTemplatesServiceFactory from './signature-templates'
import EmailTemplatesServiceFactory from './email-templates'
import ClientContactsServiceFactory from './contacts'
import CommentsServiceServiceFactory from './comments'
import AccountsServiceServiceFactory from './accounts'
import AuthServiceFactory from './auth'
import ReviewSiteServiceFactory from './reviewsites'
import TagServiceFactory from './tags'
import KeywordServiceFactory from './keywords'

export const RadarServices = {
  Reviews: Symbol('Reviews'),
  Configurations: Symbol('Configurations'),
  ResponseTemplates: Symbol('ResponseTemplates'),
  SignatureTemplates: Symbol('SignatureTemplates'),
  EmailTemplates: Symbol('EmailTemplates'),
  ClientContacts: Symbol('ClientContacts'),
  Comments: Symbol('Comments'),
  Accounts: Symbol('Accounts'),
  Auth: Symbol('Auth'),
  ReviewSites: Symbol('ReviewSites'),
  Tags: Symbol('Tags'),
  Keywords: Symbol('Keywords'),
}

const ServiceFactory = function () {}

ServiceFactory.prototype._factories = new Map()

ServiceFactory.prototype.create = function (factoryType) {
  let instance = this._factories.get(factoryType)
  if (!instance) {
    switch (factoryType) {
      case RadarServices.Reviews:
        instance = Object.create(ReviewServiceFactory.prototype)
        break
      case RadarServices.Configurations:
        instance = Object.create(ConfigurationsFactory.prototype)
        break
      case RadarServices.ResponseTemplates:
        instance = Object.create(ResponseTemplatesServiceFactory.prototype)
        break
      case RadarServices.SignatureTemplates:
        instance = Object.create(SignatureTemplatesServiceFactory.prototype)
        break
      case RadarServices.EmailTemplates:
        instance = Object.create(EmailTemplatesServiceFactory.prototype)
        break
      case RadarServices.ClientContacts:
        instance = Object.create(ClientContactsServiceFactory.prototype)
        break
      case RadarServices.Comments:
        instance = Object.create(CommentsServiceServiceFactory.prototype)
        break
      case RadarServices.Accounts:
        instance = Object.create(AccountsServiceServiceFactory.prototype)
        break
      case RadarServices.Auth:
        instance = Object.create(AuthServiceFactory.prototype)
        break
      case RadarServices.ReviewSites:
        instance = Object.create(ReviewSiteServiceFactory.prototype)
        break
      case RadarServices.Tags:
        instance = Object.create(TagServiceFactory.prototype)
        break
      case RadarServices.Keywords:
        instance = Object.create(KeywordServiceFactory.prototype)
        break
      default:
        return null
    }
    this._factories.set(factoryType, instance)
  }
  return instance.create()
}

const RadarServiceFactory = Object.create(ServiceFactory.prototype)

export default RadarServiceFactory
