import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'space-between')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  width: ${props => (props.width ? props.width : '100%')};
  margin: ${props => (props.margin ? props.margin : '0px')};
  padding: ${props => (props.padding ? props.padding : '0px')};
  height: ${props => (props.height ? props.height : 'auto')};
  ${props => (props.addProps ? props.addProps : null)}
`

const FlexContainer = props => {
  return <Container {...props}>{props.children}</Container>
}

export default FlexContainer
