import React from 'react'
import { connect } from 'react-redux'
import { MdContentCopy } from 'react-icons/md'
import { useTransition, animated } from 'react-spring'
import { useTheme } from 'styled-components'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import AutoFixHigh from '@mui/icons-material/AutoFixHigh'
import SLAIndicator from '../../common/SLAIndicator'
import Tags from './Tags'
import Notes from './Notes'
import {
  Banner,
  HorizonatalBar,
  Section,
  Header,
  NotesContainer,
  statusColors,
} from './ReviewResponseSidebar.S'
import { addNotificationAction, NotificationType } from '../../../store/actions/notifications'
import AiPrompts from './AiPrompts'

function ReviewResponseSidebar({ review, addNotification }) {
  const theme = useTheme()

  const disputing = useTransition(review.disputed, null, {
    from: { width: '100%', position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const escalation = useTransition(review.escalated, null, {
    from: { width: '100%', position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const currentStatus = useTransition(review.status, null, {
    from: { opacity: 0, width: '100%', position: 'absolute' },
    enter: { opacity: 1 },
    leave: { opacity: 0, position: 'absolute' },
  })

  const validateHTTPS = url => (/(http(s?)):\/\//.test(url) ? url : `https://${url}`)

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(review.client.clientName)
    addNotification('Client Name copied to clipboard!', NotificationType.SUCCESS_NOTIFICATION)
  }

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex justify-content-center pt-4">
        <SLAIndicator review={review} size={140} strokeWidth={7} centeredText={true} />
      </div>
      <div
        style={{
          textAlign: 'center',
          paddingTop: '10px',
          display: 'flex',
          flexDirection: 'column'
        }}>
        {currentStatus.map(({ item, props, key }) => {
          return (
            item && (
              <animated.div key={key} style={props}>
                <Banner bgColor={statusColors[item.toLowerCase()]}>{item}</Banner>
              </animated.div>
            )
          )
        })}
      </div>
      <div
        style={{
          paddingTop: '45px',
          textAlign: 'center',
          display: 'flex',
          width: '100%',
          flexDirection: 'column'
        }}>
        {escalation.map(({ item, props, key }) => {
          return item ? (
            <animated.div key={key} style={props}>
              <Banner bgColor={statusColors['escalated']}>Escalated</Banner>
            </animated.div>
          ) : (
            <animated.div key={key} style={props}>
              <Banner bgColor={statusColors['notactive']}>escalated</Banner>
            </animated.div>
          )
        })}
      </div>
      <div
        style={{
          paddingTop: '45px',
          textAlign: 'center',
          display: 'flex',
          width: '100%',
          flexDirection: 'column'
        }}>
        {disputing.map(({ item, props, key }) => {
          return item ? (
            <animated.div key={key} style={props}>
              <Banner bgColor={statusColors['disputed']}>Disputed</Banner>
            </animated.div>
          ) : (
            <animated.div key={key} style={props}>
              <Banner bgColor={statusColors['notactive']}>disputed</Banner>
            </animated.div>
          )
        })}
      </div>
      <Section>
        <h3 style={{ paddingTop: '64px' }}>Review Site</h3>
        <div className="h-100 d-flex flex-column">
          {review.reviewUrl ? (
            <a href={validateHTTPS(review.reviewUrl)} target="_blank" rel="noopener noreferrer">
              Go to Review
            </a>
          ) : (
            <p style={{ marginTop: '4px' }}>
              <i>No Review Link Found</i>
            </p>
          )}
        </div>
      </Section>
      <HorizonatalBar />
      <Section>
        {review.client && (
          <Header>
            {review.client.clientName}{' '}
            <MdContentCopy onClick={handleCopyToClipboard} style={{ color: '#FE9143', cursor: 'pointer' }} />
          </Header>
        )}
        {review.displayDisputeBadge && (
          <Banner
            maxWidth="30%"
            fontSize="12px"
            padding="5px 0px"
            margin="0px"
            bgColor={statusColors['flag']}
            color="#F57C7C"
            textTransform="none">
            Dispute
          </Banner>
        )}
      </Section>
      <Section>
        <Header>Tags</Header>
        <Tags review={review} />
      </Section>
      <NotesContainer className="d-flex flex-column pb-3">
        <div className="flex-row pb-3">
          <AttachFileIcon />
          <span style={{ fontWeight: 600, marginLeft: '10px' }}>Notes</span>
        </div>
        <Notes review={review} />
      </NotesContainer>
      <div className="p-2"></div>
      <NotesContainer className="d-flex flex-column pb-3">
        <div className="flex-row pb-3">
          <AutoFixHigh />
          <span style={{ fontWeight: 600, marginLeft: '10px' }}>AI Prompts</span>
        </div>
        <AiPrompts review={review} />
      </NotesContainer>
      <div className="p-2"></div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    review: state.review.review,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addNotification: (text, status) => dispatch(addNotificationAction(text, status)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewResponseSidebar)
