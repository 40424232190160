import styled from 'styled-components'
import { makeStyles } from '@mui/styles'

export const useMakeStyles = makeStyles({
  pager: {
    '& .Mui-selected': {
      color: '#FFF !important',
    },
  },
})

export const PagerView = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${props => props.theme.background.dark};
  background-color: ${props =>
    props.theme.type === 'dark' ? props.theme.background.light : props.theme.background.main};
`
export const ScrollProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background: ${props => props.theme.background.dark};
  display: ${props => (props.show ? 'block' : 'none')};
  z-index: 99;
  > div {
    height: 8px;
    background: ${props => props.theme.primary.light};
    width: 0%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`
