import styled, { css, keyframes } from 'styled-components'
import defaultTheme from '../../themes/default'

const ellipsisDiv = css`
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: ${defaultTheme.primary.lighter};
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
`

const ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`

const ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`

const ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

export const Container = styled.span`
  display: ${props => (props.Hide ? 'none' : 'flex')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
  height: ${props => (props.Height ? props.Height + 'px' : 'auto')};
  align-items: center;
`

export const LdsEllipsis = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
`

export const EllipsisDiv1 = styled.div`
  ${ellipsisDiv}
  left: 6px;
  animation: ${ellipsis1} 0.6s infinite;
`

export const EllipsisDiv2 = styled.div`
  ${ellipsisDiv}
  left: 6px;
  animation: ${ellipsis2} 0.6s infinite;
`

export const EllipsisDiv3 = styled.div`
  ${ellipsisDiv}
  left: 26px;
  animation: ${ellipsis2} 0.6s infinite;
`

export const EllipsisDiv4 = styled.div`
  ${ellipsisDiv}
  left: 45px;
  animation: ${ellipsis3} 0.6s infinite;
`
