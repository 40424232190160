import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTheme } from 'styled-components'
import RadarServiceFactory, { RadarServices } from '../../../services'
import { NotesContainer, NotesParagraph, Editor, UpdatingText } from './Notes.S'
import ProgressLoader from '../../common/ProgressLoader'
import Button from '@mui/material/Button'
import ButtonSpinner from '../../common/ButtonSpinner'
import { addNotificationAction, NotificationType } from '../../../store/actions/notifications'

function Notes({ review, addNotification }) {
  const [notes, setNotes] = useState('')
  const [account, setAccount] = useState({})
  const [fetchStarted, setFetchStarted] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [updateStarted, setUpdateStarted] = useState(false)
  const theme = useTheme()

  async function saveNotes() {
    let service = RadarServiceFactory.create(RadarServices.Accounts),
      retval = await service.updateAccount(account.clientId, { notes: notes })
    if (retval.Ok) {
      account.notes = notes
      setEditMode(false)
      addNotification({
        type: NotificationType.SUCCESS_NOTIFICATION,
        text: 'Update completed successfully',
      })
    } else {
      addNotification({
        type: NotificationType.ERROR_NOTIFICATION,
        text: 'Update failed miserably.. Go tell someone please',
      })
    }
    setUpdateStarted(false)
  }

  const handleNoteChange = e => {
    setNotes(e.target.value)
  }

  const handleCancelClick = () => {
    setNotes(account.notes)
    setEditMode(false)
  }

  const handleSaveClick = () => {
    if (!updateStarted) {
      setUpdateStarted(true)
      saveNotes()
    }
  }

  useEffect(() => {
    async function fetchConfiguration() {
      setFetchStarted(true)

      let service = RadarServiceFactory.create(RadarServices.Accounts),
        retval = await service.getAccountById(review.client?.clientId, { useProjection: true })
      if (retval.Ok) {
        setAccount(retval.Content)
        setNotes(retval.Content.notes)
      }

      setFetchStarted(false)
    }

    fetchConfiguration()
  }, [review])

  return (
    <NotesContainer>
      {fetchStarted && <ProgressLoader />}
      {editMode ? (
        <div className='d-flex flex-column'>
          <Editor value={notes} onChange={handleNoteChange} />
          <div className='p-1'>
            <ButtonSpinner
              disableElevation
              style={{ color: '#FFF' }}
              color='primary'
              variant='contained'
              spin={updateStarted}
              spinContent={<UpdatingText>Updating..</UpdatingText>}
              spinnerProps={{ margin: '2px 0 0 -8px' }}
              onClick={handleSaveClick}>
              Update
            </ButtonSpinner>
            <Button
              style={{ marginLeft: '20px' }}
              color='primary'
              variant='outlined'
              onClick={handleCancelClick}>
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <NotesParagraph
          className={theme.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}
          onClick={() => setEditMode(true)}>
          {notes}
        </NotesParagraph>
      )}
    </NotesContainer>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    addNotification: notification => {
      dispatch(addNotificationAction(notification.text, notification.type))
    },
  }
}

export default connect(null, mapDispatchToProps)(Notes)
