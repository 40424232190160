import Service from './service'
import Globals from './global'

class CommentsService extends Service {
  async saveComment(reviewId, comment) {
    let retval = {},
      url = Globals.toURL(`/reviews/${reviewId}/comments`)
    try {
      retval = await this.post(url, comment)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
}

const CommentsServiceServiceFactory = function () {}
CommentsServiceServiceFactory.prototype.create = () => new CommentsService()

export default CommentsServiceServiceFactory
