import styled from 'styled-components'

export const InputControl = styled.input`
  color: ${props => props.theme.text.primary};
  outline: none;
  border: 1px solid ${props => props.theme.foreground.lightest};
  border-radius: 4px;
  font-size: ${props => (props.fontSize ? props.fontSize : `14px`)};
  height: ${props => (props.height ? props.height : ``)};
  margin: ${props => (props.margin ? props.margin : `6px`)};
  padding: ${props => (props.padding ? props.padding : `8px 12px`)};
  width: ${props => props.width || '200px'};
  background-color: ${props =>
    props.variant ? props.theme.background[props.variant] : props.theme.background.darker};
  ${props => (props.center ? 'text-align: center;' : null)}
  &:active, &:focus {
    outline: 1px solid ${props => props.theme.secondary.main};
  }
  &:invalid {
    background-color: ${props => props.theme.error.lightest};
    border: 3px solid ${props => props.theme.error.dark};
  }
`
export const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.foreground.dark};
`
