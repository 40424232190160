import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import FlexContainer from '../../common/FlexContainer'
import CheckBox from '../../common/CheckBox'
import ButtonSpinner from '../../common/ButtonSpinner'
import { updateAccountStartedAction, updateAccountCompletedAction } from '../../../store/actions/account'
import { HeaderBox, TitleBox, Header, Body, ContentBox, SavingText } from './Shared.S'

function Sentiment(props) {
  const { account, updateStarted, updatedAccount, saveAccount } = props
  const [sentiments, setSentiments] = useState(account.sentiments)
  const [reporting, setReporting] = useState(account.enableReportingOnly)

  const handleCheckClick = (e, checked) => {
    let updated = null
    if (checked) updated = [...sentiments.flat(), e.id]
    else updated = sentiments.filter(a => a !== e.id)
    setSentiments(updated)
  }

  const handleReportingClick = (e, checked) => setReporting(checked)

  const handleSaveClick = () => {
    console.log('Sentiments called')
    if (!updateStarted)
      saveAccount(account.clientId, {
        sentiments: sentiments,
        enableReportingOnly: reporting,
      })
  }

  return (
    <FlexContainer justifyContent='start' direction='column' width='45%'>
      <HeaderBox>
        <TitleBox>
          <Header>Sentiment</Header>
          <Body>
            Configure settings for responding to reviews. Select the review sentiments that are required for
            this account. These will display in the queue.
          </Body>
        </TitleBox>
      </HeaderBox>
      <ContentBox className='pt-3'>
        <CheckBox id='Positive' onChanged={handleCheckClick} checked={sentiments.includes('Positive')} />
        <label className='text-secondary header' htmlFor='positive'>
          Positive
        </label>
        <CheckBox id='Neutral' onChanged={handleCheckClick} checked={sentiments.includes('Neutral')} />
        <label className='text-secondary header' htmlFor='neutral'>
          Neutral
        </label>
        <CheckBox id='Negative' onChanged={handleCheckClick} checked={sentiments.includes('Negative')} />
        <label className='text-secondary header' htmlFor='negative'>
          Negative
        </label>
      </ContentBox>
      <HeaderBox className='pt-5'>
        <TitleBox>
          <Header>Reporting Only</Header>
          <Body>
            An account may not want DAS to response to reviews.They only want us to notify them when they've
            received a review on a site and they want to go and respond to it themselves.
          </Body>
        </TitleBox>
      </HeaderBox>
      <ContentBox className='pt-3'>
        <CheckBox name='reporting' onChanged={handleReportingClick} checked={reporting} />
        <label className='text-secondary header' htmlFor='reporting'>
          Enable reporting only
        </label>
      </ContentBox>
      <ContentBox className='pt-5'>
        <ButtonSpinner
          disableElevation
          style={{ color: '#FFF' }}
          variant='contained'
          color='primary'
          spin={updateStarted}
          spinContent={<SavingText>Saving..</SavingText>}
          onClick={handleSaveClick}>
          Save
        </ButtonSpinner>
      </ContentBox>
    </FlexContainer>
  )
}

function mapStateToProps(state) {
  return {
    account: state.account.account,
    updateStarted: state.account.updateStarted,
    updatedAccount: state.account.updatedAccount,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveAccount: (clientId, account) => {
      dispatch(updateAccountStartedAction())
      dispatch(updateAccountCompletedAction(clientId, account))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sentiment)
