import Service from './service'
import Globals from './global'

class AuthService extends Service {
  async getAuthSettings() {
    let retval = {},
      url = Globals.toURL('/auth')
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getLogglySettings() {
    let retval = {},
      url = Globals.toURL('/auth/log')
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
}

const AuthServiceFactory = function () {}
AuthServiceFactory.prototype.create = () => new AuthService()

export default AuthServiceFactory
