import { createAction } from 'redux-actions'
import ReviewServiceFactory, { RadarServices } from '../../services'

export const SaveActionTypes = {
  ADD_TO_QUEUE: 'ADD_TO_QUEUE',
  REMOVE_FROM_QUEUE: 'REMOVE_FROM_QUEUE',
  ADD_TO_PROPOSED: 'ADD_TO_PROPOSED',
  REMOVE_FROM_PROPOSED: 'REMOVE_FROM_PROPOSED',
  ADD_TO_EXPIRED_PROPOSALS: 'ADD_TO_EXPIRED_PROPOSALS',
  REMOVE_FROM_EXPIRED_PROPOSALS: 'REMOVE_FROM_EXPIRED_PROPOSALS',
  ADD_TO_ESCALATED: 'ADD_TO_ESCALATED',
  REMOVE_FROM_ESCALATED: 'REMOVE_FROM_ESCALATED',
  ADD_TO_DISPUTED: 'ADD_TO_DISPUTED',
  REMOVE_FROM_DISPUTED: 'REMOVE_FROM_DISPUTED',
  ADD_TO_DONE: 'ADD_TO_DONE',
  REMOVE_FROM_DONE: 'REMOVE_FROM_DONE',
}

export const ActionTypes = {
  REVIEW_UPDATE_SAVE_DRAFT_STARTED: 'REVIEW_UPDATE_SAVE_DRAFT_STARTED',
  REVIEW_UPDATE_REVIEW_ACTION_STARTED: 'REVIEW_UPDATE_REVIEW_ACTION_STARTED',
  REVIEW_SAVE_REVIEW_COMPLETED: 'REVIEW_SAVE_REVIEW_COMPLETED',
  REVIEW_FETCH_REVIEW: 'REVIEW_FETCH_REVIEW',
  REVIEW_FETCH_REVIEW_COMPLETED: 'REVIEW_FETCH_REVIEW_COMPLETED',
  REVIEW_SHOW_REVIEW_RESPONSE: 'REVIEW_SHOW_REVIEW_RESPONSE',
  REVIEW_CLEAR_DETAIL_VIEW: 'REVIEW_CLEAR_DETAIL_VIEW',
  REVIEW_FETCH_REVIEW_HISTORY: 'REVIEW_FETCH_REVIEW_HISTORY',
  DISPLAY_REASON_COMMENT: 'DISPLAY_REASON_COMMENT',
}

//---------------------------- Review Response Actions

export const reviewSaveDraftStartedAction = createAction(ActionTypes.REVIEW_UPDATE_SAVE_DRAFT_STARTED)

export const reviewSaveActionStartedAction = createAction(
  ActionTypes.REVIEW_UPDATE_REVIEW_ACTION_STARTED,
  actionFor => ({ actionFor })
)

export const reviewSaveReviewCompletedAction = createAction(
  ActionTypes.REVIEW_SAVE_REVIEW_COMPLETED,
  (review, saveAction) => ({ review, saveAction })
)

export const fetchReviewAction = createAction(ActionTypes.REVIEW_FETCH_REVIEW)

export const fetchReviewCompleteAction = createAction(
  ActionTypes.REVIEW_FETCH_REVIEW_COMPLETED,
  async reviewId => {
    let service = ReviewServiceFactory.create(RadarServices.Reviews),
      result = await service.getReview(reviewId)
    return { review: result.Ok ? result.Content : null }
  }
)

export const fetchReviewHistoryCompleteAction = createAction(
  ActionTypes.REVIEW_FETCH_REVIEW_HISTORY,
  async reviewId => {
    let service = ReviewServiceFactory.create(RadarServices.Reviews),
      result = await service.getReviewTimeLine(reviewId)
    return { review: result.Ok ? result.Content : null }
  }
)

export const reviewShowReviewResponseAction = createAction(
  ActionTypes.REVIEW_SHOW_REVIEW_RESPONSE,
  review => ({ review })
)

export const reviewClearDetailViewAction = createAction(ActionTypes.REVIEW_CLEAR_DETAIL_VIEW)
export const reviewDisplayReasonCommentAction = createAction(
  ActionTypes.DISPLAY_REASON_COMMENT,
  payload => payload
)
