import styled from 'styled-components'
import { hover } from '../Utils/hover.S'

export default styled.button`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  outline: none;
  border: none;
  user-select: none;
  border-radius: 3px;
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  margin: ${props => (props.margin ? props.margin : '0px 10px')};
  padding: ${props => (props.padding ? props.padding : '8px 18px')};
  transition: all 0.3s linear;
  color: ${props => {
    if (props.disabled && props.variant === 'lightest') {
      return props.theme.foreground.lightest
    } else if (props.color !== 'background' && props.disabled) {
      return props.theme.background.main
    } else if (props.disabled) {
      return props.theme[props.color].lighter
    } else if (props.variant === 'main' && props.color !== 'background') {
      return props.theme.background.main
    } else if (props.color !== 'background') {
      return props.theme[props.color].main
    } else {
      return props.theme.foreground.main
    }
  }};
  background-color: ${props => {
    if (props.disabled) {
      if (props.variant === 'lightest') {
        return props.theme.background.dark
      }
      return props.theme[props.color].light
    } else if (props.hover === 'lightest') {
      // this option is for buttons you want to have a text color but white background with a hover of the `lightest` variant
      return props.theme.background.main
    }
    return props.theme[props.color][props.variant]
  }};
  ${props =>
    !props.disabled &&
    hover(
      props.selected,
      props.color,
      props.hover && typeof props.hover === 'string' ? props.hover : null,
      props.theme
    )}
  ${props => (props.override ? props.override : null)}
  span {
    transition: all 0.5s linear;
  }
`
