import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useFlagsmith } from 'flagsmith/react'

function FlagFeature({ feature, children, user, onHasFeature }) {
    const { hasFeature, identify, isIdentified } = useFlagsmith()
  const checkFeature = () => {
    let enabled = hasFeature(feature)
    return enabled ? <>{children}</> : null
  }

  const runIdentify = async () => {
    //let flags = await identify(user.username, { name: user.fullname })
    //if (flags && onHasFeature) {
      //onHasFeature(flags[feature].enabled)
    //}
  }

  useEffect(() => {
    if (!isIdentified) runIdentify()
    else if (onHasFeature) onHasFeature(hasFeature(feature))
  })

  return <>{isIdentified && checkFeature()}</>
}

function mapStateToProps(state) {
  return {
    user: state.user.profile || {},
  }
}

export default connect(mapStateToProps)(FlagFeature)
