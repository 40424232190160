import React, { useEffect, useState } from 'react'
import Button from '../../common/Button'
import styled from 'styled-components'

const Container = styled.div`
  button:first-of-type {
    margin-left: 0;
  }
`

function FilterTabs(props) {
  const { tabs, activeTab, onFilterChanged } = props
  const [selectedTab, setSelectedTab] = useState(activeTab)

  const handleTabClick = (e, tab) => {
    e.preventDefault()
    onFilterChanged(tab)
    setSelectedTab(tab)
  }

  useEffect(() => setSelectedTab(activeTab), [activeTab])

  return (
    <Container className='d-flex flex-wrap'>
      {tabs.map((tab, i) => {
        // All this does is capitalize the first letter of 'tab'
        const title = tab.replace(/^\w/, c => c.toUpperCase())
        return (
          <Button
            key={`filter-tab-${i}`}
            color={selectedTab === tab ? 'primary' : 'background'}
            variant={'main'}
            onClick={e => handleTabClick(e, tab)}
            selected={activeTab === tab}
            hover={true}>
            {title}
          </Button>
        )
      })}
    </Container>
  )
}

export default FilterTabs
