import reduceReducers from 'reduce-reducers'
import { handleAction } from 'redux-actions'
import { ActionTypes } from '../actions/user'

const defaultState = {}

const handleUserLoginReducer = handleAction(
  ActionTypes.USER_LOGIN,
  {
    next(state, action) {
      return {
        ...state,
        profile: action.payload,
        loggedIn: true,
      }
    },
  },
  defaultState
)

const handleUserLogoutReducer = handleAction(
  ActionTypes.USER_LOGOUT,
  {
    next(state, action) {
      return {
        ...state,
        profile: {},
        loggedIn: false,
      }
    },
  },
  defaultState
)

const handleThemeChangeReducer = handleAction(
  ActionTypes.USER_MODE_CHANGED,
  {
    next(state, action) {
      return {
        ...state,
        normalMode: action.payload,
      }
    },
  },
  defaultState
)

const initialState = {
  profile: {},
  loggedIn: false,
  normalMode: true,
}

export default () =>
  reduceReducers(initialState, handleUserLoginReducer, handleUserLogoutReducer, handleThemeChangeReducer)
