import styled from 'styled-components'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => (props.padding ? props.padding : '')};
  ${props => (props.border ? `border: ${props.border};` : '')}
  align-items: flex-start;
  justify-content: flex-start;
  background: ${props =>
    props.theme.type === 'dark' ? props.theme.background.dark : props.theme.background.main};
  max-height: ${props => (props.maxHeight ? props.maxHeight : '40px')};
  width: ${props => (props.width ? props.width : '100%')};
  border: solid 1px ${props => props.theme.foreground.lightest};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '3px')};
  margin: ${props => (props.margin ? props.margin : '5px')};
  transition: all 0.3s linear;
  ${props =>
    props.noHover
      ? null
      : `&:hover {
                box-shadow: 0px 0px 10px rgba(57, 58, 60, 0.1);
                background: ${props.theme.background.dark};
                cursor: pointer;
            }`}
  ${props => (props.height ? props.height : null)}
    ${props => (props.override ? props.override : null)}
  position: relative;

  .iconButton {
    font-size: 25px;
    right: 15px;
    top: 15px;
    position: absolute;
    color: gray;

    &:hover {
      color: #fe9143;
      cursor: pointer;
    }
  }
`

export const SideBar = styled.div`
  background: ${props => (props.theme.type === 'dark' ? props.theme.background.main : 'rgb(248, 248, 248)')};
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${props => (props.theme.type === 'dark' ? props.theme.background.darker : 'transparent')};
  max-width: 400px;
  width: 20%;

  & > h1 {
    font-size: 18px;
    margin: 6px 0px;
  }

  & > p {
    font-size: 12px;
    margin: 6px 0px 16px;
    font-family: 'Open Sans', Roboto, sans-serif;
    box-sizing: border-box;
  }

  & .legends {
    background: ${props => (props.theme.type === 'dark' ? props.theme.background.darker : '#fff')};
    height: calc(100vh - 315px);
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 10px;

    & > h3 {
      font-size: 12px;
      margin: 0px;
    }
    & > p {
      font-size: 13px;
      margin: 6px 0px 12px;
    }
  }

  & > p {
    line-height: 1.5;
    margin: 6px 0px 16px;
    font-size: 12px;
  }
`

export const EditorContainer = styled.div`
  border: solid 1px ${props => props.theme.foreground.lightest};
  margin: 15px 10px;
  padding: 15px;
  position: relative;
  background: ${props =>
    props.theme.type === 'dark' ? props.theme.background.dark : props.theme.background.main};

  .header {
    position: relative;

    > p {
      margin-bottom: 5px;

      > span.bolder {
        font-weight: bolder;
      }
    }

    > .closeButton {
      position: absolute;
      top: 0px;
      right: 0px;
      color: gray;
      border: none;
      font-weight: bold;
      font-size: 20px;

      &:hover {
        color: rgb(254, 145, 67);
        cursor: pointer;
      }
    }
  }

  .sentiments {
    display: flex;
    align-items: center;

    > input {
      margin-left: 0px;
    }

    > .checkbox {
      margin-left: 15px;
    }
  }

  .ql-container {
    height: 150px;
  }

  .controls {
    margin: 5px 10px;
    right: 10px;
    display: flex;
    justify-content: flex-end;
  }
`

export const ButtonSpinnerText = styled.div`
  color: #fff;
  padding-top: 1px;
`

// The sentiment span receieves the array of sentiments
// that should ONLY include 1 item, and then we compute
// the color & text for display. -Bryan Mierke 12/17/18
export const SentimentSpan = styled.span`
  padding: 0px;
  color: ${props => props.theme.background.main};
  border-radius: 15px;
  padding: 4px 16px;
  margin-right: 10px;
  background-color: ${props => {
    if (props.sentiment === 'Negative') {
      return props.theme.error.main
    } else if (props.sentiment === 'Neutral') {
      return props.theme.warning.main
    } else if (props.sentiment === 'Positive') {
      return props.theme.success.main
    }
    else {
        return props.theme.primary.main
    }
  }};
  &::after {
    content: '${props => props.sentiment}';
  }
`

export const HTMLContentText = styled.div`
  overflow-y: auto;
  max-height: 200px;
  width: 100%;
`
