import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@mui/material/Typography'
import RadarSession from '../../../lib/session'
import { CommentChatBubble, CommentView } from './ResponseReason.S'
import { CommentTextarea, useStyles } from './Comments.S'
import Button from '@mui/material/Button'
import SendIcon from '@mui/icons-material/Send'
import CircularProgress from '@mui/material/CircularProgress'
import {
  reviewDisplayReasonCommentAction,
  reviewSaveActionStartedAction,
  reviewSaveReviewCompletedAction,
  SaveActionTypes,
} from '../../../store/actions/review'
import { ActionIDs } from '../../../store/actions/reviews'
import ReviewServiceFactory, { RadarServices } from '../../../services'
import { addNotificationAction, NotificationType } from '../../../store/actions/notifications'
import { Spinner } from '../../common/ButtonSpinner/ButtonSpinner.S'

function ResponseReason(props) {
  const {
    displayReasonComment,
    review,
    saveActionRequest,
    addNotification,
    saveReviewCompleted,
    setDisplayReasonComment,
  } = props
  // useEffect(() => {
  //
  // }, [saveStarted])
  const [reason, setReason] = useState()
  const [loading, setLoading] = useState(false)
  const handleActionButtonsClick = () => {
    let payload = {},
      saveAction = null
    payload.doNotRespond = true
    payload.reason = reason
    saveAction = SaveActionTypes.REMOVE_FROM_QUEUE
    saveReviewAction('DoNotRespond', saveAction, payload)
  }
  const saveReviewAction = async (actionId, saveAction, payload) => {
    saveActionRequest(actionId)
    setLoading(true)
    let service = ReviewServiceFactory.create(RadarServices.Reviews)
    let result = await service.saveReviewAction(review.reviewId, payload)
    if (result.Ok) {
      let msg = 'Review Saved!'
      addNotification(msg, NotificationType.SUCCESS_NOTIFICATION)
      setReason('')
      setDisplayReasonComment(false)
    } else {
      addNotification('Failed to save review!', NotificationType.ERROR_NOTIFICATION)
    }
    setLoading(false)

    saveReviewCompleted(result.Ok ? result.Content.review : review, result.Ok ? saveAction : null)
  }
  const handleCommentKeyUp = e => {
    if (e.keyCode === 13 && reason) handleActionButtonsClick()
  }
  const classes = useStyles()
  return (
    <>
      {displayReasonComment && (
        <CommentView className='d-flex flex-row pt-2 pb-2 justify-content-start'>
          <div className='align-self-center'>
            <CommentChatBubble />
          </div>
          <div className='align-self-center pl-3 pr-3'>
            <Typography variant='subtitle2'>Reason</Typography>
          </div>
          <div className='flex-grow-1 '>
            <div className='d-flex flex-row align-items-center no-gutters'>
              <div className='flex-grow-1 '>
                <CommentTextarea
                  onKeyUp={handleCommentKeyUp}
                  autoFocus={true}
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                />
              </div>
              <div className='pl-3' style={{ position: 'relative' }}>
                <Button
                  disableElevation
                  disabled={!reason}
                  className={classes.fabButton}
                  variant='contained'
                  onClick={() => handleActionButtonsClick()}
                  color='secondary'>
                  {loading ? <Spinner /> : <SendIcon />}
                </Button>
                {/*{saveStarted && <CircularProgress thickness={4} size={47} className={classes.progress} />}*/}
              </div>
            </div>
          </div>
        </CommentView>
      )}
    </>
  )
}

function mapStateToProps(state) {
  return {
    review: state.review.review,
    displayReasonComment: state.review.displayReasonComment,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addNotification: (text, status) => dispatch(addNotificationAction(text, status)),
    saveReviewCompleted: (review, saveAction) => {
      dispatch(reviewSaveReviewCompletedAction(review, saveAction))
    },
    saveActionRequest: actionId => {
      dispatch(reviewSaveActionStartedAction(actionId))
    },
    setDisplayReasonComment: display => {
      dispatch(reviewDisplayReasonCommentAction(display))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseReason)
