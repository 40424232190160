import styled from 'styled-components'

export const ResolutionMap = {
  ApproveProposedResponse: {
    text: 'Approved',
    color: 'success',
    variant: 'main',
    path: 'approve-proposed-response',
    order: '1',
  },
  RejectProposedResponse: {
    text: 'Rejected',
    color: 'error',
    variant: 'main',
    path: 'reject-proposed-response',
    order: '2',
  },
  MarkResponseProposed: {
    text: 'Propose Response',
    color: 'foreground',
    variant: 'lighter',
    path: 'propose-response',
    order: '1',
  },
  Close: {
    text: 'Close',
    color: 'success',
    variant: 'main',
    path: 'close',
    order: '1',
  },
  MarkManuallyPosted: {
    text: 'I Manually Responded',
    color: 'success',
    variant: 'main',
    path: 'manually-post-response',
    order: '1',
  },
  DoNotRespond: {
    text: 'Do Not Respond',
    color: 'error',
    variant: 'light',
    path: 'do-not-respond',
    order: '3',
  },
  MarkPublishedDirectly: {
    text: '[FILL ME IN]',
    color: 'secondary',
    variant: 'main',
    key: 'mark-published-directly',
  },
}

export const ResolutionsButtonContainer = styled.div`
  display: flex;
  max-width: 400px;
  text-align: right;
  justify-content: flex-end;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-end;
`

export const Text = styled.p`
  color: ${props => props.theme.foreground.lightest};
`
