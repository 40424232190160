import React from 'react'
import { connect } from 'react-redux'
import { generatePath } from 'react-router'
import QueueCard from './QueueCard'
import { reviewShowReviewResponseAction } from '../../../store/actions/review'

function ReviewsListItem(props) {
  const { review, history } = props
  const queryUrl = generatePath('/queue/:id', { id: review.reviewId })

  return (
    <ReviewLink history={history} review={review} url={queryUrl}>
      <QueueCard {...props} />
    </ReviewLink>
  )
}

const ReviewLink = ({ history, review, url, children }) => {
  const handleClick = () => {
    history.push(url)
  }

  return (
    <div data-review-id={review.reviewId} onClick={handleClick}>
      {children}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    updatedReview: state.review.review,
    isDefaultTheme: state.user.normalMode,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    reviewDisplayItemSelected: review => {
      dispatch(reviewShowReviewResponseAction(review))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsListItem)
