import React, { useState, useEffect, useContext } from 'react'
import { withStyles, makeStyles } from '@mui/styles'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import HelpIcon from '@mui/icons-material/Help'
import Chip from '@mui/material/Chip'
import CardContent from '@mui/material/CardContent'
import RadarServiceFactory, { RadarServices } from '../../../services'
import ProgressLoader from '../ProgressLoader'
import ToolTip from '../Tooltip'
import { FilterCard, FilterCardHeadar, useCardStyles } from './Card'

const CustomLabel = withStyles(() => ({
  label: {
    fontSize: 13,
  },
}))(FormControlLabel)

const useStyles = makeStyles(theme => ({
  radio: {
    margin: '0px',
    paddingLeft: '5px',
    paddingTop: '5px',
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.darker : '#FFF7F1',
    borderRadius: '5px',
  },
}))

export default function ({ selectRule, selected, onFilterChanged }) {
  const helpText = 'Include clients in your filter or Exclude clients from your filter'

  const [tags, setTags] = useState([])
  const [fetchStarted, setFetchStarted] = useState(false)
  const cardClasses = useCardStyles()
  const classes = useStyles()

  const fetchTags = async () => {
    const service = RadarServiceFactory.create(RadarServices.Tags),
      retval = await service.getTagIds()
    return retval.Ok ? retval.Content : []
  }

  const handleIncudeExcludeChange = ev => {
    onFilterChanged({
      selectRule: ev.target.value,
    })
  }

  const handleTagClick = e => {
    let tag = tags.find(a => a.tagId === e.currentTarget.dataset['tagid'])
    tag.selected = !tag.selected
    onFilterChanged({ filters: tags.filter(a => a.selected).map(a => a) })
  }

  const handleSelectClearAllClick = () => {
    tags.forEach(a => (a.selected = selected.length > 0 ? false : true))
    onFilterChanged({ filters: tags.filter(a => a.selected).map(a => a) })
  }

  useEffect(() => {
    setFetchStarted(true)
    fetchTags().then(results => {
      setTags(
        results.map(tag => ({
          tagId: tag.tagId,
          displayName: tag.displayName,
        }))
      )
      setFetchStarted(false)
    })
  }, [])

  return (
    <FilterCard>
      <FilterCardHeadar
        title='Tags'
        selectAll={selected.length == 0}
        onActionClick={handleSelectClearAllClick}
      />
      <CardContent style={{ margin: '0px', padding: '5px' }}>
        <RadioGroup row value={selectRule} className={classes.radio} onChange={handleIncudeExcludeChange}>
          <CustomLabel value='include' control={<Radio color='primary' />} label='Include' />
          <CustomLabel value='exclude' control={<Radio color='primary' />} label='Exclude' />
          <ToolTip tooltip={helpText} className='pt-1' style={{ marginTop: '5px' }}>
            <HelpIcon color='primary' />
          </ToolTip>
        </RadioGroup>
        {fetchStarted ? (
          <ProgressLoader />
        ) : (
          <div className='d-flex flex-wrap pt-1'>
            {tags.map(tag => {
              tag.selected = selected.findIndex(a => a.tagId === tag.tagId) > -1
              return (
                <Chip
                  data-tagid={tag.tagId}
                  key={tag.tagId}
                  className={cardClasses.chip}
                  size='small'
                  variant={tag.selected ? 'default' : 'outlined'}
                  label={tag.displayName}
                  color='primary'
                  onClick={handleTagClick}
                />
              )
            })}
          </div>
        )}
      </CardContent>
    </FilterCard>
  )
}
