import styled from 'styled-components'

export const Button = styled.div`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  height: 36px;
  background-color: transparent;
  border: 1px solid transparent;
  ${props =>
    !props.disabled &&
    `&:hover {
      background: ${props.theme.secondary.lighter};
      color: #FFF;
      border: 1px solid ${props.theme.secondary.main};
    }`}
`
