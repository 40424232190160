import reduceReducers from 'reduce-reducers'
import { handleAction } from 'redux-actions'
import { ActionTypes } from '../actions/achievement'

const defaultState = {}

const handleSetCountReducer = handleAction(
  ActionTypes.ACHIEVEMENT_SETCOUNT,
  {
    next(state, action) {
      return {
        ...state,
        count: action.payload.count,
      }
    },
  },
  defaultState
)

const initialState = {
  count: 0,
}

export default () => reduceReducers(initialState, handleSetCountReducer)
