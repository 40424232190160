import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { RiErrorWarningLine } from 'react-icons/ri'
import ReactQuill from 'react-quill'
import { makeStyles, useTheme } from '@mui/styles'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import AccountCircle from '@mui/icons-material/AccountCircle'
import FlexContainer from '../../../common/FlexContainer'
import Button from '../../../common/Button'
import ButtonSpinner from '../../../common/ButtonSpinner'
import SimpleModal from '../../../common/SimpleModal'
import { Editor } from './PublishResponse.S'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  icon: {
    color: theme.palette.text.primary,
  },
}))

function PublishModal({ show, updateStarted, review, onCancel, onPublishReview }) {
  const savingButtonStyle = { color: '#FFF', padding: '1px' }
  const spinContent = <div style={savingButtonStyle}>Publishing..</div>
  const quillStyle = {
    toolbar: false,
    clipboard: {
      matchVisual: false,
    },
  }
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(show)
  const [respondent, setRespondent] = useState('')
  const validationlist = ['CarFax', 'Edmunds']

  const handleChange = ev => {
    setRespondent(ev.target.value)
  }

  const handlePublishClick = () => {
    if (validationlist.includes(review.reviewSource)) {
      if (respondent.length > 1) onPublishReview(respondent)
    } else {
      onPublishReview(respondent)
    }
  }

  useEffect(() => {
      setOpen(show)
      if (review.reviewSource === 'Edmunds' || review.reviewSource === 'CarFax') setRespondent(review.client.clientName)
  }, [show])

    return (
    <SimpleModal open={open} title={review.client.clientName}>
      <FlexContainer
        className={theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}
        direction='column'
        alignItems='left'>
        <div className='d-flex flex-column' style={{ fontSize: '13px' }}>
          <span style={{ fontWeight: '600' }}>{review.reviewerName}</span>
          <span className='text-muted'>
            {moment.parseZone(review.reviewDate).format('dddd, MMMM Do YYYY')}
          </span>
          <span className='text-muted'>{review.reviewSite}</span>
        </div>
        <p className='mt-2' style={{ fontWeight: '600', fontSize: '18px', margin: '0px' }}>
          {review.reviewTitle}
        </p>
        <p
          className={theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}
          style={{ fontSize: '16px', maxHeight: '150px', overflowY: 'auto' }}>
          {review.reviewText}
        </p>
        {validationlist.includes(review.reviewSource) && (
          <TextField
            error={respondent.length <= 1}
            className={classes.root}
            label=''
            helperText={
              respondent.length <= 1
                ? 'Enter the name of the respondent (REQUIRED)'
                : 'The name of the responder to the review'
            }
            value={respondent}
            onChange={handleChange}
            inputProps={{ maxLength: 75 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <AccountCircle className={classes.icon} />
                </InputAdornment>
              ),
            }}
          />
        )}
        <Editor>
          <ReactQuill modules={quillStyle} disabled={true} value={review.responseText} />
        </Editor>
      </FlexContainer>
      <FlexContainer className='mt-2'>
        <RiErrorWarningLine size={50} scale={20} color='red' />
        <span style={{ fontSize: '12px', color: 'red' }}>
          {`You are about to publish the above review response to ${review.reviewSite}. This cannot
                    be undone. If the above response looks correct, please confirm by clicking the publish
                    button below.`}
        </span>
      </FlexContainer>
      <FlexContainer className='mt-3' alignItems='right'>
        <Button className='col-md-2' variant='main' color='error' onClick={onCancel}>
          Cancel
        </Button>
        <ButtonSpinner
          disableElevation
          style={{ color: '#FFF' }}
          variant='contained'
          color='secondary'
          spin={updateStarted}
          spinContent={spinContent}
          onClick={handlePublishClick}>
          {`Publish to ${review.reviewSite}`}
        </ButtonSpinner>
      </FlexContainer>
    </SimpleModal>
  )
}

export default PublishModal
