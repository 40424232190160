import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useTheme } from '@mui/styles'
import RadarServiceFactory, { RadarServices } from '../../services'
import ProgressLoader from '../common/ProgressLoader'
import FlexContainer from '../common/FlexContainer'
import Button from '../common/Button'
import NewTemplateEditor from './EmailTemplateComponents/NewTemplateEditor'
import EditableTemplateEditor from './EmailTemplateComponents/EditableTemplateEditor'
import SideLegend from './EmailTemplateComponents/SideLegend'
import { ContentBox, TitleBox, Header, SearchInput, ItemContainer } from './EmailTemplates.S'
import { addNotificationAction, NotificationType } from '../../store/actions/notifications'
import { pageSelectedAction } from '../../store/actions/email-templates'

function EmailTemplates({ page, pageSize, pageSelected, addNotification }) {
  const [searchData, setSearchData] = useState('')
  const [showEditor, setShowEditor] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [fetchStarted, setFetchStarted] = useState(false)
  const [updateStarted, setUpdateStarted] = useState(false)
  const [deletionStarted, setDeletionStarted] = useState(false)
  const [templates, setTemplates] = useState([])
  const containerRef = useRef(null)
  const theme = useTheme()

  const fetchTemplates = async page => {
    if (!fetchStarted) {
      setFetchStarted(true)

      let service = RadarServiceFactory.create(RadarServices.EmailTemplates),
        result = await service.getTemplates(page, pageSize),
        { totalCount, templates } = result.Ok ? result.Content : { templates: [], totalCount: 0 }

      setTemplates(templates)
      setTotalCount(totalCount)
      setFetchStarted(false)
    }
  }

  const handleNewTemplate = () => {
    setShowEditor(!showEditor)
    containerRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const handleNewSave = async template => {
    if (!updateStarted) {
      setUpdateStarted(true)

      let service = RadarServiceFactory.create(RadarServices.EmailTemplates),
        result = await service.createTemplate(template),
        notification = {
          text: result.Ok ? 'Email Template created successfully' : 'Failed to create Email Template',
          type: result.Ok ? NotificationType.SUCCESS_NOTIFICATION : NotificationType.ERROR_NOTIFICATION,
        }

      if (result.Ok) {
        setShowEditor(false)
        setTemplates([...templates, result.Content])
        setTotalCount(totalCount + 1)

        let newpage = Math.ceil((totalCount + 1) / pageSize)
        pageSelected(newpage)
      }

      addNotification(notification)
      setUpdateStarted(false)
    }
  }

  const handleEditSave = async template => {
    if (!updateStarted) {
      setUpdateStarted(true)

      let service = RadarServiceFactory.create(RadarServices.EmailTemplates),
        result = await service.updateTemplate(template),
        notification = {
          text: result.Ok ? 'Email Template updated successfully' : 'Failed to update Email Template',
          type: result.Ok ? NotificationType.SUCCESS_NOTIFICATION : NotificationType.ERROR_NOTIFICATION,
        }
      if (result.Ok) {
        setShowEditor(false)
      }

      addNotification(notification)
      setUpdateStarted(false)
    }
  }

  const handleEditDelete = async template => {
    if (!deletionStarted) {
      setDeletionStarted(true)

      let service = RadarServiceFactory.create(RadarServices.EmailTemplates),
        result = await service.deleteTemplate(template),
        notification = {
          text: result.Ok ? 'Email Template deleted successfully' : 'Failed to delete Email Template',
          type: result.Ok ? NotificationType.SUCCESS_NOTIFICATION : NotificationType.ERROR_NOTIFICATION,
        }

      if (result.Ok) {
        setShowEditor(false)
        setTotalCount(totalCount - 1)

        let filtered = templates.filter(a => a.templateId !== template.templateId)
        setTemplates(filtered)
      }

      addNotification(notification)
      setDeletionStarted(false)
    }
  }

  useEffect(() => {
    fetchTemplates(page)
  }, [page])

  return (
    <FlexContainer
      className={theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}
      padding='15px 15px 0px'
      alignItems='baseline'
      width='100%'
      height='calc(100vh - 120px)'
      addProps='overflow-y: auto'>
      <ContentBox className='ml-5 col-lg-6'>
        <TitleBox className='ml-2'>
          <Header>Email Templates</Header>
          <p>
            Pre-written Email Notifications that can be used to quickly notify clients. Templates become
            available in the Review Workspace by using the 'Notify Client' button.
            <br />
            <b>Note:</b> Only Manager-level users allowed to create and edit Email Templates.
          </p>
        </TitleBox>
        <FlexContainer>
          <SearchInput
            className='col-md-8'
            type='text'
            placeholder='Search Templates'
            value={searchData}
            onChange={e => setSearchData(e.target.value)}
            disabled={showEditor}
          />
          <Button
            className='btn das-primary-bg text-white col-md-3'
            disabled={showEditor}
            type='button'
            onClick={handleNewTemplate}>
            Create New
          </Button>
        </FlexContainer>
        <ItemContainer
          ref={containerRef}
          className={theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}>
          {showEditor && (
            <NewTemplateEditor
              title='Email template'
              onSave={handleNewSave}
              onClose={() => setShowEditor(!showEditor)}
              updateStarted={updateStarted}
            />
          )}
          {fetchStarted && <ProgressLoader />}
          {templates
            .filter(template => {
              if (!searchData) return true
              let criteria = new RegExp(searchData, 'i')
              if (
                criteria.test(template.title) ||
                criteria.test(template.subject) ||
                criteria.test(template.content)
              )
                return true
            })
            .map(template => (
              <EditableTemplateEditor
                template={template}
                key={template.templateId}
                onDelete={handleEditDelete}
                onSave={handleEditSave}
                editing={showEditor}
                updateStarted={updateStarted}
                deletionStarted={deletionStarted}
              />
            ))}
        </ItemContainer>
      </ContentBox>
      <SideLegend className='col-md-2' />
    </FlexContainer>
  )
}

function mapStateToProps(state) {
  return {
    page: state.emailTemplates.page,
    pageSize: state.emailTemplates.pageSize,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    pageSelected: page => dispatch(pageSelectedAction(page)),
    addNotification: notification => dispatch(addNotificationAction(notification.text, notification.type)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates)
