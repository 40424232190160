import { createAction } from 'redux-actions'
import ReviewServiceFactory, { RadarServices } from '../../services'

export const ActionTypes = {
  ACCOUNT_FETCH_STARTED: 'ACCOUNT_FETCH_STARTED',
  ACCOUNT_FETCH_COMPLETED: 'ACCOUNT_FETCH_COMPLETED',
  ACCOUNT_UPDATE_STARTED: 'ACCOUNT_UPDATE_STARTED',
  ACCOUNT_UPDATE_COMPLETED: 'ACCOUNT_UPDATE_COMPLETED',
  ACCOUNT_TAB_SELECTED: 'ACCOUNT_TAB_SELECTED',
  ACCOUNT_CLEAR_NOTIFICATION: 'ACCOUNT_CLEAR_NOTIFICATION',
}

//----------------------------  Actions

export const fetchAccountStartedAction = createAction(ActionTypes.ACCOUNT_FETCH_STARTED)

export const fetchAccountCompletedAction = createAction(ActionTypes.ACCOUNT_FETCH_COMPLETED, async id => {
  let service = ReviewServiceFactory.create(RadarServices.Accounts),
    result = await service.getAccountById(id)
  // TODO: We to do something with errors
  return result.Ok ? result.Content : null
})

export const updateAccountStartedAction = createAction(ActionTypes.ACCOUNT_UPDATE_STARTED)

export const updateAccountCompletedAction = createAction(
  ActionTypes.ACCOUNT_UPDATE_COMPLETED,
  async (clientId, account) => {
    let service = ReviewServiceFactory.create(RadarServices.Accounts)
    let result = await service.updateAccount(clientId, account)
    return { ok: result.Ok, content: account }
  }
)

export const tabSelectedAction = createAction(ActionTypes.ACCOUNT_TAB_SELECTED, tabId => tabId)

export const clearNotificationAction = createAction(ActionTypes.ACCOUNT_CLEAR_NOTIFICATION)
