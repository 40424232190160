import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ReviewsConfirmExport({open,totalReviews,onClose,onConfirm}) {
  const handleClose = () => {
    onClose();
  };
  const handleConfirm = () => {
    onConfirm();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to export all {totalReviews} records?  Please expect a longer wait time to export all.  Otherwise, please narrow your search or select the records you wish to export and try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button autoFocus onClick={handleConfirm}>
            Export
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}