import React, { useRef, useEffect } from 'react'
import RadarSession from '../../../lib/session'

const SessionManager = ({ notifyUserLogout }) => {
  //
  // TODO: Add a div that will show a banner letting the user know they have
  //		 5 minutes left in their session before automatically logging them out
  //
  const session = new RadarSession()
  const timeoutId = useRef(0)

  const checkSession = async () => {
    clearTimeout(timeoutId.current)

    await session.checkSession()
    if (!session.isLoggedIn()) notifyUserLogout()
    else {
      session.renewSession()
      timeoutId.current = setTimeout(checkSession, 1000 * 60 * 5)
    }
  }

  useEffect(() => {
    checkSession()
  }, [])

  return <></>
}

export const Logout = ({ history, notifyUserLogout }) => {
  useEffect(() => {
    notifyUserLogout()
    history.push('/login')
  })
  return <></>
}

export default SessionManager
