import React from 'react'
import GenericFilterCard, { createGenericItem } from './GenericFilterCard'
import RadarServiceFactory, { RadarServices } from '../../../services'

export default function ({ showAll, selected, onFilterChanged }) {
  const fetchReviewStatuses = async () => {
    const service = RadarServiceFactory.create(RadarServices.Reviews),
      retval = await service.getStatuses()
    return retval.Ok
      ? retval.Content.values
          .filter(
            item =>
              showAll ||
              !['New', 'Unspecified', 'Closed', 'Proposed', 'Unknown', 'DoNotRespond'].includes(item.id)
          )
          .map(item => createGenericItem(item.id, item.name))
      : []
  }

  return (
    <GenericFilterCard
      title='Review Status'
      selected={selected}
      onInit={fetchReviewStatuses}
      onFilterChanged={onFilterChanged}
    />
  )
}
