import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { BsChatQuoteFill } from 'react-icons/bs'
import moment from 'moment'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/lab/AvatarGroup'
import Rating from '@mui/lab/Rating'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'
import CheckIcon from '@mui/icons-material/Check'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import SelectAllIcon from '@mui/icons-material/SelectAll'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import FlexContainer from '../../common/FlexContainer'
import Tooltip from '../../common/Tooltip'
import {
  Subheader,
  ReviewAction,
  ReviewActionHeader,
  StatusIndicator,
  StatusKeyword,
  Comment,
  ContentBubble,
  useStyles,
} from './ReviewDetail.S'
import UserGraph from '../../../lib/msgraph'
import generateId from '../../../lib/idgenerator'

function ReviewDetail({ review }) {
  const [userPhotos, setUserPhotos] = useState({})
  const [collapseList, setCollapseList] = useState({})
  const [collapseAll, setCollapseAll] = useState(true)
  const classes = useStyles()
  const systemId = '00000000-0000-0000-0000-000000000000'

  const getPhotos = () => {
    let graph = new UserGraph(),
      promises = review.users
        .filter(rep => rep.userId !== systemId)
        .map(
          rep =>
            new Promise((resolve, reject) => {
              graph.getPhoto(rep.userId).then(
                url => resolve({ id: rep.userId, url }),
                reason => reject({ id: rep.userId, url: null })
              )
            })
        )
    Promise.all(promises).then(values => {
      let photos = {}
      if (values) {
        try {
          values.forEach(photo => (photos[photo.id] = URL.createObjectURL(photo.url)))
        } catch {
          // This fails sometimes but its no reason to halt the page
        }
        setUserPhotos(photos)
      }
    })
  }

  const getNameInitials = fullname => {
    let names = fullname.split(' '),
      firstL = names[0].substring(0, 1).toUpperCase(),
      lastL = names[names.length - 1].substring(0, 1).toUpperCase()
    return <span>{names.length > 1 ? firstL + lastL : firstL}</span>
  }

  const getActionContent = content => {
    switch (content.contentType) {
      case 'Response':
        return <ResponseContent content={content} />

      case 'Comment':
        return <CommentContent content={content} />

      case 'OnlineComment':
        return <OnlineCommentContent content={content} />

      case 'Review':
        return <ReviewContent content={content} />

      default:
        return null
    }
  }

  const generateCollapseList = () => {
    let list = {}
    review.events.forEach(e => {
      e.id = generateId()
      list[e.id] = true
    })
    setCollapseList(list)
  }

  const generateView = event => (
    <Timeline align='left' className={classes.timeline}>
      {event.actionContents.length > 0 ? (
        event.actionContents.map((content, ndx) => (
          <TimelineItem key={ndx} align='left'>
            <TimelineOppositeContent style={{ display: 'none' }} />
            <TimelineSeparator>
              <TimelineConnector className={classes.connector} />
            </TimelineSeparator>
            <TimelineContent className={classes.timelineItem}>{getActionContent(content)}</TimelineContent>
          </TimelineItem>
        ))
      ) : (
        <EmptyTimeLineItem event={event} />
      )}
      <StatusTransition event={event} />
    </Timeline>
  )

  const onEventClick = event => {
    collapseList[event.id] = !collapseList[event.id]
    setCollapseList({ ...collapseList })
  }

  const onCollapseAllClick = () => {
    let direction = !collapseAll
    for (let key in collapseList) collapseList[key] = direction

    setCollapseAll(direction)
    setCollapseList({ ...collapseList })
  }

  const EmptyTimeLineItem = ({ event }) =>
    event.currentStatus != 'Unknown' &&
    event.nextStatus !== 'Unknown' && (
      <TimelineItem align='left' style={{ minHeight: '20px' }}>
        <TimelineOppositeContent style={{ display: 'none' }} />
        <TimelineSeparator>
          <TimelineConnector className={classes.connector} />
        </TimelineSeparator>
      </TimelineItem>
    )

  const ResponseContent = ({ content }) => (
    <ContentBubble>
      <div className='row'>
        <div className='col-auto mr-auto'>
          <Subheader>{moment(content.responseDate).format('dddd, MMMM Do YYYY')}</Subheader>
        </div>
      </div>
      <div className='row'>
        <div className='col-auto pt-2'>
          <Typography variant='body1'>"{content.responseText}"</Typography>
        </div>
      </div>
    </ContentBubble>
  )

  const ReviewContent = ({ content }) => (
    <ContentBubble>
      <div className='row'>
        <div className='col-md-auto '>
          <Typography variant='subtitle2'>{content.reviewerName}</Typography>
          <Subheader>{moment(content.reviewDate).format('dddd, MMMM Do YYYY')}</Subheader>
        </div>
        <div className='col'>
          <div className='d-flex justify-content-end mt-n1'>
            <Rating value={content.reviewRating} precision={0.1} readOnly />
          </div>
          <Subheader className='d-flex justify-content-end text-white'>{content.reviewSite}</Subheader>
        </div>
      </div>
      {content.reviewTitle?.length > 0 && (
        <div className='row'>
          <div className='col-md-auto pt-1'>
            <Typography variant='subtitle1'>{content.reviewTitle}</Typography>
          </div>
        </div>
      )}
      {content.reviewText?.length > 0 && (
        <div className='row'>
          <div className='col-md-auto pt-3'>
            <Typography variant='body1'>"{content.reviewText}"</Typography>
          </div>
        </div>
      )}
    </ContentBubble>
  )

  const CommentContent = ({ content }) => {
    let user = review.users.find(a => a.userId === content.userId)
    return (
      <div className='d-inline-flex'>
        <Tooltip tooltip={user.fullname}>
          <Avatar alt={user.fullname} className={classes.avatar} src={userPhotos[user.userId] || ''}>
            {getNameInitials(user.fullname)}
          </Avatar>
        </Tooltip>
        <Comment>
          <ul>
            <li>
              <strong>{content.userFullname}</strong>
            </li>
            <li>{moment(content.commentDate).format('MMM DD hh:mm A')}</li>
          </ul>
          <br />
          <span>{content.content}</span>
        </Comment>
      </div>
    )
  }

  const OnlineCommentContent = ({ content }) => (
    <ContentBubble>
      <div className='row'>
        <div className='col-md-auto'>
          <Typography variant='h6'>
            Online Comment <BsChatQuoteFill className={classes.onlineComment} />
          </Typography>
          <Typography className='pt-3' variant='subtitle2'>
            {content.name}
          </Typography>
          <Subheader>{moment(content.commentDate).format('dddd, MMMM Do YYYY')}</Subheader>
          <Typography className='pt-3' variant='body1'>
            "{content.content}"
          </Typography>
        </div>
      </div>
    </ContentBubble>
  )

  const StatusTransition = ({ event }) =>
    event.currentStatus != 'Unknown' &&
    event.nextStatus !== 'Unknown' &&
    event.currentStatus !== event.nextStatus && (
      <TimelineItem align='left'>
        <TimelineOppositeContent style={{ display: 'none' }} />
        <TimelineSeparator>
          <TimelineDot className={classes.timelineItemDot} variant='outlined'>
            <CheckIcon />
          </TimelineDot>
          <TimelineConnector className={classes.connector} style={{ marginLeft: '-2px' }} />
        </TimelineSeparator>
        <TimelineContent className={classes.timelineItemStatus}>
          <div className='d-flex flex-row align-items-center'>
            Moved from&nbsp;
            <StatusKeyword status={event.currentStatus.toLowerCase()}>{event.currentStatus}</StatusKeyword>
            &nbsp;to&nbsp;
            <StatusKeyword status={event.nextStatus.toLowerCase()}>{event.nextStatus}</StatusKeyword>
          </div>
        </TimelineContent>
      </TimelineItem>
    )

  const EventContent = ({ event }) => (
    <>
      <ReviewAction onClick={() => onEventClick(event)}>
        <div
          className={
            event.currentStatus === 'Closed' && event.nextStatus === 'Unknown' ? classes.closedEvent : ''
          }>
          <div className='row no-gutters'>
            <div className='col-auto pl-1'>
              <Tooltip tooltip={event.user.fullname}>
                <Avatar className={classes.avatar} src={userPhotos[event.user.userId] || ''}>
                  {getNameInitials(event.user.fullname)}
                </Avatar>
              </Tooltip>
            </div>
            <div className='col-auto pl-2'>
              <ReviewActionHeader>{moment(event.actionDate).format('MMMM Do h:mm a')}</ReviewActionHeader>
              <Typography>
                {event.user.fullname.split(' ')[0]}: {event.action}
              </Typography>
            </div>
            <div className='col '>
              <div className='d-flex justify-content-end'>
                <StatusIndicator status={event.currentStatus.toLowerCase()}>
                  {event.currentStatus}
                </StatusIndicator>
              </div>
            </div>
          </div>
        </div>
      </ReviewAction>
      <Collapse className={classes.collaspe} timeout='auto' in={collapseList[event.id]} unmountOnExit>
        {generateView(event)}
      </Collapse>
    </>
  )

  useEffect(() => {
    getPhotos()
    generateCollapseList()
  }, [review])

  return (
    <FlexContainer direction='column' padding='0 20px 0' alignItems='start'>
      <div className='d-flex justify-content-between w-100' style={{ padding: '10px 0 20px' }}>
        <div className='d-flex'>
          <div className='d-flex align-items-center'>
            <Typography>Reputation Specialists:</Typography>
          </div>
          <div className='d-flex align-items-center pl-2'>
            <AvatarGroup max={4}>
              {review.users
                .filter(rep => rep.userId !== systemId)
                .map(rep => (
                  <Tooltip key={rep.userId} tooltip={rep.fullname}>
                    <Avatar alt={rep.fullname} className={classes.avatar} src={userPhotos[rep.userId] || ''}>
                      {getNameInitials(rep.fullname)}
                    </Avatar>
                  </Tooltip>
                ))}
            </AvatarGroup>
          </div>
        </div>
        <div className='d-flex'>
          <IconButton onClick={onCollapseAllClick}>
            {collapseAll ? <ClearAllIcon color='primary' /> : <SelectAllIcon color='primary' />}
          </IconButton>
        </div>
      </div>
      <div className='row'>
        {review.events.map((event, ndx) => (
          <EventContent key={ndx} event={event} />
        ))}
      </div>
    </FlexContainer>
  )
}

function mapStateToProps(state) {
  return {
    review: state.review.review,
  }
}

export default connect(mapStateToProps)(ReviewDetail)
