import React from 'react'
import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import SelectAllIcon from '@mui/icons-material/SelectAll'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import CardHeader from '@mui/material/CardHeader'

export const useCardStyles = makeStyles(theme => ({
  title: { fontSize: '16px' },
  card: {
    marginTop: theme.spacing(0.5),
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.background.main : theme.palette.background.default,
  },
  cardContent: {
    padding: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.3),
    },
  },
  chip: {
    margin: theme.spacing(0.3),
  },
  clickable: { color: '#FFF !important' },
  avatar: {
    color: '#FFF !important',
  },
}))

export const FilterCard = props => {
  const classes = useCardStyles()
  return (
    <Card className={classes.card} variant='outlined' {...props}>
      {props.children}
    </Card>
  )
}

export const FilterCardHeadar = ({ title, selectAll, onActionClick }) => {
  const styles = useCardStyles()
  return (
    <CardHeader
      classes={{
        title: styles.title,
      }}
      title={title}
      action={
        onActionClick ? (
          <IconButton onClick={onActionClick}>
            {selectAll ? <SelectAllIcon color='primary' /> : <ClearAllIcon color='primary' />}
          </IconButton>
        ) : null
      }
      style={{ margin: '0px', padding: '5px' }}
    />
  )
}
