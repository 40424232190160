import React, { useState, useEffect } from 'react'
import { BsPencil } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'
import { Card, EditorContainer, SavingText, HTMLContentText } from './Shared.S'
import { ContentBox, TitleBox } from '../EmailTemplates.S'
import { useTheme } from '@mui/styles'
import TextField from '@mui/material/TextField'
import FlexContainer from '../../common/FlexContainer'
import Button from '../../common/Button'
import ButtonSpinner from '../../common/ButtonSpinner'
import SimpleModal from '../../common/SimpleModal'
import BodyText from '../../common/BodyText'
import TextEditor from '../../common/TextEditor'
import ValidationModal from '../common/ValidationModal'

function EditableTemplateEditor(props) {
  const { template, onDelete, onSave, editing, updateStarted, deletionStarted } = props
  const [isEditing, setIsEditing] = useState(editing)
  const [emailTemplate, setEmailTemplate] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [showValidationModal, setShowValidationModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const theme = useTheme()

  const showErrorDialog = msg => {
    setErrorMessage(msg)
    setShowValidationModal(true)
  }

  const handleClose = () => setShowValidationModal(false)

  const handleTitleChanged = e => {
    let updated = { ...emailTemplate }
    updated.title = e.target.value
    setEmailTemplate(updated)
  }

  const handleSubjectChanged = e => {
    let updated = { ...emailTemplate }
    updated.subject = e.target.value
    setEmailTemplate(updated)
  }

  const handleBodyChanged = (data, delta, source, editor) => {
    let updated = { ...emailTemplate }
    if (editor.getText().length <= 1) data = ''
    updated.content = data
    setEmailTemplate(updated)
  }

  const handleSave = () => {
    if (emailTemplate.title.length === 0) {
      showErrorDialog('Title is required please fill in this area')
      return
    }

    if (emailTemplate.subject.length === 0) {
      showErrorDialog('Subject is required please fill in this area')
      return
    }

    if (emailTemplate.content.length === 0) {
      showErrorDialog('Content is required please fill in this area')
      return
    }

    onSave(emailTemplate)
  }

  const handleTemplateClose = () => {
    setEmailTemplate({ ...template })
    setIsEditing(false)
  }

  useEffect(() => {
    if (!updateStarted) setIsEditing(false)
  }, [updateStarted])

  useEffect(() => {
    setEmailTemplate(template)
  }, [template])

  return isEditing ? (
    <>
      <EditorContainer>
        <div className='header'>
          <p>
            <span className='bolder'>EDIT</span> Email Template
          </p>
          <FaTimes className='closeButton' onClick={handleTemplateClose}></FaTimes>
        </div>
        <div className='inputs'>
          <TextField
            className='col-md-5'
            placeholder='Response template name'
            value={emailTemplate.title}
            onChange={handleTitleChanged}
            variant='outlined'
            margin='dense'
            required
          />
          &nbsp;
          <TextField
            className='col-md-7'
            placeholder='Response template name'
            value={emailTemplate.subject}
            onChange={handleSubjectChanged}
            variant='outlined'
            margin='dense'
            required
          />
        </div>
        <TextEditor
          placeholder='Draft Response Template Here'
          body={emailTemplate.content}
          onChange={handleBodyChanged}
        />
        <div className='controls pull-right'>
          <ButtonSpinner
            variant='outlined'
            color='primary'
            spin={deletionStarted}
            spinContent={<SavingText>Deleting..</SavingText>}
            onClick={() => setShowModal(true)}>
            Delete Template
          </ButtonSpinner>
          <ButtonSpinner
            style={{ marginLeft: '20px', color: '#FFF' }}
            disableElevation
            variant='contained'
            color='primary'
            spin={updateStarted}
            spinContent={<SavingText>Saving..</SavingText>}
            onClick={handleSave}>
            Save
          </ButtonSpinner>
        </div>
      </EditorContainer>
      <SimpleModal
        title='Are you sure you want to delete?'
        open={showModal}
        onClick={() => setShowModal(false)}>
        <FlexContainer>
          <BodyText>
            This will permanently delete <b>{emailTemplate.title}</b> email template?
          </BodyText>
        </FlexContainer>
        <FlexContainer className='pt-4'>
          <div className='col-md-7'></div>
          <Button className='col-md-2' variant='main' onClick={() => setShowModal(false)}>
            No
          </Button>
          <Button className='col-md-2' color='error' variant='main' onClick={() => onDelete(emailTemplate)}>
            Yes
          </Button>
        </FlexContainer>
      </SimpleModal>
      <ValidationModal show={showValidationModal} message={errorMessage} onClose={handleClose} />
    </>
  ) : (
    <Card noHover maxHeight='300px' maxWidth='1245px' width='97%' padding='12px 15px' margin='10px 5px 15px'>
      <ContentBox>
        <FlexContainer>
          <TitleBox>
            {emailTemplate.title}
            <p>{emailTemplate.subject}</p>
            <BsPencil
              className='iconButton pull-right'
              onClick={e => {
                setIsEditing(!isEditing)
              }}
            />
          </TitleBox>
        </FlexContainer>
        <HTMLContentText
          className={theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}
          dangerouslySetInnerHTML={{ __html: emailTemplate.content }}
        />
      </ContentBox>
    </Card>
  )
}

export default EditableTemplateEditor
