import React from 'react'
import { withStyles } from '@mui/styles'
import Tooltip from '@mui/material/Tooltip'

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 12,
    backgroundColor: '#161615',
  },
  arrow: {
    color: '#161615',
  },
}))(Tooltip)

function ToolTip({ tooltip, position, className, style, children }) {
  return (
    <StyledTooltip title={tooltip} placement={position} className={className} style={style} arrow>
      {children}
    </StyledTooltip>
  )
}

export default ToolTip
