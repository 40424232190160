import React from 'react'
import TitleContainer from './Title.S'

function Title(props) {
  const { color, fontSize, fontWeight, className, children } = props
  return (
    <TitleContainer color={color} fontSize={fontSize} fontWeight={fontWeight} className={className}>
      {children}
    </TitleContainer>
  )
}

export default Title
