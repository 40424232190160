import React from 'react'
import Tooltip from '../../common/Tooltip'
import { Button } from './SecondaryButton.S'

function SecondaryButton({ onClick, toolTip, disabled, children }) {
  const className = 'hand-cursor das-textarea-button das-hover d-flex align-items-center das-textarea-icon'
  const disabledClass = 'das-textarea-button d-flex align-items-center'

  return !disabled ? (
    <Tooltip position='top' tooltip={toolTip}>
      <Button className={className} onClick={onClick}>
        {children}
      </Button>
    </Tooltip>
  ) : (
    <Button className={disabledClass} disabled={true}>
      {children}
    </Button>
  )
}

export default SecondaryButton
