// eslint-disable-next-line no-use-before-define
import React, { useEffect, useContext } from 'react'
import { connect } from 'react-redux'
import { ThemeContext } from 'styled-components'
import QueueNavBar from './QueueNavBar'
import QueueSidebar from './QueueSidebar'
import QueueFilter from './QueueFilter'
import QueueDetail from './QueueDetail'
import { FoldersView } from './QueueWorkspace.S'

import SplitterLayout from 'react-splitter-layout'
import 'react-splitter-layout/lib/index.css'

function QueueWorkspace({ userLoggedIn, history }) {
  const themeContext = useContext(ThemeContext)

  const getSplitterCss = () => {
    let darkMode = ''
    if (themeContext.type === 'dark') darkMode = 'das-splitter-dark'
    return 'das-splitter ' + darkMode
  }

  useEffect(() => {
    if (!userLoggedIn) {
      history.push('/login')
    }
  }, [userLoggedIn])

  return (
    userLoggedIn && (
      <>
        <FoldersView>
          <QueueNavBar />
        </FoldersView>
        <div>
          <SplitterLayout
            customClassName={getSplitterCss()}
            primaryIndex={0}
            secondaryInitialSize={80}
            percentage>
            <div>
              <QueueFilter history={history} />
              <QueueSidebar history={history} />
            </div>
            <QueueDetail />
          </SplitterLayout>
        </div>
      </>
    )
  )
}

function mapStateToProps(state) {
  return {
    userLoggedIn: state.user.loggedIn,
  }
}

export default connect(mapStateToProps, null)(QueueWorkspace)
