import React, { useState, useContext, useRef, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import Tooltip from '../../common/Tooltip'
import * as UI from './EmailButton.S'
import RadarServiceFactory, { RadarServices } from '../../../services'
import { ThemeContext } from 'styled-components'

function EmailButton({ review, onClick }) {
  const [open, setOpen] = useState(false)
  const [notification, setNotification] = useState(null)
  const [cachedNotifications, setCachedNotifications] = useState(null)
  const themeContext = useContext(ThemeContext)
  const selectRef = useRef(null)

  const reactSelectStyles = {
    control: provided => ({ ...provided, minWidth: 240, margin: 8 }),
    menu: () => ({
      boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)',
      border: '1px solid ' + themeContext.background.dark,
    }),
    menuList: () => ({ maxHeight: 150, overflow: 'auto' }),
    option: styles => {
      return {
        ...styles,
        backgroundColor: themeContext.background.main,
        ':hover': {
          backgroundColor: themeContext.background.dark,
        },
      }
    },
  }

  const DropdownContainer = ({ children }) => (
    <UI.Dropdown onClose={handleDropdownClick}>
      <Tooltip tooltip='Choose Email Template from dropdown' position='top'>
        <div className='d-flex'>
          <UI.NotificationItemButton variant='main' color='primary' onClick={handleNotificationModalClick}>
            <span>{notification ? notification.label : 'Email Client'}</span>
          </UI.NotificationItemButton>
          <UI.NotificationItemDropdownButton variant='main' color='primary' onClick={handleDropdownClick}>
            <ChevronDown />
          </UI.NotificationItemDropdownButton>
        </div>
      </Tooltip>
      {open ? <UI.Menu>{children}</UI.Menu> : null}
      {open ? <UI.Blanket onClick={handleDropdownClick} /> : null}
    </UI.Dropdown>
  )

  const SvgContainer = props => (
    <UI.Svg width='24' height='24' viewBox='0 0 24 24' focusable='false' role='presentation' {...props} />
  )

  const DropdownIndicatorContainer = () => (
    <UI.DropdownIndicator>
      <SvgContainer>
        <path
          d='M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'
          fill='currentColor'
          fillRule='evenodd'
        />
      </SvgContainer>
    </UI.DropdownIndicator>
  )

  const ChevronDown = () => (
    <SvgContainer>
      <path
        d='M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </SvgContainer>
  )

  const handleLoadNotifications = (val, callback) => {
    if (cachedNotifications) {
      callback(
        val.length > 1
          ? cachedNotifications.filter(i => i.label.toLowerCase().includes(val.toLowerCase()))
          : cachedNotifications
      )
    } else {
      return new Promise(async resolve => {
        let service = RadarServiceFactory.create(RadarServices.EmailTemplates)
        let result = await service.getTemplateIds()
        if (result.Ok) {
          let templates = result.Content.map(i => ({
            label: i.title,
            value: i.id,
          }))

          setCachedNotifications(templates)
          resolve(cachedNotifications)
        } else {
          resolve([])
        }
      })
    }
  }

  const handleNotificationChange = option => {
    setOpen(!open)
    setNotification(option)
  }

  const handleDropdownClick = () => {
    setOpen(!open)
  }

  const handleNotificationModalClick = () => {
    if (notification != null) {
      onClick(notification)
    }
  }

  useEffect(() => {
    // This is silly to do but the only way to know that workspace has
    // changed to another review so we can reset the text on the dropdown.
    setNotification(null)
  }, [review])

  return (
    <DropdownContainer>
      <AsyncSelect
        ref={selectRef}
        cacheOptions
        autoFocus
        menuIsOpen
        defaultOptions
        styles={reactSelectStyles}
        backspaceRemovesValue={false}
        components={{ DropdownIndicator: DropdownIndicatorContainer, IndicatorSeparator: null }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        placeholder='Search...'
        tabSelectsValue={false}
        loadOptions={handleLoadNotifications}
        onChange={handleNotificationChange}
      />
    </DropdownContainer>
  )
}

export default EmailButton
