import reduceReducers from 'reduce-reducers'
import { handleAction } from 'redux-actions'
import { ActionTypes } from '../actions/tags'

const defaultState = {}

const handlePageReducer = handleAction(
  ActionTypes.TAGS_PAGE,
  {
    next(state, action) {
      return {
        ...state,
        page: action.payload,
      }
    },
  },
  defaultState
)

const initialState = {
  page: 1,
}

export default () => reduceReducers(initialState, handlePageReducer)
