import reduceReducers from 'reduce-reducers'
import { handleAction, combineActions } from 'redux-actions'
import { ActionTypes, reviewDisplayReasonCommentAction } from '../actions/review'

const defaultState = {}

const handleReviewSaveDraftReducer = handleAction(
  ActionTypes.REVIEW_UPDATE_SAVE_DRAFT_STARTED,
  {
    next(state, action) {
      return {
        ...state,
        updateStarted: true,
        saveCompleted: false,
        updateWorkspace: false,
        saveAction: null,
      }
    },
  },
  defaultState
)

const handleSaveActionReducer = handleAction(
  ActionTypes.REVIEW_UPDATE_REVIEW_ACTION_STARTED,
  {
    next(state, action) {
      return {
        ...state,
        actionUpdateStarted: true,
        actionUpdateFor: action.payload.actionFor,
        updateStarted: true,
        saveCompleted: false,
        updateWorkspace: false,
      }
    },
  },
  defaultState
)

const handleSaveReviewCompletedReducer = handleAction(
  ActionTypes.REVIEW_SAVE_REVIEW_COMPLETED,
  {
    next(state, action) {
      return {
        ...state,
        updateStarted: false,
        review: action.payload.review,
        saveAction: action.payload.saveAction,
        saveCompleted: true,
        updateWorkspace: true,
        actionUpdateStarted: false,
      }
    },
  },
  defaultState
)

const handlefetchReviewReducer = handleAction(
  ActionTypes.REVIEW_FETCH_REVIEW,
  {
    next(state) {
      return {
        ...state,
        fetchStarted: true,
      }
    },
  },
  defaultState
)

const handlefetchReviewCompleteReducer = handleAction(
  combineActions(ActionTypes.REVIEW_FETCH_REVIEW_COMPLETED, ActionTypes.REVIEW_FETCH_REVIEW_HISTORY),
  {
    next(state, action) {
      let review = action.payload.review
      if (review?.status === 'New')
        // We will never show reviews with New status because the importer
        // never processes this kind of review. The reason it doesn't is because
        // a clientId for the review was never found in the database. Therefore
        // the review will not have all the data populated correctly and will break
        // all kinds of shit in the review workspace if you try to render it.
        review = null
      return {
        ...state,
        fetchStarted: false,
        review,
      }
    },
  },
  defaultState
)

const handleShowReviewResponseReducer = handleAction(
  ActionTypes.REVIEW_SHOW_REVIEW_RESPONSE,
  {
    next(state, action) {
      return {
        ...state,
        review: action.payload.review,
      }
    },
  },
  defaultState
)

const handleClearDetailViewReducer = handleAction(
  ActionTypes.REVIEW_CLEAR_DETAIL_VIEW,
  {
    next(state, action) {
      return {
        ...state,
        review: null,
      }
    },
  },
  defaultState
)
const handleReviewDisplayReasonCommentReducer = handleAction(
  ActionTypes.DISPLAY_REASON_COMMENT,
  {
    next(state, action) {
      return {
        ...state,
        displayReasonComment: action.payload,
      }
    },
  },
  defaultState
)
const initialState = {
  review: null,
  fetchStarted: false,
  updateStarted: false,
  actionUpdateStarted: false,
  actionUpdateFor: -1,
  updateWorkspace: false,
  saveAction: null,
  displayReasonComment: false,
  reason: null,
}

export default () =>
  reduceReducers(
    initialState,
    handlefetchReviewReducer,
    handlefetchReviewCompleteReducer,
    handleShowReviewResponseReducer,
    handleReviewSaveDraftReducer,
    handleSaveActionReducer,
    handleSaveReviewCompletedReducer,
    handleClearDetailViewReducer,
    handleReviewDisplayReasonCommentReducer
  )
