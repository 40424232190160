import styled from 'styled-components'

export default styled.h3`
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
  line-height: normal;
  font-size: ${props => (props.fontSize ? props.fontSize : '24px')};
  margin: ${props => (props.margin ? props.margin : '0px 8px 0px 0px')};
  text-align: left;
  color: ${props => (props.color ? props.color : props.theme.foreground.main)};
`
