import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

export const IndicatorContainer = styled.div`
  display: flex;
  place-items: end center;
`
export const IndicatorDot = styled.span`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: ${props => (props.enabled ? props.theme.success.main : props.theme.error.main)};
  margin: 14px 4px;
  display: block;
`

export const UpdatingText = styled.div`
  color: #fff;
  padding-left: 15px;
`

export const BodyText = styled.p`
  line-height: 2;
  color: ${props => props.theme.foreground.lighter};
  font-size: 16px;
`

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`

export const SearchIndicator = styled.div`
  display: flex;
  color: #a0a5a5;
  padding-right: 10px;
`

export const MenuItem = styled.div`
  border-bottom: solid 1px ${props => props.theme.primary.lightest};
  padding: 5px;
  span {
    color: ${props => props.theme.foreground.lighter};
    font-size: 14px;
  }
`

export const Tags = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`

export const ReviewResponseDateContainer = styled.div`
  margin-top: 30px;
  padding: 5px;
  height: 120px;
  border-radius: 5px;
  background-color: ${props => props.theme.background.main} /* ${props =>
      props.theme.type === 'dark' ? '#111111' : '#f9f9f9'};*/ > span {
    font-size: 24px;
    color: ${props => props.theme.text.secondary};
  }
`
