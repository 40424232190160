import Service from './service'
import Globals from './global'
import moment from "moment";
const StorageKeys = {
  ExpireTime: 'expiresAt',
  AuthSessionData: 'sessionState',
}
class AccountsService extends Service {
  async getAccounts(query) {
    let retval = {},
      url = Globals.toURL(`/configurations/search`, { ...query })
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
  async getClients(query) {
    let retval = {},
      url = Globals.toURL(`/configurations/searchClients`, { ...query })
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
  async getAccountById(id, query) {
    let retval = {},
      url = Globals.toURL(`/configurations/${id}`, query)
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async updateAccount(clientId, account) {
    let retval = {},
      url = Globals.toURL(`/configurations/${clientId}`)
    try {
      retval = await this.put(url, account)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getSignatures(clientId, page, count) {
    let retval = {},
      url = Globals.toURL(`/configurations/${clientId}/signatures`, { page, count })
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
  async exportToExcelRequest(){
    let retval = {},
      url = Globals.toURL('/configurations/exportToExcelRequest',null)
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
  async exportToExcelResult(fileName){
    this.session = JSON.parse(localStorage.getItem(StorageKeys.AuthSessionData))
    if (this.session && this.session.accessToken) {
      // Sneeking this into the global scope to peform
      // some behind the scene magic
      window.AccessToken = this.session.accessToken
    }
    let retval = {},
      url = Globals.toURL(`/configurations/exportToExcelResult/${fileName}`,null)
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
  exportToExcel(criteria,reviews) {
    return fetch(Globals.toURL('/configurations/exportToExcel', {...criteria}), {
      method: 'POST',
      body: JSON.stringify(reviews),
      headers: {
        'Authorization': `Bearer ${window.AccessToken}`,
        'content-type': 'application/json'
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        if (criteria.tempFileName){
          return true;
        }
        else{
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([blob]),
          );
          const link = document.createElement('a');
          link.href = url;
          const exportDate = moment(new Date()).format('MMDDYYYY')
          link.setAttribute(
            'download',
            `Radar_Accounts_Export_${exportDate}.xlsx`,
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
          return true;
        }
      });
  }
  downloadExcelResult(fileName) {
    return fetch(Globals.toURL(`/configurations/downloadExcelResult/${fileName}`, null), {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${window.AccessToken}`,
        'content-type': 'application/json'
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        const exportDate = moment(new Date()).format('MMDDYYYY')
        link.setAttribute(
          'download',
          `Radar_Accounts_Export_${exportDate}.xlsx`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        return true;
      });
  }
}

const AccountsServiceServiceFactory = function () {}
AccountsServiceServiceFactory.prototype.create = () => new AccountsService()

export default AccountsServiceServiceFactory
