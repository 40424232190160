import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import { useTheme, makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import EditIcon from '@mui/icons-material/Edit'
import HistoryIcon from '@mui/icons-material/History'
import IconButton from '@mui/material/IconButton'
import ReviewResponse from './DetailComponents/ReviewResponse'
import ReviewResponseSidebar from './DetailComponents/ReviewResponseSidebar'
import ResponseComments from './DetailComponents/ResponseComments'
import ProgressPageLoader from '../common/ProgressPageLoader'
import PizzaImage from '../../assets/pizza.png'
import PizzaImageDM from '../../assets/pizza-dm.png'
import SplitterLayout from 'react-splitter-layout'
import 'react-splitter-layout/lib/index.css'
import ReviewDetail from './DetailComponents/ReviewDetail'
import { TimelineSwitch } from './QueueDetail.S'
import {
  fetchReviewAction,
  fetchReviewCompleteAction,
  fetchReviewHistoryCompleteAction,
  reviewDisplayReasonCommentAction,
} from '../../store/actions/review'
import ResponseReason from './DetailComponents/ResponseReason'

const useStyles = makeStyles(theme => ({
  text: {
    height: '76px',
    width: '375.36px',
    opacity: theme.palette.type === 'dark' ? 0.9 : 0.5,
    color: theme.palette.type === 'dark' ? theme.palette.text.main : theme.palette.text.primary,
    fontSize: '26px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '32px',
    textAlign: 'center',
  },
}))

function ReviewsReview({
  fetchStarted,
  fetchReviewById,
  fetchReviewHistory,
  review,
  setDisplayReasonComment,
}) {
  const [reviewId, setReviewId] = useState(0)
  const urlParams = useParams()
  const theme = useTheme()
  const classes = useStyles()
  const location = new URLSearchParams(useLocation().search)

  const handleTimelineClick = () => {
    if (!fetchStarted) {
      if (review.events) fetchReviewById(review.reviewId)
      else fetchReviewHistory(review.reviewId)
    }
  }

  useEffect(() => {
    setDisplayReasonComment(false)
    if (urlParams.id && urlParams.id !== reviewId) {
      setReviewId(urlParams.id)
      if (location.get('view') === 'history') fetchReviewHistory(urlParams.id)
      else fetchReviewById(urlParams.id)
    }
  }, [urlParams.id])

  return (
    <div
      className={`d-flex flex-scroll-container das-splitter-container ${
        theme.palette.type === 'dark' ? 'das-splitter-dark das-scroll-style-dark' : 'das-scroll-style'
      }`}>
      {fetchStarted && <ProgressPageLoader />}
      {review ? (
        <SplitterLayout primaryIndex={0} secondaryInitialSize={25} percentage>
          <div>
            <TimelineSwitch>
              <IconButton size='small' onClick={handleTimelineClick} style={{ color: '#fff', zIndex: 10 }}>
                {review.events ? <EditIcon /> : <HistoryIcon />}
              </IconButton>
            </TimelineSwitch>
            {review.events ? (
              <ReviewDetail />
            ) : (
              <>
                <ReviewResponse />
                <ResponseReason />
                <ResponseComments />
              </>
            )}
          </div>
          <ReviewResponseSidebar />
        </SplitterLayout>
      ) : (
        <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
          <img src={theme.palette.type === 'dark' ? PizzaImageDM : PizzaImage} alt='' />
          <Typography className={`pt-3 ${classes.text} `} variant='h5'>
            Select a review on the left to start responding to reviews.
          </Typography>
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    review: state.review.review,
    fetchStarted: state.review.fetchStarted,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchReviewById: reviewId => {
      dispatch(fetchReviewAction())
      dispatch(fetchReviewCompleteAction(reviewId))
    },
    fetchReviewHistory: reviewId => {
      dispatch(fetchReviewAction())
      dispatch(fetchReviewHistoryCompleteAction(reviewId))
    },
    setDisplayReasonComment: display => {
      dispatch(reviewDisplayReasonCommentAction(display))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsReview)
