import Service from './service'
import Globals from './global'

class SignatureTemplatesService extends Service {
  async getTemplates(page, count, clientId) {
    let retval = {},
      url = Globals.toURL(`/templates/signatures`, { page, count, clientId })
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
}

const SignatureTemplatesServiceFactory = function () {}
SignatureTemplatesServiceFactory.prototype.create = () => new SignatureTemplatesService()

export default SignatureTemplatesServiceFactory
