import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Switch from '@mui/material/Switch'
import FlexContainer from '../../common/FlexContainer'
import CheckBox from '../../common/CheckBox'
import Input from '../../common/Input'
import ButtonSpinner from '../../common/ButtonSpinner'
import { ProposalsSection } from './Responses.S'
import { updateAccountStartedAction, updateAccountCompletedAction } from '../../../store/actions/account'
import { TitleBox, Header, Body, ContentBox, SavingText } from './Shared.S'

function Responses(props) {
  const { account, updateStarted, saveAccount, updatedAccount } = props

  const [slaResponseTime, setSlaResponsTime] = useState(account.slaResponseTimeInHours)
  const [enableDisputeReminder, setEnableDisputeReminder] = useState(account.enableDisputeReminder)
  const [enableAutoPost, setEnableAutoPost] = useState(account.enableAutoPost)
  const [enableProposals, setEnableProposals] = useState(account.enableProposals)
  const [sentiments, setSentiments] = useState(account.clientApprovalSentiments || [])
  const [clientApprovalWindow, setClientApprovalWindow] = useState(account.clientApprovalWindow)

  const handleCheckClick = (e, checked) => {
    let updated = null
    if (checked) updated = [...sentiments.flat(), e.id]
    else updated = sentiments.filter(a => a !== e.id)
    setSentiments(updated)
  }

  const handleSaveClick = () => {
    if (!updateStarted) {
      let update = {}

      if (sentiments.length === account.clientApprovalSentiments?.length) {
        for (let i = 0; i < sentiments.length; i++) {
          if (!account.clientApprovalSentiments.includes(sentiments[i])) {
            update.clientApprovalSentiments = sentiments
            break
          }
        }
      } else {
        update.clientApprovalSentiments = sentiments
      }

      let slaTime = parseInt(slaResponseTime)
      if (slaTime !== parseInt(account.slaResponseTimeInHours)) update.slaResponseTimeInHours = slaTime

      if (enableDisputeReminder !== account.enableDisputeReminder)
        update.enableDisputeReminder = enableDisputeReminder

      if (enableAutoPost !== account.enableAutoPost)
        update.enableAutoPost = enableAutoPost

      if (enableProposals !== account.enableProposals) update.enableProposals = enableProposals

      let window = parseInt(clientApprovalWindow)
      if (window !== parseInt(account.clientApprovalWindow)) update.clientApprovalWindow = window

      saveAccount(account.clientId, update)
    }
  }

  return (
    <FlexContainer justifyContent='start' direction='column' width='60%'>
      <ContentBox>
        <TitleBox>
          <Header>Responses</Header>
          <Body>Configure settings for responding to reviews.</Body>
        </TitleBox>
      </ContentBox>
      <ContentBox className='pt-4 bold'>
        <TitleBox>
          <Header fontWeight='500'>Service Level Agreement (SLA)</Header>
          <Body>
            The time frame in which reviews must be responded to, proposed, disputed, or reached a resolution
            of done.
          </Body>
        </TitleBox>
      </ContentBox>
      <ContentBox className='pt-3'>
        <Input
          required
          center
          inputType='number'
          min='0'
          fontSize='18px'
          width='61px'
          height='41px'
          padding='10px 0px 8px 10px'
          value={slaResponseTime}
          onChange={e => setSlaResponsTime(e.target.value)}
        />
        <Body flex='.75 0 0' margin='12px 10px 0px 10px' color='foreground' fontSize='17px'>
          Hours
        </Body>
      </ContentBox>
      <ContentBox className='pt-5'>
        <TitleBox>
          <Header fontWeight='500'>Disputed Reviews</Header>
          <Body>
            Selecting this option will make the 'Dispute' reminder appear on reviews that belong to this
            client.
          </Body>
        </TitleBox>
      </ContentBox>
      <ContentBox className='pt-5'>
        <TitleBox>
          <Header fontWeight='500'>Auto Post</Header>
          <Body>
            Auto-post the review responses.
          </Body>
        </TitleBox>
      </ContentBox>
      <ContentBox className='pt-2'>
        <Switch
          color='primary'
          checked={enableAutoPost}
          onChange={e => setEnableAutoPost(e.target.checked)}
        />
        <div className='header pl-2 pt-2'>Enable Auto Post</div>
      </ContentBox>
      <ContentBox className='pt-5'>
        <TitleBox>
          <Header fontWeight='500'>Proposals</Header>
          <Body>
            Selecting this option if a dealership wants to approve responses before they are posted to the
            review site.
          </Body>
        </TitleBox>
      </ContentBox>
      <ContentBox className='pt-2'>
        <Switch
          color='primary'
          checked={enableProposals}
          onChange={e => setEnableProposals(e.target.checked)}
        />
        <div className='header pl-2 pt-2'>Enable Proposals</div>
      </ContentBox>
      <ContentBox className='pt-5'>
        <ProposalsSection requireClientApproval={enableProposals}>
          <Header fontWeight='500'>Sentiments Enabled</Header>
          <Body>
            Select the following sentiments for which 'Proposals' is enabled. You can only select from the
            sentiments \ that have already been configured in the Sentiment settings.
          </Body>

          <div className='d-flex pt-2'>
            <CheckBox id='Positive' checked={sentiments.includes('Positive')} onChanged={handleCheckClick} />
            <div className='header pl-2' style={{ paddingRight: '20px' }}>
              Positive
            </div>
            <CheckBox id='Neutral' checked={sentiments.includes('Neutral')} onChanged={handleCheckClick} />
            <div className='header pl-2' style={{ paddingRight: '20px' }}>
              Neutral
            </div>
            <CheckBox id='Negative' checked={sentiments.includes('Negative')} onChanged={handleCheckClick} />
            <div className='header pl-2'>Negative</div>
          </div>

          <Header fontWeight='500' className='pt-5'>
            Client Response Time
          </Header>
          <Body>
            The amount of time the client has to approve responses before they are automatically posted to the
            review site. Note: if an escalated proposal, the system will automatically assign a value of 2
            days, or 48 hours.
          </Body>

          <div className='d-flex pt-2' style={{ paddingBottom: '20px' }}>
            <Input
              required
              center
              inputType='number'
              min='1'
              fontSize='18px'
              width='61px'
              height='41px'
              padding='10px 0px 8px 10px'
              value={clientApprovalWindow}
              onChange={e => setClientApprovalWindow(e.target.value)}
            />
            <Body flex='.75 0 0' margin='12px 10px 0px 10px' color='foreground' fontSize='17px'>
              Hours
            </Body>
          </div>
        </ProposalsSection>
      </ContentBox>
      <ContentBox className='pt-5'>
        <ButtonSpinner
          disableElevation
          style={{ color: '#FFF' }}
          variant='contained'
          color='primary'
          spin={updateStarted}
          spinContent={<SavingText>Saving..</SavingText>}
          onClick={handleSaveClick}>
          Save
        </ButtonSpinner>
      </ContentBox>
    </FlexContainer>
  )
}

function mapStateToProps(state) {
  return {
    account: state.account.account,
    updateStarted: state.account.updateStarted,
    updatedAccount: state.account.updatedAccount,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveAccount: (clientId, account) => {
      dispatch(updateAccountStartedAction())
      dispatch(updateAccountCompletedAction(clientId, account))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Responses)
