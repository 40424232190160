import reduceReducers from 'reduce-reducers'
import { handleAction } from 'redux-actions'
import { ActionTypes } from '../actions/comments'

const defaultState = {}

const handleSaveStartedReducer = handleAction(
  ActionTypes.COMMENT_SAVE_STARTED,
  {
    next(state, action) {
      return {
        ...state,
        saveStarted: true,
        newComment: null,
        saveCompleted: false,
      }
    },
  },
  defaultState
)

const handleSaveCompletedReducer = handleAction(
  ActionTypes.COMMENT_SAVE_COMPLETED,
  {
    next(state, action) {
      return {
        ...state,
        saveStarted: false,
        saveCompleted: true,
        newComment: action.payload.comment,
      }
    },
  },
  defaultState
)

const handleClearStateReducer = handleAction(
  ActionTypes.COMMENT_CLEAR_STATE,
  {
    next(state, action) {
      return {
        ...state,
        newComment: null,
        saveCompleted: false,
        saveStarted: false,
      }
    },
  },
  defaultState
)

const initialState = {
  saveStarted: false,
  saveCompleted: false,
  newComment: null,
}

export default () =>
  reduceReducers(initialState, handleSaveStartedReducer, handleSaveCompletedReducer, handleClearStateReducer)
