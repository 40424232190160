import styled, { keyframes } from 'styled-components'

const xboxgreen = '#107C10'

const achievementLoaderAnimationFrames = keyframes`
    0% {
        transform: scaleX(0.00) scaleY(0.00);
    }

    10% {
        transform: scaleX(1.00) scaleY(1.00);
    }

    90% {
        transform: scaleX(1.00) scaleY(1.00);
    }

    100% {
        transform: scaleX(0.00) scaleY(0.00);
    }
`

const xboxLogoAnimationFrames = keyframes`
    0% {
        background-color: transparent !important;
        transform: scaleX(0.00) scaleY(0.00);
    }

    5% {
        background-color: transparent !important;
        transform: scaleX(1.20) scaleY(1.20);
    }

    10% {
        background-color: transparent !important;
        transform: scaleX(1.00) scaleY(1.00);
    }

    90% {
        background-color: transparent !important;
        transform: scaleX(1.00) scaleY(1.00);
    }

    95% {
        background-color: transparent !important;
        transform: scaleX(1.20) scaleY(1.20);
    }

    100% {
        background-color: transparent !important;
        transform: scaleX(0.00) scaleY(0.00);
    }
`

const achievementTextAnimationFrames = keyframes`
    0% {
        opacity: 0;
        top: 5px;
    }

    40% {
        opacity: 1;
    }

    55% {
        opacity: 1;
        top: 0px;
        right: 0;
    }

    100% {
        opacity: 0;
        top: -138px;
    }
`

const achievementNameAnimationFrames = keyframes`
    0% {
        opacity: 0;
        top: 75px;
    }

    20% {
        opacity: 1;
        top: 9px;
    }

    90% {
        opacity: 1;
        top: 9px;
        right: 0;
    }

    100% {
        opacity: 0;
        top: 9px;
    }
`

const mainAnimationFrames = keyframes`
    0% {
        // transform: scaleX(0.00) scaleY(0.00);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }

    10% {
        background-color: ${xboxgreen};
        // transform: scaleX(0.00) scaleY(0.00);
    }

    15% {
        // transform: scaleX(1.00) scaleY(1.00);
        width: 75px;
        height: 75px;
        left: calc(50% - 50px);
    }

    20% {
        width: 75px;
        height: 75px;
        left: calc(50% - 50px);
    }

    30% {
        width: 500px;
        left: calc(50% - 300px);
    }

    80% {
        width: 500px;
        left: calc(50% - 300px);
    }

    90% {
        width: 75px;
        height: 75px;
        left: calc(50% - 50px);
        background-color: ${xboxgreen};
    }

    95% {
        // transform: scaleX(1.00) scaleY(1.00);
        width: 75px;
        height: 75px;
        left: calc(50% - 50px);
        background-color: transparent;
    }

    100% {
        // transform: scaleX(0.00) scaleY(0.00);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.0);
    }
`

export const Container = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};

  .achievement-banner {
    position: absolute;
    bottom: 10px;
    // bottom: 50%;
    left: calc(50% - 50px);
    width: 75px;
    height: 75px;
    border-radius: 50px;
    background-color: transparent;
    overflow: hidden;
    z-index: 1000;

    &.animated {
      animation: ${mainAnimationFrames} linear 7.5s;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      transform-origin: 50% 50%;
      animation-fill-mode: forwards; /*when the spec is finished*/
    }

    &-big {
      position: absolute;
      bottom: 10px;
      // bottom: 50%;
      left: calc(50% - 50px);
      width: 75px;
      height: 75px;
      border-radius: 50px;
      background-color: transparent;
      overflow: hidden;
      width: 400px;
      left: calc(50% - 300px);
      background-color: ${xboxgreen};
    }

    .achievement-loader {
      width: 75px;
      height: 75px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50px;
      background-color: lighten(${xboxgreen}, 5%);

      animation: ${achievementLoaderAnimationFrames} ease 6s;
      animation-iteration-count: 1;
      transform: scaleX(0) scaleY(0);
      transform-origin: 50% 50%;
      animation-fill-mode: forwards; /*when the spec is finished*/

      &:nth-of-type(1) {
        background-color: lighten(${xboxgreen}, 5%);
        animation-duration: 10s;
      }

      &:nth-of-type(2) {
        background-color: ${xboxgreen};
        animation-delay: 0.25s;
        animation-duration: 9.5s;
      }

      &:nth-of-type(3) {
        background-color: darken(${xboxgreen}, 5%);
        animation-delay: 0.5s;
        animation-duration: 9s;
      }

      &:nth-of-type(4) {
        background-color: ${xboxgreen};
        animation-delay: 0.75s;
        animation-duration: 8.5s;
      }

      &:nth-of-type(5) {
        background-color: lighten(${xboxgreen}, 5%);
        animation-delay: 1s;
        animation-duration: 8s;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      }
    }

    .achievement-trophy {
      width: 75px;
      height: 75px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50px;
      animation-iteration-count: 1;
      transform: scaleX(0) scaleY(0);
      transform-origin: 50% 50%;
      animation-fill-mode: forwards; /*when the spec is finished*/
      animation: ${xboxLogoAnimationFrames} ease-out 7.5s;
      animation-delay: 1s;
      color: #fff;
      background-color: transparent !important;

      > svg {
        margin-left: 15px;
        margin-top: 17px;
      }

      &:before {
        font-size: 3em;
        top: 32px;
        left: 20px;
        position: absolute;
      }
    }

    .achievement-text {
      font-size: 2em;
      position: absolute;
      width: (400px - 125px);
      top: calc((100px - 50%) - 1.6em);
      left: 105px;
      text-align: center;
      white-space: pre-wrap;
      word-wrap: break-word;
      color: #fff;

      .achievement-notification {
        position: relative;
        width: 100%;
        animation: ${achievementTextAnimationFrames} linear 2s;
        animation-delay: 2s;
        top: 5px !important;
        opacity: 0;
      }

      .achievement-name {
        // display: none;
        position: absolute;
        width: 100%;
        // width: (600px - 65px);
        // text-align: left;
        font-size: 0.7em;
        top: 75px;

        animation: ${achievementNameAnimationFrames} linear 4s;
        animation-delay: 3s;
        opacity: 0;

        .xbox-gamerscore {
          position: absolute;
          top: 0px;
          left: -25px;
          height: 32px;
          width: 32px;
        }
      }
    }
  }
`
