import { createAction } from 'redux-actions'

export const ActionTypes = {
  RESPONSE_TEMPLATE_PAGE_SELECTED: 'RESPONSE_TEMPLATE_PAGE_SELECTED',
}

//----------------------------  Response Teimplates Actions

export const pageSelectedAction = createAction(ActionTypes.RESPONSE_TEMPLATE_PAGE_SELECTED, page => ({
  page,
}))
