import React from 'react'
import GenericFilterCard, { createGenericItem } from './GenericFilterCard'
import RadarServiceFactory, { RadarServices } from '../../../services'

export default function ({ selected, onFilterChanged }) {
  const fetchReviewSites = async () => {
    const service = RadarServiceFactory.create(RadarServices.ReviewSites),
      retval = await service.getReviewSites()
    return retval.Ok ? retval.Content.map(item => createGenericItem(item.reviewSiteId, item.displayName)) : []
  }

  return (
    <GenericFilterCard
      title='Review Sites'
      selected={selected}
      onInit={fetchReviewSites}
      onFilterChanged={onFilterChanged}
    />
  )
}
