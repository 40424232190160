import styled, { keyframes } from 'styled-components'

const mnmlSpinner = keyframes`
    from {
      transform: rotate(0)
    }

    to {
      transform: rotate(359.9deg)
    }
`
export const Spinner = styled.div`
  display: flex;
  border-radius: 100%;
  border-color: rgba(255, 255, 255, 0.2);
  border-top-color: ${props => props.theme.background.main};
  border-style: solid;
  border-width: ${props => (props.borderWidth ? props.borderWidth : '2px')};
  height: ${props => (props.height ? props.height : '16px')};
  width: ${props => (props.width ? props.width : '16px')};
  animation: ${mnmlSpinner} 1s infinite linear;
  margin: ${props => (props.margin ? props.margin : '0 8px')};
`
