const RADAR_REDUX_STATE = 'radar_redux_state'

export default class RadarRedux {
  reduxState

  constructor() {
    let json = localStorage.getItem(RADAR_REDUX_STATE)
    if (json) this.reduxState = JSON.parse(json)
  }

  get state() {
    return this.reduxState ?? {}
  }

  store(state) {
    this.reduxState = state
    let json = this.reduxState ? JSON.stringify(this.reduxState) : ''
    localStorage.setItem(RADAR_REDUX_STATE, json)
  }
}
