import React from 'react'
import { connect } from 'react-redux'
import { generatePath } from 'react-router'
import { reviewsFiltersSelectedAction, clearFilteredReviewsAction } from '../../store/actions/reviews'
import { reviewClearDetailViewAction } from '../../store/actions/review'
import ReviewSearchFilter from '../common/ReviewFilter'

function QueueFilter({
  history,
  showFilter,
  pageSize,
  fetchFilteredReviews,
  clearFilteredReviews,
  cachedFilters,
}) {
    const appliedClearAll = ({ statuses, tags, clients, sentiments, miscellaneous, sites, postedDate }) =>
    statuses.filters.length == 0 &&
    tags.filters.length == 0 &&
    clients.filters.length == 0 &&
    sentiments.filters.length == 0 &&
        miscellaneous.filters.length == 0 &&
    sites.filters.length == 0 &&
    postedDate.startDate == null &&
    postedDate.endDate == null

  const handleApplyClick = filters => {
      let { statuses, tags, clients, sentiments, sites, postedDate, miscellaneous } = filters
    let page = 1,
      fetchQuery = {
        escalated: false,
        disputed: false,
        expiredProposals: false,
        orderBy: 'reviewDate',
        orderByDescending: true,
        statuses:
          statuses.filters.length > 0
            ? statuses.filters.map(t => t.id)
            : ['Undrafted', 'InDraft', 'Posted', 'RemovedAtReviewSite', 'NotRemovedAtReviewSite', 'Ready'],
        tags: tags.filters.length > 0 ? tags.filters.map(t => t.tagId) : [],
        excludeTags: tags.exclude,
        clients: clients.filters.length > 0 ? clients.filters.map(t => t.id) : [],
        sentiments: sentiments.filters.length > 0 ? sentiments.filters.map(t => t.id) : [],
        reviewSites: sites.filters.length > 0 ? sites.filters.map(t => t.id) : [],
          miscellaneous: miscellaneous.filters.length > 0 ? miscellaneous.filters.map(t => t.id) : [],
      }

    if (postedDate.startDate) fetchQuery.startDate = postedDate.startDate

    if (postedDate.endDate) fetchQuery.endDate = postedDate.endDate

    // If all of the sections have no selections then just clear the queue.
    if (appliedClearAll(filters)) {
      clearFilteredReviews(filters)
      history.push(generatePath('/queue'))
    } else {
      fetchFilteredReviews(filters, page, pageSize, fetchQuery)
    }
  }

  return (
    <ReviewSearchFilter
          cards={['tags', 'clients', 'postedDate', 'sentiments', 'statuses', 'sites', 'miscellaneous']}
      selectedFilters={cachedFilters}
      show={showFilter}
      onApply={handleApplyClick}
    />
  )
}

function mapStateToProps(state) {
    let cachedFilters = state.reviews.filters;
    if (!cachedFilters.miscellaneous) {
        cachedFilters.miscellaneous = { filters: [] };
    }
  return {
    pageSize: state.reviews.pageSize,
    showFilter: state.reviews.showFilter,
    cachedFilters: state.reviews.filters,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearFilteredReviews: filters => {
      dispatch(clearFilteredReviewsAction(filters))
      dispatch(reviewClearDetailViewAction())
    },
    fetchFilteredReviews: (filters, page, pageSize, query) => {
      dispatch(reviewsFiltersSelectedAction(filters, query, page, pageSize))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QueueFilter)
