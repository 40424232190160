import styled from 'styled-components'

export const Editor = styled.div`
  margin-top: 10px;
  .ql-container {
    font-size: 16px !important;
    border: 0;
    height: 250px;
    background-color: ${props => props.theme.background.dark};
    color: #6c757d;
  }
`
