import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@mui/styles'
import Button from '@mui/material/Button'
import ClientsFilterCard from './ClientsFilterCard'
import DatesFilterCard from './DatesFilterCard'
import TagsFilterCard from './TagsFilterCard'
import ReviewSitesCard from './ReviewSitesCard'
import ReviewStatus from './ReviewStatusCard'
import GenericFilterCard, { createGenericItem } from './GenericFilterCard'

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
  },
}))

export default function ({ selectedFilters, cards, show, onClear, onApply }) {
  const [selectedTagFilters, setSelectedTagFilters] = useState({ exclude: false, filters: [] })
  const [selectedClientFilters, setSelectedClientFilters] = useState({ filters: [] })
  const [selectedPostedDates, setSelectedPostedDates] = useState({ startDate: null, endDate: null })
  const [selectedSentiments, setSelectedSentiments] = useState({ filters: [] })
  const [selectedMiscellaneous, setSelectedMiscellaneous] = useState({ filters: [] })
  const [selectedSites, setSelectedSites] = useState({ filters: [] })
  const [selectedStatuses, setSelectedStatuses] = useState({ filters: [] })
  const classes = useStyles()
  const theme = useTheme()

  const filterCards = {
    tags: () => (
      <TagsFilterCard
        key='tags'
        selectRule={selectedTagFilters.exclude ? 'exclude' : 'include'}
        selected={selectedTagFilters.filters}
        onFilterChanged={handleTagsFilterChange}
      />
    ),
    clients: () => (
      <ClientsFilterCard
        key='clients'
        selected={selectedClientFilters.filters}
        onFilterChanged={handleClientsFilterChange}
      />
    ),
    postedDate: () => (
      <DatesFilterCard
        key='postedDate'
        title='Posted Date'
        selected={selectedPostedDates}
        onFilterChanged={handlePostedDateFilterChange}
      />
    ),
    sentiments: () => (
      <GenericFilterCard
        key='sentiments'
        title='Review Sentiments'
        selected={selectedSentiments.filters}
        onInit={() => ['Positive', 'Neutral', 'Negative'].map(item => createGenericItem(item, item))}
        onFilterChanged={handleSentimentsFilterChange}
      />
    ),
    sites: () => (
      <ReviewSitesCard
        key='reviewSites'
        selected={selectedSites.filters}
        onFilterChanged={handleReviewSitesFilterChange}
      />
    ),
    statuses: props => (
      <ReviewStatus
        key='reviewStatus'
        selected={selectedStatuses.filters}
        onFilterChanged={handleReviewStatusFilterChange}
        {...props}
      />
      ),
      miscellaneous: () => (
          <GenericFilterCard
              key='miscellaneous'
              title='Miscellaneous'
              selected={selectedMiscellaneous.filters}
              onInit={() => ['DirectPost', 'HasResponse'].map(item => createGenericItem(item, item))}
              onFilterChanged={handleMiscellaneousFilterChange}
          />
      ),
  }

  const handleTagsFilterChange = changes => {
    setSelectedTagFilters({
      exclude: changes.selectRule ? changes.selectRule === 'exclude' : selectedTagFilters.exclude,
      filters: changes.filters || selectedTagFilters.filters,
    })
  }

  const handleClientsFilterChange = changes => setSelectedClientFilters({ filters: changes.filters })

  const handleSentimentsFilterChange = changes =>
      setSelectedSentiments({ filters: changes.filters }
        )

  const handleMiscellaneousFilterChange = changes =>
      setSelectedMiscellaneous({ filters: changes.filters }
      )

  const handleReviewSitesFilterChange = changes => setSelectedSites({ filters: changes.filters })

  const handleReviewStatusFilterChange = changes => setSelectedStatuses({ filters: changes.filters })

  const handlePostedDateFilterChange = changes =>
      setSelectedPostedDates({ startDate: changes.startDate, endDate: changes.endDate, startDateActual: changes.startDateActual, endDateActual: changes.endDateActual})

  const handleApplyClick = () => {
    if (onApply)
      onApply({
        clients: selectedClientFilters,
        tags: selectedTagFilters,
        postedDate: selectedPostedDates,
        sentiments: selectedSentiments,
        miscellaneous: selectedMiscellaneous,
        sites: selectedSites,
        statuses: selectedStatuses,
      })
  }

  const handleClearAllClick = () => {
    setSelectedClientFilters({ filters: [] })
    setSelectedSentiments({ filters: [] })
    setSelectedMiscellaneous({ filters: [] })
    setSelectedSites({ filters: [] })
    setSelectedStatuses({ filters: [] })
    setSelectedPostedDates({ startDate: null, endDate: null })
    setSelectedTagFilters({ exclude: false, filters: [] })
    if (onClear)
      onClear({
        clients: selectedClientFilters,
        tags: selectedTagFilters,
        postedDate: selectedPostedDates,
        sentiments: selectedSentiments,
        miscellaneous: selectedMiscellaneous,
        sites: selectedSites,
        statuses: selectedStatuses,
      })
  }

  useEffect(() => {
    if (selectedFilters) {
      setSelectedClientFilters(selectedFilters.clients)
      setSelectedSentiments(selectedFilters.sentiments)
      setSelectedMiscellaneous(selectedFilters.miscellaneous)
      setSelectedSites(selectedFilters.sites)
      setSelectedStatuses(selectedFilters.statuses)
      setSelectedPostedDates(selectedFilters.postedDate)
      setSelectedTagFilters(selectedFilters.tags)
    }
  }, [selectedFilters])

  return (
    <div className='flex-scroll-container flex-column' hidden={!show}>
      <div
              className={`overflow-auto flex-grow-1 ${theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'
        }`}
        style={{ padding: '3px' }}>
        {cards?.map(a =>
          typeof a === 'string' ? filterCards[a]?.() : filterCards[Object.keys(a)[0]]?.(a[Object.keys(a)[0]])
        )}
      </div>
      <div className='row no-gutters p-0'>
        <div className='col-md-6 col-sm-12 pl-3 pr-3 pt-2 pb-1'>
          <Button
            disableElevation
            className={classes.button}
            variant='contained'
            style={{
              backgroundColor: '#e0e0e0',
            }}
            onClick={handleClearAllClick}>
            Clear All
          </Button>
        </div>
        <div className='col-md-6 col-sm-12 pl-3 pr-3 pt-2 pb-1'>
          <Button
            disableElevation
            className={classes.button}
            style={{
              color: theme.palette.type == 'dark' ? theme.palette.text.primary : theme.palette.common.white,
            }}
            variant='contained'
            color='primary'
            onClick={handleApplyClick}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  )
}
