import React, { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { CommentTextarea, useStyles } from './Comments.S'

function Comments({ saveStarted, saveSucceeded, onSaveComment }) {
  const [newComment, setNewComment] = useState('')
  const [disabled, setDisabled] = useState(true)
  const classes = useStyles()

  const handleCommentKeyUp = e => {
    if (e.keyCode === 13 && newComment.length > 0) onSaveComment({ content: newComment })
    setDisabled(newComment.length === 0)
  }

  const handleSaveClick = () => {
    if (newComment.length > 0) onSaveComment({ content: newComment })
  }

  useEffect(() => {
    if (saveSucceeded) setNewComment('')
  }, [saveSucceeded])

  return (
    <>
      <div className='d-flex flex-row align-items-center no-gutters'>
        <div className='flex-grow-1 '>
          <CommentTextarea
            value={newComment}
            onKeyUp={handleCommentKeyUp}
            onChange={e => setNewComment(e.target.value)}
          />
        </div>
        <div className='pl-3' style={{ position: 'relative' }}>
          <Button
            disableElevation
            disabled={disabled}
            className={classes.fabButton}
            variant='contained'
            color='secondary'
            onClick={handleSaveClick}>
            <AddIcon />
          </Button>
          {saveStarted && <CircularProgress thickness={4} size={47} className={classes.progress} />}
        </div>
      </div>
    </>
  )
}

export default Comments
