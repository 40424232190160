import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FiFilter } from 'react-icons/fi'
import Typography from '@mui/material/Typography'
import { SaveActionTypes } from '../../store/actions/review'
import { FilterItem, FolderItem } from './QueueNavBar.S'
import {
  navbarFiltersSelectedAction,
  navbarFolderSelectedAction,
  navbarFoldersLoadAction,
  navbarFoldersLoadActionCompletedAction,
  ActionIDs,
} from '../../store/actions/reviews'
import AchievementIndicator from '../common/AchievementIndicator'

function QueueNavBar(props) {
  const {
    fetchedfolders,
    pageSize,
    filterActive,
    toggleFilter,
    activeFolderId,
    fetchFolderReviews,
    review,
    updateWorkspace,
    filterQuery,
    loadFolders,
    clearFilter,
  } = props

  const [folders, setFolders] = useState([])
  const [folderNdx, setFolderNdx] = useState(0)

  const incrementFolder = actionId => {
    let folder = folders.find(i => i.actionId === actionId)
    if (folder) folder.count++
  }

  const decrementFolder = actionId => {
    let folder = folders.find(i => i.actionId === actionId)
    if (folder && folder.count > 0) folder.count--
  }

  const updateFolders = review => {
    if (folders.length > 0) {
      switch (review.saveAction) {
        case SaveActionTypes.ADD_TO_PROPOSED:
          if (!review.review.disputeProposed) {
            incrementFolder(ActionIDs.PROPOSED)
          }
          if (activeFolderId === ActionIDs.ESCALATED || activeFolderId === ActionIDs.MY_QUEUE)
            decrementFolder(activeFolderId)
          break

        case SaveActionTypes.REMOVE_FROM_PROPOSED:
          decrementFolder(ActionIDs.PROPOSED)
          if (review.review.escalated) incrementFolder(ActionIDs.ESCALATED)
          else incrementFolder(ActionIDs.MY_QUEUE)
          break

        case SaveActionTypes.ADD_TO_ESCALATED:
          incrementFolder(ActionIDs.ESCALATED)
          decrementFolder(ActionIDs.MY_QUEUE)
          break

        case SaveActionTypes.REMOVE_FROM_ESCALATED:
          decrementFolder(ActionIDs.ESCALATED)
          incrementFolder(ActionIDs.MY_QUEUE)
          break

        case SaveActionTypes.ADD_TO_DISPUTED:
          incrementFolder(ActionIDs.DISPUTED)
          decrementFolder(ActionIDs.ESCALATED)
          break

        case SaveActionTypes.REMOVE_FROM_DISPUTED:
          decrementFolder(ActionIDs.DISPUTED)
            if (review.review.status !== "Proposed") {
              incrementFolder(ActionIDs.ESCALATED)
            }
            else{
              incrementFolder(ActionIDs.PROPOSED)
            }
          break

        case SaveActionTypes.REMOVE_FROM_EXPIRED_PROPOSALS:
          decrementFolder(ActionIDs.EXPIRED_PROPOSALS)
          incrementFolder(ActionIDs.MY_QUEUE)
          break

        case SaveActionTypes.REMOVE_FROM_QUEUE:
          decrementFolder(activeFolderId)
          break

        default:
          break
      }

      setFolders(folders.flat())
    }
  }

  const handleFolderClick = (folder, ndx) => {
    if (ndx !== folderNdx) {
      setFolderNdx(ndx)

      let query = { ...filterQuery }
      delete query.expiredProposals
      delete query.disputed
      delete query.escalated
      switch (folder.actionId) {
        case ActionIDs.DISPUTED:
          query.disputed = true
          query.escalated = true
          break

        case ActionIDs.ESCALATED:
          query.escalated = true
          query.disputed = false
          break

        case ActionIDs.PROPOSED:
          query.statuses = ['Proposed']
          query.expiredProposals = false
          break

        case ActionIDs.EXPIRED_PROPOSALS:
          query.statuses = ['Proposed']
          query.expiredProposals = true
          break

        default:
          query.escalated = false
          query.disputed = false
          query.expiredProposals = false
          break
      }

      fetchFolderReviews(folder.actionId, 1, pageSize, !clearFilter ? query : null)
    }
  }

  const handleFilterClick = () => toggleFilter(!filterActive)

  useEffect(() => {
    if (updateWorkspace) {
      updateFolders(review)
    }
  }, [updateWorkspace])

  useEffect(() => {
    setFolderNdx(fetchedfolders.findIndex(i => i.actionId === activeFolderId))
    setFolders(fetchedfolders)
  }, [fetchedfolders])

  useEffect(() => {
    setFolderNdx(folders.findIndex(i => i.actionId === activeFolderId))
  }, [activeFolderId])

  useEffect(() => {
    if (filterQuery) {
      loadFolders(filterQuery)
    }
  }, [filterQuery])

  return (
    <div className='d-flex flex-wrap pb-2'>
      <div className='d-flex align-items-center pl-lg-4 pl-md-3 pl-sm-2 pl-2 pr-lg-4 pr-md-3 pr-2 pt-2'>
        <FilterItem active={filterActive} onClick={handleFilterClick}>
          <FiFilter />
        </FilterItem>
      </div>
      {folders.map((folder, index) => (
        <FolderItem
          key={index}
          className='d-flex justify-content-between'
          active={folder.actionId === activeFolderId}
          onClick={() => handleFolderClick(folder, index)}>
          <Typography
            color='textPrimary'
            variant='subtitle2'
            display='inline'
            style={{ lineHeight: '2.2em', marginLeft: '4px' }}>
            {folder.displayName}
          </Typography>
          <span id='queue-item-count' className='das-queue-item-count'>
            <Typography color='textPrimary' variant='subtitle2' display='inline'>
              {folder.count}
            </Typography>
          </span>
        </FolderItem>
      ))}
      <AchievementIndicator />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    filterActive: state.reviews.showFilter,
    fetchedfolders: state.reviews.folders,
    pageSize: state.reviews.pageSize,
    fetchCompleted: state.reviews.fetchCompleted,
    activeFolderId: state.reviews.activeFolderId,
    review: state.review,
    updateWorkspace: state.review.updateWorkspace,
    filterQuery: state.reviews.filterQuery,
    foldersLoaded: state.reviews.foldersLoaded,
    clearFilter: state.reviews.clear,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadFolders: query => {
      dispatch(navbarFoldersLoadAction())
      dispatch(navbarFoldersLoadActionCompletedAction(query))
    },
    toggleFilter: filtered => dispatch(navbarFiltersSelectedAction(filtered)),
    fetchFolderReviews: (folderId, page, pageSize, query) =>
      dispatch(navbarFolderSelectedAction(folderId, page, pageSize, query)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QueueNavBar)
