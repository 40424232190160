import styled from 'styled-components'

export const Editor = styled.textarea`
  border: 1px solid ${props => props.theme.foreground.lightest};
  background-color: ${props => props.theme.background.main};
  color: ${props => props.theme.text.primary};
  &:active,
  &:focus {
    outline: 1px solid ${props => props.theme.secondary.main};
  }
`
