import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import reviewsReducer from './reviews'
import reviewResponseReducer from './review'
import accountReducer from './account'
import notificationsReducer from './notifications'
import userReducer from './user'
import responseTemplatesReducer from './response-templates'
import emailTemplatesReducer from './email-templates'
import commentsReducer from './comments'
import settingsReducer from './settings'
import tagsReducer from './tags'
import achievementReducer from './achievement'

const internalReducer = history =>
  combineReducers({
    router: connectRouter(history),
    reviews: reviewsReducer(),
    review: reviewResponseReducer(),
    user: userReducer(),
    notifications: notificationsReducer(),
    account: accountReducer(),
    responseTemplates: responseTemplatesReducer(),
    emailTemplates: emailTemplatesReducer(),
    comments: commentsReducer(),
    settings: settingsReducer(),
    tags: tagsReducer(),
    achievement: achievementReducer(),
  })

export default internalReducer
