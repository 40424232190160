import React, { useContext, useEffect, useState } from "react";
import AccountsTableView from "./AccountsComponents/AccountsTableView";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ClientSearchInput from "../common/ClientSearchInput";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MaterialInput from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import styled from "styled-components";
import { makeStyles, withStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import RadarServiceFactory, { RadarServices } from "../../services";
import { CircularProgress } from "@mui/material";
import { addNotificationAction, NotificationType } from "../../store/actions/notifications";
import { connect } from "react-redux";
import Globals from "../../services/global";
import ReviewsConfirmExport from "../manager/ReviewManagerComponents/ReviewsConfirmExport";
const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 150,
        maxWidth: 150,
    },
    button: {
        color: '#FFF',
        marginTop: '10px',
    },
    textField: {
        width: '100%',
    },
    picker: {
        maxWidth: 150,
    },
}))

const StyledCheckbox = withStyles(theme => ({
    root: {
        color: theme.palette.primary.light,
        '&$checked': {
            color: theme.palette.primary.main,
        },
    },
    checked: {},
}))(props => <Checkbox color='default' {...props} />)
const ContentBox = styled.div`
  padding-top: 15px;
`
function AccountsWorkspace(props) {
    const defaultCriteria = {
        page: 1,
        count: 25,
        fullMode: true,
        reviewSites: '',
        tagId: '',
        sentiments: '',
        orderBy: '',
        desc: true,
        enabled: '',
        clientApprovalSentiment: '',
        enableProposals: ''

    }
    const { addNotification } = props
    const [fetchStarted, setFetchStarted] = useState(false)
    const [fetchExcelStarted, setFetchExcelStarted] = useState(false)
    const [updateStarted, setUpdateStarted] = useState(false)
    const [searchCriteria, setSearchCriteria] = useState(defaultCriteria)
    const [reviewSites, setReviewSites] = useState([])
    const [tags, setTags] = useState([])
    const [canExport, setCanExport] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [data, setData] = useState([])
    const [sortOrder, setSortOrder] = useState({ orderBy: '', descending: true })
    const [totalItems, setTotalItems] = useState(0)
    const [excelExportConfirmationOpen, setExcelExportConfirmationOpen] = React.useState(false);

    const handleExportConfirmationOpen = () => {
        setExcelExportConfirmationOpen(true);
    };

    const handleCloseExportConfirmation = () => {
        setExcelExportConfirmationOpen(false);
    };
    const handleConfirmExport = () => {
        handleCloseExportConfirmation();
        if (totalItems <= 500) {
            setFetchExcelStarted(true)
        }
        else {
            const service = RadarServiceFactory.create(RadarServices.Accounts);
            service.exportToExcelRequest().then(res => {
                searchCriteria.tempFileName = res.Content
                setFetchExcelStarted(true)
                var win = window.open(`export/${res.Content}/accounts`, '_blank');
                win.focus();
            });
        }
        //setFetchExcelStarted(true);
    };
    const classes = useStyles()

    const handleValueChange = e => setSearchCriteria({ ...searchCriteria, [e.target.name]: e.target.value })
    const handleCountChange = e => setSearchCriteria({ ...searchCriteria, count: parseInt(e.target.value) })

    let sentiments = [
        { id: null, title: 'Any' },
        { id: 1, title: 'Positive' },
        { id: 2, title: 'Neutral' },
        { id: 4, title: 'Negative' },
        { id: 10000, title: 'None' },
    ]
    let proposals = [
        { id: null, title: 'Any' },
        { id: 0, title: 'Disabled' },
        { id: 5, title: 'Enabled' },
        { id: 1, title: 'Enabled-Positive' },
        { id: 2, title: 'Enabled-Neutral' },
        { id: 4, title: 'Enabled-Negative' },
    ]
    let accountStatus = [
        { id: null, title: "Any" },
        { id: 1, title: 'Active' },
        { id: 2, title: 'Inactive' },
    ]
    const clean = criteria => {
        let newCriteria = {}
        for (let key in criteria) {
            let val = criteria[key]
            if (!val) continue
            if (Array.isArray(val) && val.length < 1) continue
            newCriteria[key] = val
        }
        return newCriteria
    }
    useEffect(() => {
        const fetchSites = async () => {
            const service = RadarServiceFactory.create(RadarServices.ReviewSites),
                retval = await service.getReviewSites()
            retval.Content.unshift({ "reviewSiteId": null, displayName: "Any" });
            retval.Content.push({ "reviewSiteId": 10000, displayName: "None" })
            if (retval.Ok) setReviewSites(retval.Content)
        }
        fetchSites()
    }, [])
    useEffect(() => {
        const fetchTags = async () => {
            const service = RadarServiceFactory.create(RadarServices.Tags),
                retval = await service.getTagIds()
            retval.Content.unshift({ "tagId": null, displayName: "Any" });
            if (retval.Ok) setTags(retval.Content)
        }

        fetchTags()
    }, [])
    useEffect(() => {
        const fetchData = async criteria => {
            const newCriteria = Object.assign({}, criteria)
            let query = clean(newCriteria)
            if (query.enabled) query.enabled = query.enabled === 1
            if (criteria.clientApprovalSentiment != null && criteria.clientApprovalSentiment === 0) {
                delete query.clientApprovalSentiment
                query.enableProposals = false
            }
            if (criteria.clientApprovalSentiment != null && criteria.clientApprovalSentiment === 5) {
                delete query.clientApprovalSentiment
                query.enableProposals = true
            }
            const service = RadarServiceFactory.create(RadarServices.Accounts),
                retval = await service.getClients(query)
            if (retval.Ok) {
                setData(retval.Content.result)
                setTotalItems(retval.Content.totalCount)
                if (retval.Content.totalCount) {
                    setCanExport(true);
                }
            } else {
                setData([])
                addNotification({
                    type: NotificationType.ERROR_NOTIFICATION,
                    text: 'Search failed!',
                })
            }
            setFetchStarted(false)
            return () => {
                setData({});
            };
        }

        if (fetchStarted) {
            fetchData(searchCriteria)
        }
    }, [fetchStarted])

    useEffect(() => {
        const exportToExcel = async () => {
            const newCriteria = Object.assign({}, searchCriteria);
            let query = clean(newCriteria)
            if (query.enabled) query.enabled = query.enabled === 1
            if (searchCriteria.clientApprovalSentiment != null && searchCriteria.clientApprovalSentiment === 0) {
                delete query.clientApprovalSentiment
                query.enableProposals = false
            }
            if (searchCriteria.clientApprovalSentiment != null && searchCriteria.clientApprovalSentiment === 5) {
                delete query.clientApprovalSentiment
                query.enableProposals = true
            }
            const service = RadarServiceFactory.create(RadarServices.Accounts);
            service.exportToExcel(query, selectedRows).then(res => {
                setFetchExcelStarted(false)
            });
        }
        if (fetchExcelStarted) {
            exportToExcel()
        }
    }, [fetchExcelStarted])

    const handleSearchClick = () => {
        if (!fetchStarted) {
            setSearchCriteria({ ...searchCriteria, page: 1 })
            setFetchStarted(true)
        }
    }
    const handleExportClick = async () => {
        searchCriteria.tempFileName = null
        if ((selectedRows && selectedRows.length > 0 && selectedRows.length <= 500) || totalItems <= 500) {
            setFetchExcelStarted(true);
        }
        else {
            handleExportConfirmationOpen();
        }

    }

    const handlePageChange = page => {
        setSearchCriteria({ ...searchCriteria, page: page + 1 })
        setFetchStarted(true)
    }

    const handleRowCountChange = count => {
        setSearchCriteria({ ...searchCriteria, count: parseInt(count) })
        setFetchStarted(true)
    }

    const handleRowSelected = rows => {
        setSelectedRows(rows);
    }

    const handleRowSelectedClick = item => {
    }

    const handleHeaderClick = (orderBy, descending) => {
        setSortOrder({ orderBy, descending })
        setSearchCriteria({ ...searchCriteria, orderBy, desc: descending })
        if (!fetchStarted && data.length > 0) setFetchStarted(true)
    }

    useEffect(() => {
        setCanExport(false);
        setSelectedRows([]);
    }, [searchCriteria])

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className='container-fluid'>
                <div className='row'>
                    <ContentBox className='col-sm-12'>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor='tagId'>Tags</InputLabel>
                            <Select
                                name='tagId'
                                labelId='tagId'
                                id='tagId'
                                value={searchCriteria.tagId}
                                input={<MaterialInput />}
                                onChange={handleValueChange}>
                                {tags.map(item => (
                                    <MenuItem key={item.tagId} value={item.tagId}>{item.displayName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ display: 'inline', padding: '20px' }}>&nbsp;</div>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor='sentiment'>Sentiments</InputLabel>
                            <Select
                                name='sentiment'
                                labelId='sentiment'
                                id='sentiment'
                                value={searchCriteria.sentiment}
                                input={<MaterialInput />}
                                onChange={handleValueChange}>
                                {sentiments.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ display: 'inline', padding: '20px' }}>&nbsp;</div>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor='reviewSiteId'>Sites</InputLabel>
                            <Select
                                name='reviewSiteId'
                                labelId='reviewSiteId'
                                id='reviewSiteId'
                                value={searchCriteria.reviewSiteId}
                                input={<MaterialInput />}
                                onChange={handleValueChange}>
                                {reviewSites.map(item => (
                                    <MenuItem key={item.reviewSiteId} value={item.reviewSiteId}>{item.displayName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ display: 'inline', padding: '20px' }}>&nbsp;</div>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor='clientApprovalSentiment'>Proposals </InputLabel>
                            <Select
                                name='clientApprovalSentiment'
                                labelId='clientApprovalSentiment'
                                id='clientApprovalSentiment'
                                value={searchCriteria.clientApprovalSentiment}
                                input={<MaterialInput />}
                                onChange={handleValueChange}>
                                {proposals.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ display: 'inline', padding: '20px' }}>&nbsp;</div>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor='enabled'>Account Status </InputLabel>
                            <Select
                                name='enabled'
                                labelId='enabled'
                                id='enabled'
                                value={searchCriteria.enabled}
                                input={<MaterialInput />}
                                onChange={handleValueChange}>
                                {accountStatus.map((item, index) => (
                                    <MenuItem key={item.index} value={item.id}>{item.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ display: 'inline', padding: '20px' }}>&nbsp;</div>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor='limit-label'>Limit</InputLabel>
                            <Select
                                name='count'
                                labelId='limit-label'
                                id='limit'
                                value={searchCriteria.count}
                                input={<MaterialInput />}
                                onChange={handleCountChange}>
                                <MenuItem value='10'>10</MenuItem>
                                <MenuItem value='25'>25</MenuItem>
                                <MenuItem value='50'>50</MenuItem>
                                <MenuItem value='100'>100</MenuItem>
                                <MenuItem value='500'>500</MenuItem>
                                <MenuItem value='1000'>1000</MenuItem>
                            </Select>
                        </FormControl>
                        <div style={{ display: 'inline', padding: '20px' }}>&nbsp;</div>
                        <Button
                            disableElevation
                            className={classes.button}
                            variant='contained'
                            color='primary'
                            onClick={handleSearchClick}>
                            Search
                        </Button>
                        <Button
                            disableElevation
                            className={`ml-4 ${classes.button}`}
                            variant='contained'
                            color='success'
                            disabled={fetchExcelStarted || !canExport}
                            onClick={handleExportClick}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {(fetchExcelStarted) ? <><span>Exporting...</span> <CircularProgress className={'ml-2'} style={{ width: 16, height: 16 }} /></> : (selectedRows.length === 0) ? <span>Export All</span> : <span>Export Selected</span>}
                            </div>
                        </Button>
                    </ContentBox>
                </div>
            </div>
            <ContentBox style={{ padding: '30px 10px 10px 10px' }}>
                <div style={{ height: '7px', paddingTop: '2px' }}>
                    {(fetchStarted || updateStarted) && <LinearProgress />}
                </div>
                <AccountsTableView
                    data={data}
                    orderBy={sortOrder.orderBy}
                    descending={sortOrder.descending}
                    page={searchCriteria.page - 1}
                    count={totalItems}
                    rows={searchCriteria.count}
                    onPageChange={handlePageChange}
                    onRowCountChange={handleRowCountChange}
                    onRowSelected={handleRowSelected}
                    onRowSelectedClick={handleRowSelectedClick}
                    onHeaderClick={handleHeaderClick}
                />
            </ContentBox>
            <ReviewsConfirmExport open={excelExportConfirmationOpen} totalReviews={(selectedRows && selectedRows.length > 0) ? selectedRows.length : totalItems} onConfirm={handleConfirmExport} onClose={handleCloseExportConfirmation} />

        </LocalizationProvider>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        addNotification: notification => {
            dispatch(addNotificationAction(notification.text, notification.type))
        },
    }
}

export default connect(null, mapDispatchToProps)(AccountsWorkspace)
