import styled from 'styled-components'
import { BsChatDots } from 'react-icons/bs'

export const CommentView = styled.div`
  margin: 0px 36px;
  padding: 8px;
  border: 1px solid ${props => props.theme.background.dark};
  border-radius: 10px;
`

export const CommentChatBubble = styled(BsChatDots)`
  font-weight: 600;
  font-size: 1.6em;
  margin-left: 10px;
`

export const CommentContainer = styled.div`
  padding-top: 10px;
  padding-bottom: 5px;
`
