import UserGraph from './msgraph'
import generateId from './idgenerator'
export default class RadarMail {
  graph

  constructor() {
    this.graph = new UserGraph()
  }

  async sendMail(message) {
    this.__reformat(message)
    const mail = {
      subject: message.subject,
      toRecipients: message.recipients.map(r => ({ emailAddress: { address: r } })),
      body: {
        content: message.body,
        contentType: 'html',
      },
      isDraft: true,
      attachments: message.attachments || [],
    }

    try {
      let response = await this.graph.api('/me/sendMail').post({ saveToSentItems: true, message: mail })
      return { Ok: true, Content: response }
    } catch (error) {
      return { Ok: false, Message: error }
    }
  }

  __reformat(message) {
    let tags = [],
      result = null,
      start = 0
    do {
      result = this.__findImageTag(start, message.body)
      if (result) {
        tags.push(result)
        start = result.end + 1
      }
    } while (result)

    if (tags.length > 0) {
      message.attachments = []
      while (tags.length > 0) {
        let tag = tags.pop()
        result = this.__replaceImageTag(tag, message.body)
        message.body = result.body
        message.attachments.push(result.attachment)
      }
    }
  }

  __findImageTag(start, content) {
    let coord = {},
      tag = ''

    for (let i = start; i < content.length; i++) {
      if (content[i] === '<') {
        tag = ''
      } else if (content[i] === '>' && coord.start) {
        coord.end = i
        return coord
      } else if (content[i] === ' ' && tag === 'img') {
        coord.start = i - '<img'.length
      } else {
        tag += content[i]
      }
    }

    return null
  }

  __replaceImageTag(coord, body) {
    let tag = body.slice(coord.start, coord.end),
      bytes = this.__extract(tag),
      front = body.substring(0, coord.start),
      back = body.substring(coord.end + 1),
      name = generateId()

    return {
      body: `${front}<img src="cid:${name}.jpg">${back}`,
      attachment: {
        '@odata.type': '#microsoft.graph.fileAttachment',
        contentId: `${name}.jpg`,
        id: `${name}.jpg`,
        name: `${name}.jpg`,
        contentType: 'image/jpeg',
        contentBytes: bytes,
        isInline: true,
      },
    }
  }

  __extract(content) {
    let ndx = content.indexOf('base64') + 'base64,'.length
    let bytes = ''

    for (let i = ndx; i < content.length; i++) {
      if (content[i] === '"') break
      bytes += content[i]
    }

    return bytes
  }
}
