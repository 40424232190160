import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ReviewServiceFactory, { RadarServices } from '../../../../services'
import FilterModal from './FilterModal'

function SignatureTemplates({ show, onClose, onTemplateItemSelected, clientId }) {
  const [templates, setTemplates] = useState([])
  const [fetchStarted, setFetchStarted] = useState(false)

  const fetchTemplates = async () => {
    let service = ReviewServiceFactory.create(RadarServices.Accounts),
      result = await service.getSignatures(clientId, 1, 500)
    if (result.Ok) {
      setTemplates(result.Content.signatures)
    } else {
      setTemplates([])
    }

    setFetchStarted(false)
  }

  useEffect(() => {
    if (show) {
      setFetchStarted(true)
      fetchTemplates()
    }
  }, [show])

  return (
    <>
      <FilterModal
        id='signature-template'
        show={show}
        title='Choose a Signature Template'
        noResultsText='No signatures available'
        fetchStarted={fetchStarted}
        fetchTemplates={fetchTemplates}
        templates={templates}
        onTemplateItemSelected={onTemplateItemSelected}
        onClose={onClose}
      />
    </>
  )
}

function mapStateToProps(state) {
  return {
    clientId: state.review.review.client.clientId,
  }
}

export default connect(mapStateToProps, null)(SignatureTemplates)
