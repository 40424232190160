import React, { useState, useEffect } from 'react'
import { BsChatQuoteFill } from 'react-icons/bs'
import { makeStyles } from '@mui/styles'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import Collapse from '@mui/material/Collapse'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: { padding: '0' },
  paper: {
    padding: '6px 16px',
    borderRadius: '15px',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.secondary.main + '41' : 'rgba(0,150,219,0.8)',
    color: '#FFF',
    maxWidth: '450px',
  },
  dot: {
    boxShadow: '0px 0px 0px 0px',
    color: theme.palette.primary.main,
  },
  connector: {
    backgroundColor: '#EAE6E3',
  },
  date: {
    color: theme.palette.text.secondary,
  },
  collaspe: {
    paddingLeft: '19px',
  },
  expand: {
    color: theme.palette.primary.main,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  quote: {
    padding: '0px',
    marginLeft: '5px',
    marginTop: '-5px',
    color: theme.palette.secondary.main,
  },
}))

export default function ReplyTimeline({ review }) {
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    setExpanded(false)
  }, [review])

  return (
    review.replyTimeLine.length > 0 && (
      <div className={classes.root}>
        <div className='flex-row'>
          <IconButton
            className={expanded ? classes.expandOpen : classes.expand}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'>
            <ExpandMoreIcon />
          </IconButton>
          <Typography className={classes.date} variant='caption'>
            {expanded ? 'Hide' : 'Show'} Responses
          </Typography>
          <BsChatQuoteFill className={classes.quote} />
        </div>
        <Collapse className={classes.collaspe} in={expanded} timeout='auto' unmountOnExit>
          <Timeline className={classes.root} align='left'>
            {review.replyTimeLine.map((reply, ndx) => (
              <TimelineItem key={ndx} align='left'>
                <TimelineOppositeContent style={{ display: 'none' }} />
                <TimelineSeparator>
                  <TimelineConnector className={classes.connector} />
                  <TimelineDot className={classes.dot} color='primary' />
                </TimelineSeparator>
                <TimelineContent>
                  <Typography className={classes.date} variant='caption' display='block' gutterBottom>
                    {moment(reply.date).format('dddd, MMMM Do YYYY h:mm A')}
                  </Typography>
                  <Paper elevation={0} className={classes.paper}>
                    <Typography variant='overline' display='block'>
                      {reply.name} response:
                    </Typography>
                    <Typography variant='body2'>{reply.response}</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Collapse>
      </div>
    )
  )
}
