import styled from 'styled-components'

export const AiPromptsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Editor = styled.textarea`
  width: 100%;
  height: 250px;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: ${props => props.theme.background.dark + '4F'};
  color: ${props => props.theme.text.primary};
`

export const AiPromptsParagraph = styled.p`
  font-size: 14px;
  color: ${props => props.theme.text.primary} !important;
  width: 100%;

  border-radius: 10px;
  background-color: ${props => props.theme.background.dark + '4F'};
  padding: 7px;
  overflow: auto;
`

export const UpdatingText = styled.div`
  color: #fff;
  padding-left: 15px;
`
