import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import { FaTimes } from 'react-icons/fa'
import { BsPencil } from 'react-icons/bs'
import { useTheme } from '@mui/styles'
import FlexContainer from '../../common/FlexContainer'
import CheckBox from '../../common/CheckBox'
import Input from '../../common/Input'
import Button from '../../common/Button'
import ButtonSpinner from '../../common/ButtonSpinner'
import { ItemContainer, SentimentSpan, Textarea } from './Signatures.S'
import { updateAccountStartedAction, updateAccountCompletedAction } from '../../../store/actions/account'
import { TitleBox, Header, Body, ContentBox, SavingText, Card, IconButton, HrLineBreak } from './Shared.S'
import generateId from '../../../lib/idgenerator'

function Signatures(props) {
  const { account, updateStarted, saveAccount, updatedAccount } = props

  const defaultSignature = {}
  const [signatures, setSignatures] = useState(account.signatures)
  const [addNew, setAddNew] = useState(false)
  const [editSignature, setEditSignature] = useState(defaultSignature)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const containerRef = useRef(null)
  const theme = useTheme()

  const handleTitleChange = e => {
    let signature = { ...editSignature }
    signature.title = e.target.value
    setEditSignature(signature)
  }

  const handleContentChange = e => {
    let signature = { ...editSignature }
    signature.content = e.target.value
    setEditSignature(signature)
  }

  const handleSentimentClick = (sentiment, checked) => {
    if (checked) editSignature.sentiments.push(sentiment)
    else editSignature.sentiments = editSignature.sentiments.filter(a => a !== sentiment)
    setEditSignature({ ...editSignature })
  }

  const handleSaveClick = signature => {
    if (!updateStarted) {
      let updates = signatures.filter(a => a.templateId !== signature.templateId)
      saveAccount(account.clientId, {
        signatures: [signature, ...updates],
      })
    }
  }

  const handleDeleteClick = signature => {
    if (!updateStarted) {
      saveAccount(account.clientId, {
        signatures: signatures.filter(a => a.templateId !== signature.templateId),
      })
    }
  }

  const handleAddNewClick = () => {
    setEditSignature({
      templateId: generateId(),
      title: '',
      content: '',
      sentiments: [],
    })
    setAddNew(true)
    containerRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const handleCloseClick = editing => {
    if (editing) setEditSignature(defaultSignature)
    else setAddNew(false)
  }

  const handleCancelClick = () => setAddNew(false)

  const handleRemoveTemplateClick = () => setShowDeleteModal(true)

  const editSignatureProps = {
    handleCloseClick: handleCloseClick,
    handleCancelClick: handleCancelClick,
    handleSaveClick: handleSaveClick,
    handleTitleChange: handleTitleChange,
    handleSentimentClick: handleSentimentClick,
    handleContentChange: handleContentChange,
    handleRemoveTemplateClick: handleRemoveTemplateClick,
  }

  useEffect(() => {
    if (updatedAccount?.succeeded) {
      setShowDeleteModal(false)
      setSignatures(updatedAccount.content.signatures)
      setEditSignature(defaultSignature)
      setAddNew(false)
    }
  }, [updatedAccount])

  return (
    <FlexContainer justifyContent='start' alignItems='left' direction='column'>
      <ContentBox>
        <TitleBox>
          <Header>Signatures</Header>
          <Body>
            Configure and manage Signatures used in reviews. Signatures are used as the ‘sign-off’ at the end
            of a response.
          </Body>
        </TitleBox>
      </ContentBox>
      <ContentBox>
        <Button margin='0px' className='btn das-primary-bg text-white' onClick={handleAddNewClick}>
          Add New Signature
        </Button>
      </ContentBox>
      <ItemContainer
        ref={containerRef}
        className={theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}>
        <ContentBox className='pt-4'>
          {addNew ? (
            <EditSignature
              {...editSignatureProps}
              signature={editSignature}
              editing={false}
              updateStarted={updateStarted}
            />
          ) : null}
        </ContentBox>
        {signatures.map(a => (
          <ContentBox key={a.templateId}>
            {a.templateId === editSignature.templateId && !addNew ? (
              <EditSignature
                {...editSignatureProps}
                key={a.templateId}
                signature={editSignature}
                editing={true}
                updateStarted={updateStarted}
              />
            ) : (
              <Signature signature={a} handleEditClick={s => setEditSignature(s)} />
            )}
          </ContentBox>
        ))}
      </ItemContainer>
      <DeleteSignatureModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={handleDeleteClick}
        signature={editSignature}
        updateStarted={updateStarted}
      />
    </FlexContainer>
  )
}

const Signature = ({ signature, handleEditClick }) => (
  <Card
    noHover
    override='min-width: 500px;'
    width='100%'
    height='max-width: 1245px;'
    maxHeight='250px'
    padding='12px 15px'>
    <FlexContainer height='35px' direction='row'>
      <FlexContainer justifyContent='flex-start'>
        <Body color='foreground' margin='0px' fontSize='18px'>
          {signature.title}
        </Body>
        <Body margin='0px 10px 0px 15px'>
          {signature.sentiments.map(sentiment => (
            <SentimentSpan key={sentiment} sentiment={sentiment} />
          ))}
        </Body>
      </FlexContainer>
      <IconButton onClick={() => handleEditClick(signature)}>
        <BsPencil className='iconButton pull-right' />
      </IconButton>
    </FlexContainer>
    <HrLineBreak width='100%' />
    <Body margin='0px' fontSize='14px'>
      {signature.content}
    </Body>
  </Card>
)

const EditSignature = props => {
  const {
    editing,
    signature,
    updateStarted,
    handleSaveClick,
    handleTitleChange,
    handleContentChange,
    handleCloseClick,
    handleCancelClick,
    handleRemoveTemplateClick,
    handleSentimentClick,
  } = props
  return (
    <Card
      noHover
      width='100%'
      borderRadius='5px'
      style={{ height: '310px', maxWidth: '1245px' }}
      maxHeight='450px'
      margin='2px'
      padding='15px'>
      <FlexContainer direction='column' justifyContent='flex-start'>
        <FlexContainer>
          <Body color='foreground' margin='0px 10px 5px 6px'>
            <b>{editing ? 'EDIT' : 'ADD NEW'}</b> Signature Template
          </Body>
          <IconButton onClick={() => handleCloseClick(editing)}>
            <FaTimes className='closeButton' />
          </IconButton>
        </FlexContainer>
        <FlexContainer justifyContent='space-between'>
          <Input
            width='45%'
            value={signature.title}
            variant='main'
            onChange={handleTitleChange}
            placeholder='Signature Template Name'
          />
          <FlexContainer width='fit-content' margin='0 0 0 auto'>
            <FlexContainer justifyContent='flex-start'>
              <CheckBox
                checked={signature.sentiments.includes('Positive')}
                onChanged={(e, checked) => handleSentimentClick('Positive', checked)}
              />
              <Body margin='0px 10px 0px 10px' color='foreground' fontSize='16px' fontWeight='500'>
                Positive
              </Body>
            </FlexContainer>
            <FlexContainer justifyContent='flex-start'>
              <CheckBox
                checked={signature.sentiments.includes('Neutral')}
                onChanged={(e, checked) => handleSentimentClick('Neutral', checked)}
              />
              <Body margin='0px 10px 0px 10px' color='foreground' fontSize='16px' fontWeight='500'>
                Neutral
              </Body>
            </FlexContainer>
            <FlexContainer justifyContent='flex-start'>
              <CheckBox
                checked={signature.sentiments.includes('Negative')}
                onChanged={(e, checked) => handleSentimentClick('Negative', checked)}
              />
              <Body margin='0px 10px 0px 10px' color='foreground' fontSize='16px' fontWeight='500'>
                Negative
              </Body>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <Textarea
            value={signature.content}
            placeholder='Draft Signature Template Here...'
            onChange={handleContentChange}
            height='150px'
            margin='5px'
          />
        </FlexContainer>
      </FlexContainer>
      {editing ? (
        <FlexContainer padding='5px 0px 0px 10px' alignItems='baseline' justifyContent='flex-end'>
          <Button onClick={handleRemoveTemplateClick} color='error' hover='lightest'>
            Remove Template
          </Button>
          <ButtonSpinner
            disableElevation
            style={{ color: '#FFF' }}
            variant='contained'
            color='primary'
            spin={updateStarted}
            spinContent={<SavingText>Saving..</SavingText>}
            onClick={() => handleSaveClick(signature)}>
            Save
          </ButtonSpinner>
        </FlexContainer>
      ) : (
        <FlexContainer padding='5px 0px 0px 10px' alignItems='baseline' justifyContent='flex-end'>
          <Button onClick={handleCancelClick} color='background'>
            Cancel
          </Button>
          <ButtonSpinner
            disableElevation
            style={{ color: '#FFF' }}
            variant='contained'
            color='primary'
            spin={updateStarted}
            spinContent={<SavingText>Saving..</SavingText>}
            onClick={() => handleSaveClick(signature)}>
            Add New
          </ButtonSpinner>
        </FlexContainer>
      )}
    </Card>
  )
}

const DeleteSignatureModal = ({ show, updateStarted, signature, onClose, onDelete }) => (
  <Modal show={show} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
    <Modal.Header closeButton>
      <Modal.Title id='contained-modal-title-vcenter'>Delete Signature</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Are you sure you want to delete this signature? This will permanently delete '{signature.title}'
        template
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='secondary' onClick={onClose}>
        Close
      </Button>
      <ButtonSpinner
        disableElevation
        style={{ color: '#FFF' }}
        variant='contained'
        color='primary'
        spin={updateStarted}
        spinContent={<SavingText>Deleting..</SavingText>}
        onClick={() => onDelete(signature)}>
        Delete Signature
      </ButtonSpinner>
    </Modal.Footer>
  </Modal>
)

const mapStateToProps = state => {
  return {
    account: state.account.account,
    updateStarted: state.account.updateStarted,
    updatedAccount: state.account.updatedAccount,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveAccount: (clientId, account) => {
      dispatch(updateAccountStartedAction())
      dispatch(updateAccountCompletedAction(clientId, account))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signatures)
