import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  right: 10px;

  .icon {
    margin-top: 10px;
    height: 32px;
    width: 32px;
    color: ${props => props.theme.primary.main};
  }

  .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 105%;
    font-size: 15px;
  }

  :hover .tooltiptext {
    visibility: visible;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
`
