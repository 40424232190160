// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import RadarServiceFactory, { RadarServices } from '../../services'
import { addNotificationAction, NotificationType } from '../../store/actions/notifications'
import ReviewsTableView from './ReviewManagerComponents/ReviewsTableView'
import ClientSearchInput from '../common/ClientSearchInput'
import SimpleModal from '../common/SimpleModal'
import FlexContainer from '../common/FlexContainer'
import BodyText from '../common/BodyText'
import { makeStyles, withStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import MaterialInput from '@mui/material/Input'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import LinearProgress from '@mui/material/LinearProgress'
import MomentUtils from '@date-io/moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Globals from "../../services/global";
import { CircularProgress } from "@mui/material";
import ReviewsConfirmExport from "./ReviewManagerComponents/ReviewsConfirmExport";
const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 150,
        maxWidth: 150,
    },
    button: {
        color: '#FFF',
        marginTop: '10px',
    },
    textField: {
        width: '100%',
    },
    picker: {
        maxWidth: 150,
    },
}))

const StyledCheckbox = withStyles(theme => ({
    root: {
        color: theme.palette.primary.light,
        '&$checked': {
            color: theme.palette.primary.main,
        },
    },
    checked: {},
}))(props => <Checkbox color='default' {...props} />)

const ContentBox = styled.div`
  padding-top: 15px;
`

function ReviewsManagerWorkspace(props) {
    const { addNotification } = props

    const reviewStatus = [
        'Closed',
        'DoNotRespond',
        'InDraft',
        'New',
        'Posted',
        'Proposed',
        'Ready',
        'RemovedAtReviewSite',
        'NotRemovedAtReviewSite',
        'Undrafted',
        'Unknown',
    ]
    const reviewSeverites = [
        'Disputed',
        'Escalated',
    ]
    const defaultCriteria = {
        page: 1,
        count: 25,
        reviewSites: [],
        reviewSources: [],
        statuses: [],
        severities: [],
        tags: [],
        clients: [],
        sentiments: [],
        reviewTitle: null,
        reviewText: null,
        startDate: null,
        endDate: null,
        ingestionStartDate: null,
        ingestionEndDate: null,
        orderBy: '',
        desc: true,
    }
    const reviewSources = ['Direct', 'Mozenda', 'Vendasta']
    const [reviewSites, setReviewSites] = useState([])
    const [tags, setTags] = useState([])
    const [searchCriteria, setSearchCriteria] = useState(defaultCriteria)
    const [fetchStarted, setFetchStarted] = useState(false)
    const [fetchExcelStarted, setFetchExcelStarted] = useState(false)
    const [updateStarted, setUpdateStarted] = useState(false)
    const [reviews, setReviews] = useState([])
    const [totalReviews, setTotalReviews] = useState(0)
    const [closeAllRows, setCloseAllRows] = useState([])
    const [reOpenAllRows, setReOpenAllRows] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [canExport, setCanExport] = useState(false)
    const [modal, setModal] = useState({})
    const [sortOrder, setSortOrder] = useState({ orderBy: '', descending: true })
    const [endDate, setEndDate] = useState(null)
    const [ingestionEndDate, setIngestionEndDate] = useState(null)
    const [excelExportConfirmationOpen, setExcelExportConfirmationOpen] = React.useState(false);

    const handleExportConfirmationOpen = () => {
        setExcelExportConfirmationOpen(true);
    };

    const handleCloseExportConfirmation = () => {
        setExcelExportConfirmationOpen(false);
    };
    const handleConfirmExport = () => {
        handleCloseExportConfirmation();
        if (totalReviews <= 500){
            setFetchExcelStarted(true)
        }
        else{
            const service = RadarServiceFactory.create(RadarServices.Reviews);
            service.exportToExcelRequest().then(res=>{
                searchCriteria.tempFileName = res.Content
                searchCriteria.disputed = searchCriteria.severities.includes("Disputed");
                searchCriteria.escalated = searchCriteria.severities.includes("Escalated");
                setFetchExcelStarted(true)
                var win = window.open(`export/${res.Content}`, '_blank');
                win.focus();
            });
        }
        //setFetchExcelStarted(true);
    };
    const classes = useStyles()

    const clean = criteria => {
        let newCriteria = {}
        for (let key in criteria) {
            let val = criteria[key]
            if (!val) continue
            if (Array.isArray(val) && val.length < 1) continue
            newCriteria[key] = val
        }
        return newCriteria
    }

    const handleClientSelected = client => {
        setSearchCriteria({ ...searchCriteria, clients: [client.id] })
    }

    const handleClientCleared = () => {
        setSearchCriteria({ ...searchCriteria, clients: [] })
    }

    const handleValueChange = e => setSearchCriteria({ ...searchCriteria, [e.target.name]: e.target.value })

    const handleCountChange = e => setSearchCriteria({ ...searchCriteria, count: parseInt(e.target.value) })

    const handlePostedStartDateChange = date => {
        let startDate = null
        if (date) startDate = date.format('YYYY-MM-DDT00:00:00')
        setSearchCriteria({ ...searchCriteria, startDate })
    }

    const handlePostedEndDateChange = date => {
        let endDate = null
        setEndDate(null)
        if (date) endDate = date.format('YYYY-MM-DDT23:59:59')
        if (date) setEndDate(date.format('YYYY-MM-DDT00:00:00'))
        setSearchCriteria({ ...searchCriteria, endDate })
    }

    const handleCollectedStartDateChange = date => {
        let ingestionStartDate = null
        if (date) ingestionStartDate = date.format('YYYY-MM-DDT00:00:00')
        setSearchCriteria({ ...searchCriteria, ingestionStartDate })
    }

    const handleCollectedEndDateChange = date => {
        let ingestionEndDate = null
        setIngestionEndDate(null)
        if (date) ingestionEndDate = date.format('YYYY-MM-DDT23:59:59')
        if (date) setIngestionEndDate(date.format('YYYY-MM-DDT00:00:00'))
        setSearchCriteria({ ...searchCriteria, ingestionEndDate })
    }

    const handleSearchClick = () => {
        if (!fetchStarted) {
            setSearchCriteria({ ...searchCriteria, page: 1 })
            setFetchStarted(true)
        }
    }

    const handlePageChange = page => {
        setSearchCriteria({ ...searchCriteria, page: page + 1 })
        setFetchStarted(true)
    }

    const handleRowCountChange = count => {
        setSearchCriteria({ ...searchCriteria, count: parseInt(count) })
        setFetchStarted(true)
    }

    const handleRowSelected = rows => {
        let openAll = [],
          closeAll = [];
        setSelectedRows(rows);
        for (let reviewId of rows) {
            let review = reviews.find(a => a.reviewId === reviewId)
            if (review.status === 'Closed') openAll.push(reviewId)
            else closeAll.push(reviewId)
        }
        setCloseAllRows(closeAll)
        setReOpenAllRows(openAll)
    }

    const handleRowSelectedClick = review => {
        let updateData
        if (review.status === 'Closed') {
            updateData = {
                reviewIds: [review.reviewId],
                reason: 'Bulk reopen from Reviews Manager',
                status: 'Undrafted',
            }
        } else {
            updateData = {
                reviewIds: [review.reviewId],
                reason: 'Bulk close from Reviews Manager',
                status: 'Closed',
            }
        }

        setModal({ updateData })
        setUpdateStarted(true)
    }

    const handleOpenAllClick = () => {
        setModal({
            showModal: true,
            title: `Action: Review ReOpen All`,
            content: `Are you sure that you want to reopen ${reOpenAllRows.length} review(s)?`,
            updateData: {
                reviewIds: reOpenAllRows,
                reason: 'Bulk reopen from Reviews Manager',
                status: 'Undrafted',
            },
            handleModalClick: () => {
                setUpdateStarted(true)
            },
        })
    }

    const handleCloseAllClick = () => {
        setModal({
            showModal: true,
            title: `Action: Review Close All`,
            content: `Are you sure that you want to close ${closeAllRows.length} review(s)?`,
            updateData: {
                reviewIds: closeAllRows,
                reason: 'Bulk close from Reviews Manager',
                status: 'Closed',
            },
            handleModalClick: () => {
                setUpdateStarted(true)
            },
        })
    }

    const handleExportClick = async () => {
        searchCriteria.tempFileName = null
        if ((selectedRows && selectedRows.length > 0 && selectedRows.length <= 500) || totalReviews <= 500) {
            setFetchExcelStarted(true);
        }
        else {
            handleExportConfirmationOpen();
        }

    }

    const handleHeaderClick = (orderBy, descending) => {
        setSortOrder({ orderBy, descending })
        setSearchCriteria({ ...searchCriteria, orderBy, desc: descending })
        if (!fetchStarted && reviews.length > 0) setFetchStarted(true)
    }
    useEffect(() => {
        const exportToExcel = async () => {
            const newCriteria = Object.assign({}, searchCriteria);
            if (searchCriteria.reviewSources.length === 3) {
                newCriteria.reviewSources = []
            } else {
                if (newCriteria.reviewSources.some(x => x === 'Direct')) {
                    newCriteria.reviewSources = newCriteria.reviewSources.filter(x => x !== 'Direct')
                    for (let item of reviewSites) {
                        newCriteria.reviewSources.push(item.nameId)
                    }
                }
            }
            newCriteria.disputed = newCriteria.severities.includes("Disputed");
            newCriteria.escalated = newCriteria.severities.includes("Escalated");
            let query = clean(newCriteria)
            const service = RadarServiceFactory.create(RadarServices.Reviews);
            service.exportToExcel(query,selectedRows).then(res=>{
                setFetchExcelStarted(false)
            });
        }
        if (fetchExcelStarted){
            exportToExcel()
        }
    }, [fetchExcelStarted])
    useEffect(() => {
        const fetchSites = async () => {
            const service = RadarServiceFactory.create(RadarServices.ReviewSites),
              retval = await service.getReviewSites()
            if (retval.Ok) setReviewSites(retval.Content)
        }
        fetchSites()
    }, [])

    useEffect(() => {
        const fetchTags = async () => {
            const service = RadarServiceFactory.create(RadarServices.Tags),
              retval = await service.getTagIds()
            if (retval.Ok) setTags(retval.Content)
        }

        fetchTags()
    }, [])

    useEffect(() => {
        const fetchReviews = async criteria => {
            const newCriteria = Object.assign({}, criteria)
            newCriteria.disputed = criteria.severities.includes("Disputed");
            newCriteria.escalated = criteria.severities.includes("Escalated");
            if (criteria.reviewSources.length === 3) {
                newCriteria.reviewSources = []
            } else {
                if (newCriteria.reviewSources.some(x => x === 'Direct')) {
                    newCriteria.reviewSources = newCriteria.reviewSources.filter(x => x !== 'Direct')
                    for (let item of reviewSites) {
                        newCriteria.reviewSources.push(item.nameId)
                    }
                }
            }
            let query = clean(newCriteria)
            const service = RadarServiceFactory.create(RadarServices.Reviews),
              retval = await service.search(query)
            if (retval.Ok) {
                setReviews(retval.Content.reviews)
                setTotalReviews(retval.Content.totalCount)
                if (retval.Content.totalCount){
                    setCanExport(true);
                }
            } else {
                setReviews([])
                addNotification({
                    type: NotificationType.ERROR_NOTIFICATION,
                    text: 'Search failed!',
                })
            }
            setFetchStarted(false)
        }

        if (fetchStarted) {
            fetchReviews(searchCriteria)
        }
    }, [fetchStarted])

    useEffect(() => {
        const runUpdate = async () => {
            const service = RadarServiceFactory.create(RadarServices.Reviews),
              retval = await service.saveReviews(modal.updateData)
            if (retval.Ok) {
                addNotification({
                    type: NotificationType.SUCCESS_NOTIFICATION,
                    text: 'Update completed successfully',
                })
                setFetchStarted(true)
            } else {
                addNotification({
                    type: NotificationType.ERROR_NOTIFICATION,
                    text: 'Update failed!',
                })
            }

            setUpdateStarted(false)
        }

        if (updateStarted) runUpdate()
    }, [updateStarted])
useEffect(()=>{
    setCanExport(false);
    setSelectedRows([]);
},[searchCriteria])
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
          <div className='container-fluid'>
              <div className='row'>
                  <ContentBox className='col-md-3'>
                      <ClientSearchInput
                        marginTop={9}
                        paddingTop={8}
                        paddingBottom={8}
                        onClientSelected={handleClientSelected}
                        onCleared={handleClientCleared}
                      />
                  </ContentBox>
                  <ContentBox className='col-md-3'>
                      <TextField
                        name='reviewTitle'
                        className={classes.textField}
                        label='Title'
                        onChange={handleValueChange}
                        margin='dense'
                        variant='outlined'
                      />
                  </ContentBox>
                  <ContentBox className='col-md-6 pl-5' style={{ marginTop: 9 }}>
                      <DatePicker
                        label='Posted on'
                        value={searchCriteria.startDate}
                        onChange={handlePostedStartDateChange}
                        inputFormat='MM/DD/yyyy'
                        renderInput={params => <TextField {...params} />}
                      />
                      <div style={{ display: 'inline', padding: '20px' }}>&nbsp;</div>
                      <DatePicker
                        label='and before'
                        value={endDate}
                        onChange={handlePostedEndDateChange}
                        inputFormat='MM/DD/yyyy'
                        renderInput={params => <TextField {...params} />}
                      />
                  </ContentBox>
              </div>
              <div className='row'>
                  <ContentBox className='col-md-3'>
                      <TextField
                        name='reviewText'
                        className={classes.textField}
                        label='Content'
                        onChange={handleValueChange}
                        margin='dense'
                        variant='outlined'
                      />
                  </ContentBox>
                  <ContentBox className='col-md-3'>
                      <FormControl className={classes.formControl}>
                          <InputLabel htmlFor='review-sites'>Sites</InputLabel>
                          <Select
                            name='reviewSites'
                            labelId='review-sites'
                            id='sites'
                            multiple
                            value={searchCriteria.reviewSites}
                            input={<MaterialInput />}
                            onChange={handleValueChange}
                            renderValue={selected => `${selected.length} Selected`}>
                              {reviewSites.map(site => (
                                <MenuItem key={site.reviewSiteId} value={site.reviewSiteId}>
                                    <StyledCheckbox
                                      name={site.reviewSiteId}
                                      className={classes.checkbox}
                                      checked={searchCriteria.reviewSites.indexOf(site.reviewSiteId) > -1}
                                    />
                                    <ListItemText primary={site.displayName} />
                                </MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                      <div style={{ display: 'inline', padding: '20px' }}>&nbsp;</div>
                      <FormControl className={classes.formControl}>
                          <InputLabel htmlFor='review-sources'>Sources</InputLabel>
                          <Select
                            name='reviewSources'
                            labelId='review-sources'
                            id='sites'
                            multiple
                            value={searchCriteria.reviewSources}
                            input={<MaterialInput />}
                            onChange={handleValueChange}
                            renderValue={selected => `${selected.length} Selected`}>
                              {reviewSources.map(item => (
                                <MenuItem key={item} value={item}>
                                    <StyledCheckbox
                                      name={item}
                                      className={classes.checkbox}
                                      checked={searchCriteria.reviewSources.indexOf(item) > -1}
                                    />
                                    <ListItemText primary={item} />
                                </MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                  </ContentBox>
                  <ContentBox className='col-md-6 pl-5'>
                      <DatePicker
                        label='Collected on'
                        value={searchCriteria.ingestionStartDate}
                        onChange={handleCollectedStartDateChange}
                        inputFormat='MM/DD/yyyy'
                        renderInput={params => <TextField {...params} />}
                      />

                      <div style={{ display: 'inline', padding: '20px' }}>&nbsp;</div>
                      <DatePicker
                        label='and before'
                        value={ingestionEndDate}
                        onChange={handleCollectedEndDateChange}
                        inputFormat='MM/DD/yyyy'
                        renderInput={params => <TextField {...params} />}
                      />
                  </ContentBox>
              </div>
              <div className='row'>
                  <ContentBox className='col-md-3'>
                      <FormControl className={classes.formControl}>
                          <InputLabel htmlFor='review-status'>Status</InputLabel>
                          <Select
                            name='statuses'
                            labelId='review-status'
                            id='status'
                            multiple
                            value={searchCriteria.statuses}
                            input={<MaterialInput />}
                            onChange={handleValueChange}
                            renderValue={selected => `${selected.length} Selected`}>
                              {reviewStatus.map(status => (
                                <MenuItem key={status} value={status}>
                                    <StyledCheckbox
                                      name={status}
                                      className={classes.checkbox}
                                      checked={searchCriteria.statuses.indexOf(status) > -1}
                                    />
                                    <ListItemText primary={status} />
                                </MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                      <div style={{ display: 'inline', padding: '20px' }}>&nbsp;</div>
                      <FormControl className={classes.formControl}>
                          <InputLabel htmlFor='review-severity'>Severity</InputLabel>
                          <Select
                              name='severities'
                              labelId='review-severity'
                              id='severity'
                              multiple
                              value={searchCriteria.severities}
                              input={<MaterialInput />}
                              onChange={handleValueChange}
                              renderValue={selected => `${selected.length} Selected`}>
                              {reviewSeverites.map(severity => (
                                  <MenuItem key={severity} value={severity}>
                                      <StyledCheckbox
                                          name={severity}
                                          className={classes.checkbox}
                                          checked={searchCriteria.severities.indexOf(severity) > -1}
                                      />
                                      <ListItemText primary={severity} />
                                  </MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                  </ContentBox>
                  <ContentBox className='col-md-3'>
                      <FormControl className={classes.formControl}>
                          <InputLabel htmlFor='review-sentiments'>Sentiments</InputLabel>
                          <Select
                            name='sentiments'
                            labelId='review-sentiments'
                            id='sentiments'
                            multiple
                            value={searchCriteria.sentiments}
                            input={<MaterialInput />}
                            onChange={handleValueChange}
                            renderValue={selected => `${selected.length} Selected`}>
                              <MenuItem key='1' value='Positive'>
                                  <StyledCheckbox
                                    name='Positive'
                                    className={classes.checkbox}
                                    checked={searchCriteria.sentiments.indexOf('Positive') > -1}
                                  />
                                  <ListItemText primary='Positive' />
                              </MenuItem>
                              <MenuItem key='2' value='Neutral'>
                                  <StyledCheckbox
                                    name='Neutral'
                                    className={classes.checkbox}
                                    checked={searchCriteria.sentiments.indexOf('Neutral') > -1}
                                  />
                                  <ListItemText primary='Neutral' />
                              </MenuItem>
                              <MenuItem key='3' value='Negative'>
                                  <StyledCheckbox
                                    name='Negative'
                                    className={classes.checkbox}
                                    checked={searchCriteria.sentiments.indexOf('Negative') > -1}
                                  />
                                  <ListItemText primary='Negative' />
                              </MenuItem>
                          </Select>
                      </FormControl>
                      <div style={{ display: 'inline', padding: '20px' }}>&nbsp;</div>
                      <FormControl className={classes.formControl}>
                          <InputLabel htmlFor='tags'>Tags</InputLabel>
                          <Select
                            name='tags'
                            labelId='tags'
                            id='tags'
                            multiple
                            value={searchCriteria.tags}
                            input={<MaterialInput />}
                            onChange={handleValueChange}
                            renderValue={selected => `${selected.length} Selected`}>
                              {tags.map(tag => (
                                <MenuItem key={tag.tagId} value={tag.tagId}>
                                    <StyledCheckbox
                                      name={tags.displayName}
                                      className={classes.checkbox}
                                      checked={searchCriteria.tags.indexOf(tag.tagId) > -1}
                                    />
                                    <ListItemText primary={tag.displayName} />
                                </MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                  </ContentBox>
                  <ContentBox className='col-md-6 pl-5'>
                      <FormControl className={classes.formControl}>
                          <InputLabel htmlFor='limit-label'>Limit</InputLabel>
                          <Select
                              name='count'
                              labelId='limit-label'
                              id='limit'
                              value={searchCriteria.count}
                              input={<MaterialInput />}
                              onChange={handleCountChange}>
                              <MenuItem value='10'>10</MenuItem>
                              <MenuItem value='25'>25</MenuItem>
                              <MenuItem value='50'>50</MenuItem>
                              <MenuItem value='100'>100</MenuItem>
                              <MenuItem value='500'>500</MenuItem>
                              <MenuItem value='1000'>1000</MenuItem>
                          </Select>
                      </FormControl>
                      <div style={{ display: 'inline', padding: '8px' }}>&nbsp;</div>
                      <Button
                          disableElevation
                          className={classes.button}
                          variant='contained'
                          color='secondary'
                          disabled={reOpenAllRows.length === 0}
                          onClick={handleOpenAllClick}>
                          Open All
                      </Button>
                      <div style={{ display: 'inline', padding: '8px' }}>&nbsp;</div>
                      <Button
                          disableElevation
                          className={`${classes.button}`}
                          variant='contained'
                          color='secondary'
                          disabled={closeAllRows.length === 0}
                          onClick={handleCloseAllClick}>
                          Close All
                      </Button>
                      <div style={{ display: 'inline', padding: '8px' }}>&nbsp;</div>
                      <Button
                          disableElevation
                          className={`${classes.button}`}
                          variant='contained'
                          color='success'
                          disabled={fetchExcelStarted || !canExport}
                          onClick={handleExportClick}>
                          <div style={{display:'flex',alignItems:'center'}}>
                              {(fetchExcelStarted) ? <><span>Exporting...</span> <CircularProgress className={'ml-2'} style={{width:16,height:16}} /></>:(selectedRows.length === 0) ? <span>Export All</span>:<span>Export Selected</span>}
                          </div>
                      </Button>
                      <div style={{ display: 'inline', padding: '10px' }}>&nbsp;</div>
                      <Button
                        disableElevation
                        className={classes.button}
                        variant='contained'
                        color='primary'
                        onClick={handleSearchClick}>
                          Search
                      </Button>
                  </ContentBox>
              </div>
          </div>
          <ContentBox style={{ padding: '30px 10px 10px 10px' }}>
              <div style={{ height: '7px', paddingTop: '2px' }}>
                  {(fetchStarted || updateStarted) && <LinearProgress />}
              </div>
              <ReviewsTableView
                reviews={reviews}
                orderBy={sortOrder.orderBy}
                descending={sortOrder.descending}
                page={searchCriteria.page - 1}
                count={totalReviews}
                rows={searchCriteria.count}
                onPageChange={handlePageChange}
                onRowCountChange={handleRowCountChange}
                onRowSelected={handleRowSelected}
                onRowSelectedClick={handleRowSelectedClick}
                onHeaderClick={handleHeaderClick}
              />
          </ContentBox>
          <SimpleModal
            title={modal.title}
            open={modal.showModal}
            onClick={() => setModal({ ...modal, showModal: false })}>
              <FlexContainer>
                  <BodyText>{modal.content}</BodyText>
              </FlexContainer>
              <FlexContainer className='pt-4'>
                  <div className='col-md-7'></div>
                  <Button className='col-md-2' variant='outlined' onClick={() => setModal({ showModal: false })}>
                      No
                  </Button>
                  <Button className='col-md-2' color='primary' variant='outlined' onClick={modal.handleModalClick}>
                      Yes
                  </Button>
              </FlexContainer>
          </SimpleModal>
          <ReviewsConfirmExport open={excelExportConfirmationOpen} totalReviews={(selectedRows && selectedRows.length>0)?selectedRows.length:totalReviews} onConfirm={handleConfirmExport} onClose={handleCloseExportConfirmation}/>
      </LocalizationProvider>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        addNotification: notification => {
            dispatch(addNotificationAction(notification.text, notification.type))
        },
    }
}

export default connect(null, mapDispatchToProps)(ReviewsManagerWorkspace)
