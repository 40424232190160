import { createAction } from 'redux-actions'

export const ActionTypes = {
  NOTIFICATION_ADD_NOTIFICATION: 'NOTIFICATION_ADD_NOTIFICATION',
  NOTIFICATION_DISMISS_NOTIFICATION: 'NOTIFICATION_DISMISS_NOTIFICATION',
}

export const NotificationType = {
  SUCCESS_NOTIFICATION: 'success',
  ERROR_NOTIFICATION: 'error',
  WARNING_NOTIFICATION: 'warning',
}

export const dismissNotificationAction = createAction(ActionTypes.NOTIFICATION_DISMISS_NOTIFICATION)

export const addNotificationAction = createAction(
  ActionTypes.NOTIFICATION_ADD_NOTIFICATION,
  (message, type) => ({ message, type })
)
