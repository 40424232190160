import styled from 'styled-components'

export const QueueCardView = styled.div`
  background: ${props => (props.active ? props.theme.primary.main : props.theme.background.main)};
  color: ${props => props.theme.text.primary};
  cursor: pointer;
  min-height: 110px;
  width: 100%;
  padding: 10px;
  margin-bottom: 4px;
  border-right: 7px solid ${props => props.statusColor || props.theme.background.main};
  h6 {
    margin: 5px 0 0 0;
    font-weight: 300;
    span {
      font-size: 18px;
      font-weight: 400;
    }
  }
  .social-src {
    float: right;
    color: ${props => (props.active ? '#FFF' : props.theme.text.main)};
    position: relative;
    font-size: 13px;
    font-weight: 500;
    margin-right: 5px;
  }
  &:hover {
    background: ${props =>
      props.theme.type !== 'dark' ? props.theme.primary.lightest : props.theme.background.dark};
  }
`

export const QueueClientName = styled.h4`
  color: ${props => (props.active ? '#FFF' : props.theme.text.main)};
  font-size: 15px;
  font-weight: 400;
  margin-top: 7px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const QueueStatus = styled.div`
  display: inline-block;
  color: #fff;
  text-align: center;
  background-color: ${props => props.theme[props.statusColor[0]][props.statusColor[1]]};
  font-size: 12px;
  width: 130px;
  padding: 1px;
`

export const QueueTime = styled.span`
  font-size: 13px;
  font-weight: ${props => (props.bold ? '700' : '400')};
  color: ${props =>
    props.bold ? props.theme.error.dark : props.active ? '#FFF' : props.theme.text.secondary};
  float: right;
`
