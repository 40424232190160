import React, { useEffect, useState } from 'react'
import PageProgressBar from '../common/ProgressPageLoader'
import FlexContainer from "../common/FlexContainer";
import exportAnimation from "../../assets/anims/exporting.json";
import successAnimation from "../../assets/anims/success.json";
import Lottie from "lottie-react";
import RadarServiceFactory, { RadarServices } from '../../services'
import { useParams } from 'react-router';

export default function ExportView(props) {
    const [isReady,setIsReady] = useState(false)
    let {id,type} = useParams();
    useEffect(() => {
        let service = RadarServiceFactory.create(RadarServices.Reviews)
        if (type === "accounts") service = RadarServiceFactory.create(RadarServices.Accounts)
        const interval = setInterval(() => {
            service.exportToExcelResult(id).then(res=>{
                console.log(res)
                if (res.Content == true){
                    clearInterval(interval);
                    service.downloadExcelResult(id)
                    setIsReady(true)
                }
            });
        }, 5000);
        return () => clearInterval(interval);
    }, []);
    return (
      <FlexContainer justifyContent='stretch'>
          {!isReady ? <><PageProgressBar />
                <div className="d-flex align-content-center align-items-center w-100 justify-content-center">
                    <Lottie animationData={exportAnimation} loop={true} />
                </div>
            </>:
            <div className="d-flex align-content-center align-items-center vw-100 vh-100 h justify-content-center">
                <Lottie animationData={successAnimation} loop={true} />
            </div>
          }
      </FlexContainer>
    )
}