import React from 'react'
import { connect } from 'react-redux'
import { BsMoon } from 'react-icons/bs'
import Switch from '@mui/material/Switch'
import { grey } from '@mui/material/colors'
import { withStyles } from '@mui/styles'
import { userModeChangeAction } from '../../store/actions/user'

const LightGraySwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      color: '#efefef',
      transform: 'translateX(16px)',
    },
    '&$checked + $track': {
      backgroundColor: grey[400],
    },
  },
  thumb: {
    width: 24,
    height: 24,
    border: `1px solid ${
      theme.palette.type === 'dark' ? theme.palette.primary.lightest : theme.palette.background.main
    }`,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
  },
  checked: {},
}))(Switch)

function ModeSelector({ normalMode, notifyModeChange }) {
  const handleModeChange = e => {
    notifyModeChange(e.target.checked)
    localStorage.setItem('normalMode', e.target.checked)
  }

  React.useEffect(() => {
    let mode = localStorage.getItem('normalMode')
    if (mode) {
      notifyModeChange(mode === 'true' ? true : false)
    }
  }, [])

  return (
    <>
      <BsMoon className={!normalMode ? 'text-white' : ''} />
      <LightGraySwitch checked={normalMode} onChange={handleModeChange} />
    </>
  )
}

function mapStateToProps(state) {
  return {
    normalMode: state.user.normalMode,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    notifyModeChange: normalMode => dispatch(userModeChangeAction(normalMode)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModeSelector)
