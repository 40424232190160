import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import promiseMiddleware from 'redux-promise'
import internalReducer from './reducers'
import RadarRedux from '../lib/redux'
const { logger } = require('redux-logger')

// Manages state for our app so we don't loose it across reloads
const radarState = new RadarRedux()
const radarStateMiddleware = store => next => action => {
  let result = next(action),
    state = store.getState()
  radarState.store(state.user.loggedIn ? state : {})
  return state.user.loggedIn ? result : {}
}

export default function configureStore(history) {
  const middleware = [thunk, promiseMiddleware, radarStateMiddleware, routerMiddleware(history)]
  const enhancers = []

  if (window.RADAR_ENV) {
    middleware.push(logger)
  }

  return createStore(
    internalReducer(history),
    radarState.state,
    compose(applyMiddleware(...middleware), ...enhancers)
  )
}
