import RadarAuth from './auth'

const StorageKeys = {
  ExpireTime: 'expiresAt',
  AuthSessionData: 'sessionState',
}

export default class RadarSession {
  authService
  session

  constructor() {
    this.session = JSON.parse(localStorage.getItem(StorageKeys.AuthSessionData))
    if (this.session && this.session.accessToken) {
      // Sneeking this into the global scope to peform
      // some behind the scene magic
      window.AccessToken = this.session.accessToken
    }
    this.authService = new RadarAuth()
  }

  setSessionState(state) {
    this.session = state
    localStorage.setItem(StorageKeys.AuthSessionData, JSON.stringify(state))

    let pollTime = new Date()
    pollTime.setMinutes(pollTime.getMinutes() + 15)
    localStorage.setItem(StorageKeys.ExpireTime, pollTime.getTime())
  }

  getSessionState() {
    if (!this.session) return null

    return {
      profile: this.session.profile,
      accessToken: this.session.accessToken,
    }
  }

  isLoggedIn() {
    if (this.session) {
      let currentTime = new Date().getTime()
      let exprTime = parseInt(localStorage.getItem(StorageKeys.ExpireTime) || currentTime)
      return currentTime < exprTime
    }

    return false
  }

  async checkSession() {
    if (!this.isLoggedIn()) this.logout()
    else {
      let result = await this.authService.checkSession()
      if (!result.Ok) {
        this.logout()
      }
    }
  }

  renewSession() {
    if (this.session) {
      let pollTime = new Date()
      pollTime.setMinutes(pollTime.getMinutes() + 15)
      localStorage.setItem(StorageKeys.ExpireTime, pollTime.getTime())
    }
  }

  logout() {
    this.session = null

    document.cookie = 'samesite; path=/;'
    localStorage.clear()
    window.AccessToken = null

    this.authService.signOut()
  }
}
