import React from 'react'
import styled from 'styled-components'

const Text = styled.p`
  font-style: ${props => (props.fontStyle ? props.fontStyle : 'normal')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : '2')};
  color: ${props => (props.color ? props.theme[props.color].main : props.theme.text.primary)};
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  margin: ${props => (props.margin ? props.margin : '0px 10px 10px 0px')};
  padding: ${props => (props.padding ? props.padding : '0px')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  ${props => (props.flex ? `flex: ${props.flex};` : null)};
  ${props => (props.border ? props.border : '')};
  ${props => props.override || ''};
`

const BodyText = props => {
  return <Text {...props}>{props.children}</Text>
}

export default BodyText
