import React from 'react'
import { styled } from '@mui/styles'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import { Banner, Errors, Toolbar } from './index.S'

const Display = styled(Paper)({
  justifyContent: 'center',
  position: 'absolute',
  left: '25%',
  top: '5%',
  backgroundColor: '#100',
  border: '1px solid #000',
  borderRadius: '10px',
  width: '800px',
  '& p': {
    color: '#FFF',
    marginTop: '10px',
    marginLeft: '10px',
  },
})

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      errorInfo: '',
      hasError: false,
      open: false,
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleCopyToClipboard = this.handleCopyToClipboard.bind(this)
  }

  handleClose = () => {
    this.setState({ open: false })
    window.location = '/logout'
  }

  handleCopyToClipboard = () => {
    navigator.clipboard.writeText(this.state.errorInfo.componentStack)
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ open: true, error, errorInfo })
  }

  render() {
    let { hasError, errorInfo, error } = this.state
    return hasError ? (
      <Modal open={this.state.open}>
        <Display>
          <Banner>
            <Typography variant='h5'>Runtime Error</Typography>
          </Banner>
          <p>Error: {error.message}</p>
          <Errors>{errorInfo.componentStack}</Errors>
          <Toolbar>
            <Button
              variant='outlined'
              startIcon={<FileCopyOutlinedIcon />}
              onClick={this.handleCopyToClipboard}>
              Copy
            </Button>
            <Button disableElevation variant='outlined' onClick={this.handleClose}>
              Logout
            </Button>
          </Toolbar>
        </Display>
      </Modal>
    ) : (
      this.props.children
    )
  }
}
