import styled from 'styled-components'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(theme => ({
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    paddingBottom: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.5),
    fontWeight: 500,
  },
  chipColor: {
    backgroundColor: `${theme.palette.error.main} !important`,
    color: '#FFF',
  },
  avatar: {
    color: '#FFF !important',
    marginLeft: '5px',
    height: '12px',
  },
}))

export const Container = styled.div`
  margin: 0px 36px;
`

export const Subheader = styled.p`
  color: ${props => props.theme.text.main};
  font-size: 13px;
  text-align: left;
`

export const ReviewTitle = styled.h5`
  font-size: 16px;
  font-weight: 500;
`

export const ReviewContent = styled.div`
  position: relative;
  font-size: 14px;
  margin-top: 10px;
  background-color: ${props =>
    props.theme.type === 'dark' ? props.theme.secondary.main + '41' : 'rgba(0,150,219,0.8)'};
  border-radius: 1rem;
  padding: 1.125em 1.5em;
  margin-bottom: 10px;
  width: fit-content;

  ::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 1.5em;
    border: 0.75rem solid transparent;
    border-top: none;
    border-bottom-color: ${props =>
      props.theme.type === 'dark' ? props.theme.secondary.main + '41' : 'rgba(0,150,219,0.8)'};
  }
`

export const ReviewText = styled.div`
  max-height: 175px;
  overflow-y: auto;
  color: #fff;
  font-size: 16px;

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: ${props =>
      props.theme.type === 'dark' ? props.theme.secondary.main + '41' : 'rgba(0,150,219,0.8)'};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #fff;
  }
`

export const NotificationBar = styled.div`
  background-color: ${props => props.theme.secondary.lightest};
  height: auto;
  padding: 4px 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  p {
    margin: 0;
    text-align: center;
    font-size: 12px;
    color: ${props => props.theme.foreground.light};
  }
`

export const EmptyStar = styled.span`
  height: 6px;
  width: 6px;
  background-color: ${props => props.theme.warning.main};
  border-radius: 50%;
  display: inline-block;
  margin: 10px 11px;
`

export const ResolutionContainer = styled.div`
  width: 100%;
  margin: 12px 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: all 0.3s;
  ${props => (props.disabled ? 'opacity: 0.3; pointer-events: none; user-select: none;' : '')}
`

export const Editor = styled.div`
  .ql-container {
    font-size: 16px !important;
    border-radius: 10px;
    border: 1px solid transparent !important;
    background-color: ${props => props.theme.background.dark + '8F'};
    color: ${props => props.theme.text.primary};
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
    &:active,
    &:focus {
      outline: 1px solid ${props => props.theme.secondary.main};
    }
  }
  .ql-editor {
    padding:30px 15px;
  }
  .ql-blank::before {
    color: ${props => props.theme.text.primary} !important;
  }
`

export const Toolbar = styled.div`
  display: flex;
  height: auto;
  margin-top: 20px;
  border-radius: 10px;
  align-items: center;
  background-color: ${props => props.theme.background.dark + '8F'};
`
