import React, { useState } from 'react'
import { connect } from 'react-redux'
import Typography from '@mui/material/Typography'
import AccountSearch from './SettingsNavbarComponents/SettingsAccountSearch'
import Translations from '../../translations/v2/TranslationsV2.json'
import { Nav, Tabs, TabItem } from './SettingsNavBar.S'
import { settingsTabChangedAction } from '../../store/actions/settings'

function SettingsNavBar(props) {
  const { tabChanged, activeSection } = props
  const [active, setActive] = useState(activeSection)
  const [hover, setHover] = useState(-1)
  const accountNdx = Translations.settingsNav.findIndex(tab => tab.name === 'accounts')

  const handleTabClick = index => {
    setActive(index)
    tabChanged(index)
  }

  const handleAccountSearchClick = () => {
    setActive(accountNdx)
    tabChanged(accountNdx)
  }

  return (
    <>
      <Nav className='navbar-expand-lg col-md-12'>
        <Tabs className='navbar-nav mr-auto '>
          {Translations.settingsNav.map((tab, index) => (
            <TabItem
              key={index}
              className={`navitem mr-5 ${
                index === active
                  ? 'active das-border-bottom-active'
                  : `${hover === index ? 'das-border-bottom-hover' : ''}`
              }`}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(-1)}
              onClick={() => handleTabClick(index)}>
              <Typography color='textPrimary'>{tab.displayName}</Typography>
            </TabItem>
          ))}
        </Tabs>
      </Nav>
      {activeSection === accountNdx ? (
        <AccountSearch onAccountSearchClick={handleAccountSearchClick} />
      ) : null}
    </>
  )
}

function mapStateToProps(state) {
  return {
    activeSection: state.settings.activeTabId,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    tabChanged: tabId => dispatch(settingsTabChangedAction(tabId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsNavBar)
