import styled from 'styled-components'

import Title from '../common/Title'
import Button from '../common/Button'
import Input from '../common/Input'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 0px 20px;
  -webkit-box-align: center;

  .mainContent {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 0px auto;
  }

  label {
    margin-right: 50px;
    font-size: 0.98em;
    margin-top: 3px;
  }

  > .templateContent {
    color: rgb(144, 141, 138);
    border-top: solid 1px rgb(222, 222, 222);
    padding-top: 10px;
  }
`

export const TitleBox = styled.div`
  display: inline-block;
  padding: 5px 0px;

  > p {
    color: rgb(144, 141, 138);
    font-size: 14px;
    line-height: 2;
    margin-bottom: 8px;
  }

  > .sentiment {
    margin-left: 15px;
  }
  > .sentiment.Positive {
    color: rgb(26, 223, 117);
  }
  > .sentiment.Negative {
    color: rgb(236, 96, 96);
  }
  > .sentiment.Neutral {
    color: rgb(253, 211, 64);
  }
`

export const Header = styled(Title)`
  font-size: 1.4em;
  padding-bottom: 10px;
`

export const SearchContainer = styled.div`
  display: flex;
  padding-top: 20px;
  margin: 0 auto;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  max-width: 1245px;
`

export const DisabledSearchContainer = styled.div`
  width: 100%;
  opacity: 0.25;
  pointer-events: none;
`

export const SearchInputSpan = styled.span`
  position: absolute;
  top: ${props => (props.top ? props.top : '15px')};
  right: 697px;
  z-index: 1;
  padding: 4px 18px;
`

export const ItemContainer = styled.div`
  height: calc(100vh - 355px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-top: 5px;
  scrollbar-width: thin;
`

export const ListContainer = styled.div`
  display: flex;
  padding-top: 20px;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1245px;
  width: 100%;
`

export const LoadingContainer = styled.div`
  display: flex;
  padding-top: 20px;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1245px;
`

export const FilterButton = styled(Button)`
  &:hover {
    color: #fe9143;
  }
  &.disabled,
  &:disabled {
    background: #fff;
    color: #ffb47f;
  }
`

export const SearchInput = styled(Input)`
  &:disabled,
  &.disabled {
    opacity: 0.5;
  }
`
