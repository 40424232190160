import React, { useState, useEffect } from 'react'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import { FilterCard, FilterCardHeadar, useCardStyles } from './Card'
import ProgressLoader from '../ProgressLoader'

export const createGenericItem = (id, displayName) => ({ id, displayName })

export default function ({ title, selected, onFilterChanged, onInit }) {
  const [fetchStarted, setFetchStarted] = useState(false)
  const [values, setValues] = useState([])
  const cardClasses = useCardStyles()

  const handleChipClick = e => {
    let val = values.find(a => a.id === e.currentTarget.dataset['id'])
    val.selected = !val.selected
    onFilterChanged({ filters: values.filter(a => a.selected).map(a => a) })
  }

  const handleSelectClearAllClick = () => {
    values.forEach(a => (a.selected = selected.length > 0 ? false : true))
    onFilterChanged({ filters: values.filter(a => a.selected).map(a => a) })
  }

  useEffect(() => {
    setFetchStarted(true)
    let vals = onInit()
    if (vals.then)
      vals.then(results => {
        setValues(results)
        setFetchStarted(false)
      })
    else {
      setValues(vals)
      setFetchStarted(false)
    }
  }, [])

  return (
    <FilterCard>
      <FilterCardHeadar
        title={title}
        selectAll={selected.length == 0}
        onActionClick={handleSelectClearAllClick}
      />
      <CardContent style={{ padding: 0, margin: 0 }}>
        {fetchStarted ? (
          <ProgressLoader />
        ) : (
          <div className='d-flex flex-wrap pt-1 pb-3'>
            {values.map(val => {
              val.selected = selected.findIndex(a => a.id === val.id) > -1
              return (
                <Chip
                  data-id={val.id}
                  key={val.id}
                  className={cardClasses.chip}
                  size='small'
                  variant={val.selected ? 'default' : 'outlined'}
                  label={val.displayName}
                  color='primary'
                  onClick={handleChipClick}
                />
              )
            })}
          </div>
        )}
      </CardContent>
    </FilterCard>
  )
}
