import React from 'react'
import ReactQuill from 'react-quill'

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
}

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
]

export default function ({ className, placeholder, body, onChange, children }) {
  return (
    <ReactQuill
      id='editor'
      name='editor'
      className={`${'das-texteditor'} ${className}`}
      placeholder={placeholder}
      value={body}
      theme='snow'
      modules={modules}
      formats={formats}
      onChange={onChange}>
      {children}
    </ReactQuill>
  )
}
