import styled, { keyframes } from 'styled-components'

export const Logo = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 18px;
`

export const MarketingContainer = styled.div`
  width: calc(100% / 3 * 2);
  margin-right: -10px;
  height: 100vh;
  background-image: url('https://dasprod.blob.core.windows.net/mcv1marketingloginscreeassets/image');
`

export const LogInContainer = styled.div`
  width: calc(100% / 3 * 1);
  padding: 0 8px;
  background-color: #fff;
  height: 100vh;

  div {
    margin-top: 100px;
  }

  p {
    margin-top: 10px;
    width: 100%;
    color: #4c4742;
    font-size: 0.85rem;
    line-height: 1.25rem;
  }
`

export const LogInButton = styled.div`
  padding-top: 15px;
  width: 100%;
  height: 55px;
  text-align: center;
  background-color: #ef7e2d;
  color: #ffffff !important;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  margin: 0 auto !important;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s linear;
  &:hover {
    background-color: #dd6000;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'space-between')};
  width: ${props => (props.width ? props.width : '100%')};
  margin: ${props => (props.margin ? props.margin : '0px')};
  padding: ${props => (props.padding ? props.padding : '0px')};
  ${props => (props.height ? `height: ${props.height};` : null)}
  ${props => (props.addProps ? props.addProps : null)}
`

const mnmlSpinner = keyframes`
    from {
      transform: rotate(0)
    }

    to {
      transform: rotate(359.9deg)
    }
`

export const Spinner = styled.span`
  display: inline-block;
  border-radius: 100%;
  border-color: rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
  border-style: solid;
  border-width: 2px;
  height: 15px;
  width: 15px;
  animation: ${mnmlSpinner} 1s infinite linear;
`

export const ErrorMessage = styled.p`
  padding-bottom: 5px;
  height: 40px;
  width: 100%;
  color: #d94b4b !important;
  font-size: 0.85rem;
  font-weight: bold;
`
