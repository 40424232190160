import React, { useState, useEffect, useRef, useContext } from 'react'
import { FaSearch } from 'react-icons/fa'
import { ThemeContext } from 'styled-components'
import FlexContainer from '../../../common/FlexContainer'
import Button from '../../../common/Button'
import FilterTabs from '../FilterTabs'
import SimpleModal from '../../../common/SimpleModal'
import * as ModalStyles from './FilterModal.S'
import ProgressLoader from '../../../common/ProgressLoader'

function FilterModal(props) {
  const {
    id,
    searchText,
    templates,
    fetchStarted,
    noResultsText,
    onClose,
    onTemplateItemSelected,
    showParents,
    show,
  } = props

  const parentTabs = ['All', 'Sales', 'Service', 'Strategic']
  const tabs = ['All', 'Positive', 'Neutral', 'Negative']
  const [filteredTemplates, setFilteredTemplates] = useState(templates)
  const [searchfilter, setSearchFilter] = useState({parent:'All', child:'All'})
  const [searchCriteria, setSearchCriteria] = useState(null)
  const searchTimerRef = useRef(0)
  const theme = useContext(ThemeContext)
  
  const handleFilterChanged = filter => {
    setSearchFilter({...searchfilter,child:filter});
  }

  const handleParentFilterChanged = filter => {
    setSearchFilter({...searchfilter,parent:filter});
  }

  const handleSearchCanged = text => {
    clearTimeout(searchTimerRef.current)
    if (text.length > 1) {
      let query = new RegExp(text, 'i')
      searchTimerRef.current = setTimeout(() => {
        setSearchCriteria(query)
      }, 500)
    } else {
      setSearchCriteria(null)
    }
  }

  const handleFilterItemSelected = (text,id) => {
    onTemplateItemSelected(text,id)
    onClose()
  }

  useEffect(() => {
    setFilteredTemplates(templates)
  }, [templates])

  return (
    <SimpleModal id={id} open={show}>
      <ModalStyles.SearchInputContainer>
        <FaSearch />
        <ModalStyles.SearchInput
          className='rounded'
          value={searchText}
          placeholder='Search templates...'
          onChange={e => handleSearchCanged(e.target.value)}
        />
      </ModalStyles.SearchInputContainer>
      {showParents && <>
        <FilterTabs tabs={parentTabs} activeTab='All' onFilterChanged={handleParentFilterChanged} />
        <div className={'mt-2'}></div>
      </>
      }
      <FilterTabs tabs={tabs} activeTab='All' onFilterChanged={handleFilterChanged} />
      <ModalStyles.FilterContainer
        className={theme.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}>
        {fetchStarted && <ProgressLoader />}
        {!filteredTemplates ? (
          <div>{noResultsText}</div>
        ) : (
          filteredTemplates
            .filter(item => {
              if (searchCriteria) {
                let found = searchCriteria.test(item.title) || searchCriteria.test(item.content)
                if (!found) return false
              }
              let filtered = true;
              if (searchfilter.parent !== 'All'){
                filtered = item.types?.includes(searchfilter.parent);
                if (filtered && searchfilter.child !== 'All'){
                  filtered = item.sentiments?.includes(searchfilter.child);
                }
              }
              else{
                if (filtered && searchfilter.child !== 'All'){
                  filtered = item.sentiments?.includes(searchfilter.child);
                }
              }
              return filtered;
            })
            .map(item => (
              <ModalStyles.FilterItem
                key={item.templateId}
                style={{ display: fetchStarted ? 'none' : 'block' }}
                onClick={() => handleFilterItemSelected(item.content,item.id)}>
                <header>
                  <FlexContainer justifyContent='flex-start'>
                    <h5>{item.title}</h5>
                    {item.types?.map(type => (
                        <ModalStyles.Tag key={type} sentiment={type}>
                          {type}
                        </ModalStyles.Tag>
                    ))}
                    {item.sentiments.map(sentiment => (
                      <ModalStyles.Tag key={sentiment} sentiment={sentiment}>
                        {sentiment}
                      </ModalStyles.Tag>
                    ))}
                  </FlexContainer>
                </header>
                <p className='card-list-card-text' dangerouslySetInnerHTML={{ __html: item.content }}></p>
              </ModalStyles.FilterItem>
            ))
        )}
      </ModalStyles.FilterContainer>
      <FlexContainer>
        <div className='col-md-10'></div>
        <Button className='col-md-2' variant='main' onClick={onClose}>
          Close
        </Button>
      </FlexContainer>
    </SimpleModal>
  )
}

export default FilterModal
