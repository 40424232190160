import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: ${props => (props.open ? 'block' : 'none')};
  z-index: 500;
`

export const Main = styled.section`
  position: fixed;
  background: ${props => props.theme.background.main};
  width: 48%;
  max-width: 692px;
  height: auto;
  top: 50%;
  left: 50%;
  padding: 30px 32px;
  transform: translate(-50%, -50%);
  border-radius: 5px;

  p:last-of-type {
    margin-bottom: 0;
  }
`

export const Title = styled.h3`
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 28px;
  margin: 0 0 16px;
  text-align: left;
  color: ${props => props.theme.text.primary};
`
