// eslint-disable-next-line no-use-before-define
import React, { useRef } from 'react'
import AsyncSelect from 'react-select/async'
import { BsSearch } from 'react-icons/bs'
import { components } from 'react-select'
import { useTheme } from '@mui/styles'
import RadarServiceFactory, { RadarServices } from '../../../services'
import { SearchIndicator, MenuItem } from './index.S'

export default function ClientSearchInput(props) {
  const {
    marginTop,
    marginLeft,
    marginRight,
    marginBottom,
    paddingTop,
    paddingLeft,
    paddingBottom,
    paddingRight,
    width,
    height,
    dontShowDisables,
    onClientSelected,
    onCleared,
  } = props

  const timer = useRef(0)
  const theme = useTheme()

  const reactSelectStyles = {
    control: provided => ({
      ...provided,
      width: width,
      height: height ?? 'auto',
      marginLeft: marginLeft,
      marginRight: marginRight,
      marginTop: marginTop,
      marginBottom: marginBottom,
      paddingLeft: paddingLeft,
      paddingTop: paddingTop,
      paddingRight: paddingRight,
      paddingBottom: paddingBottom,
      fontWeight: 400,
      backgroundColor: theme.palette.background.main,
      border: `solid 1px ${
        theme.palette.type === 'dark' ? 'rgba(255,255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)'
      }`,
    }),
    menu: base => ({
      ...base,
      minWidth: width,
      zIndex: 10,
      backgroundColor: theme.palette.background.main,
    }),
    option: styles => ({
      ...styles,
      backgroundColor: theme.palette.background.main,
      ':hover': {
        backgroundColor: theme.palette.action.hover,
      },
    }),
    input: provider => ({
      ...provider,
      color: theme.palette.text.primary,
    }),
    singleValue: provider => ({
      ...provider,
      color: theme.palette.text.primary,
    }),
  }

  const searchForAccounts = async (query, resolve) => {
    if (!query || query.length === 0) return

    let timeoutVal = timer.current,
      accounts = [],
      service = RadarServiceFactory.create(RadarServices.Accounts)

    if (timeoutVal !== timer.current || timer.current === -1) {
      console.log(`timeoutVal : ${timeoutVal}, timer : ${timer.current}`)
      return
    }

    let guid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi
      let result = await service.getAccounts(guid.test(query) ? { clientId: query, dontShowDisables: dontShowDisables ? true : false } : { clientName: query, dontShowDisables: dontShowDisables ? true : false })
    if (result.Ok) {
        if (result.Content)
            if (dontShowDisables) {
                result.Content = result.Content.filter(x => x.enabled === true)
            }
          accounts = result.Content.map(acc => ({
          label: acc.clientName,
          value: {
            id: acc.clientId,
            name: acc.clientName,
            enabled: acc.enabled,
          },
        }))
    }

    if (timeoutVal === timer.current && timer.current !== -1) resolve(accounts)
  }

  const handleLoadAccounts = val => {
    return new Promise(resolve => {
      if (val.length < 2) resolve([])
      clearTimeout(timer.current)
      timer.current = -1
      timer.current = setTimeout(() => searchForAccounts(val, resolve), 1000)
    })
  }

  const handleAccountsChange = (option, actionType) => {
    if (actionType.action === 'select-option') {
      onClientSelected(option.value)
    } else if (actionType.action === 'clear' && onCleared) {
      onCleared()
    }
  }

  const SearchIndicatorContainer = props => (
    <SearchIndicator>
      <BsSearch />
    </SearchIndicator>
  )

  const MenuItemContainer = props => (
    <components.Option {...props}>
      <MenuItem key={props.data.value.id} className='row p-1 hand-cursor no-gutters '>
        <div className='col-lg-12'>
          <span>{props.data.value.name}</span>
        </div>
        <div>
          {props.data.value.enabled ? (
            <span className='text-success'>Enabled</span>
          ) : (
            <span className='text-danger'>Disabled</span>
          )}
        </div>
      </MenuItem>
    </components.Option>
  )

  return (
    <>
      <AsyncSelect
        loadOptions={handleLoadAccounts}
        autoFocus={false}
        isMulti={false}
        styles={reactSelectStyles}
        backspaceRemovesValue={true}
        escapeClearsValue={true}
        components={{
          Option: MenuItemContainer,
          DropdownIndicator: SearchIndicatorContainer,
          IndicatorSeparator: null,
        }}
        isClearable={true}
        placeholder='Search for Clients...'
        noOptionsMessage={() => 'No Clients Found'}
        loadingMessage={() => 'Searching for Clients...'}
        onChange={handleAccountsChange}
      />
    </>
  )
}
