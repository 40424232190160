import React from 'react'
import {
  Container,
  LdsEllipsis,
  EllipsisDiv1,
  EllipsisDiv2,
  EllipsisDiv3,
  EllipsisDiv4,
} from './ProgressLoader.S'

const ProgressLoader = props => (
  <Container {...props}>
    <LdsEllipsis>
      <EllipsisDiv1 />
      <EllipsisDiv2 />
      <EllipsisDiv3 />
      <EllipsisDiv4 />
    </LdsEllipsis>
  </Container>
)

export default ProgressLoader
