import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { dismissNotificationAction } from '../../../store/actions/notifications'

function Notifications({ notification, dismissNotification }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (notification) {
      enqueueSnackbar(notification.message, {
        variant: notification.type,
        action: key => (
          <IconButton onClick={() => closeSnackbar(key)}>
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
        ),
        onExited: () => {
          dismissNotification()
        },
      })
    }
  }, [notification])

  return null
}

const mapStateToProps = state => ({
  notification: state.notifications.notification,
})

const mapDispatchToProps = dispatch => ({
  dismissNotification: () => dispatch(dismissNotificationAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
