import styled from 'styled-components'

export const SearchIndicator = styled.div`
  display: flex;
  color: #a0a5a5;
  padding-right: 10px;
`

export const MenuItem = styled.div`
  border-bottom: solid 1px ${props => props.theme.primary.lightest};
  padding: 5px;
  text-align: left !important;
  span {
    color: ${props => props.theme.foreground.lighter};
    font-size: 15px !important;
  }
`
