import { createAction } from 'redux-actions'

export const ActionTypes = {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_MODE_CHANGED: 'USER_MODE_CHANGED',
}

export const userLoginAction = createAction(ActionTypes.USER_LOGIN, user => user)

export const userLogoutAction = createAction(ActionTypes.USER_LOGOUT)

export const userModeChangeAction = createAction(ActionTypes.USER_MODE_CHANGED, normalMode => normalMode)
