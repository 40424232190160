import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 8px;
  width: 280px;

  a {
    text-decoration: none;

    &:hover,
    &:active {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-color: ${props => props.theme.foreground.main};
    }
  }
`

export const ContactIcon = styled.span`
  border-radius: 25px;

  svg {
    color: ${props => props.theme[props.color].main};
    opacity: 0.5;
    transition: all 0.3s ease;
  }

  &:hover,
  &:active {
    cursor: pointer;
    svg {
      opacity: 1;
    }
  }
`

export const GroupTagSpan = styled.span`
  width: 6px;
  height: 14px;
  right: 20.07px;
  top: calc(50% - 14px / 2 + 4.67px);
  margin: 0px 6px 0px 0px;
  font-style: normal;
  font-weight: normal;
  font-size: ${props => (props.fontSize ? props.fontSize : '12px')};
  line-height: 14px;
  /* identical to box height */

  text-align: center;
`

export const GroupTag = styled.div`
  border-radius: 26px;
  background-color: ${props => props.theme.foreground.lightest};
  color: ${props => props.theme.foreground.dark};
  font-size: 9px;
  line-height: 12px;
  padding: 4px 6px 4px 12px;
  margin: 4px 4px 4px 0;
  font-weight: normal;
  position: relative;
`

export const ItemContainer = styled.div`
  height: calc(100vh - 325px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 680px;
  margin-top: 5px;
`
