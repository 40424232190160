import React, { useEffect, useState } from 'react'
import RadarServiceFactory, { RadarServices } from '../../../services'
import { makeStyles } from '@mui/styles'
import ProgressLoader from '../../common/ProgressLoader'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  avatar: {
    color: '#FFF !important',
  },
}))

export default function Tags({ review }) {
  const classes = useStyles()
  const [tags, setTags] = useState([])
  const [fetchCompleted, setFetchCompleted] = useState(false)

  useEffect(() => {
    async function fetchTags(clientId) {
      const service = RadarServiceFactory.create(RadarServices.Tags),
        retval = await service.getTagsByClientId(clientId)

      setTags(retval.Ok ? retval.Content : [])
      setFetchCompleted(true)
    }

    fetchTags(review.client?.clientId)
  }, [review])

  return (
    <div className='d-flex flex-wrap'>
      <ProgressLoader Hide={fetchCompleted} />
      {tags.map(tag => (
        <Chip
          key={tag.tagId}
          className={classes.chip}
          avatar={<Avatar className={classes.avatar}>{tag.displayName.charAt(0)}</Avatar>}
          label={tag.displayName}
          color='primary'
          variant='outlined'
          size='small'
        />
      ))}
    </div>
  )
}
