import './Contacts.css'
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useTheme } from '@mui/styles'
import Modal from 'react-bootstrap/Modal'
import { FaTimes, FaPhone, FaEnvelope } from 'react-icons/fa'
import { BsPencil } from 'react-icons/bs'
import FlexContainer from '../../common/FlexContainer'
import Input from '../../common/Input'
import Button from '../../common/Button'
import ButtonSpinner from '../../common/ButtonSpinner'
import { ItemContainer, Container, ContactIcon, GroupTagSpan, GroupTag } from './Contacts.S'
import { updateAccountStartedAction, updateAccountCompletedAction } from '../../../store/actions/account'
import { TitleBox, Header, Body, ContentBox, SavingText, Card, IconButton, HrLineBreak } from './Shared.S'
import generateId from '../../../lib/idgenerator'

function Contacts({ account, updateStarted, updatedAccount, saveAccount }) {
  const defaultContact = {}
  const [addNew, setAddNew] = useState(false)
  const [editContact, setEditContact] = useState(defaultContact)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [contacts, setContacts] = useState(account.contacts)
  const containerRef = useRef(null)
  const theme = useTheme()

  const handleAddNewClick = () => {
    setEditContact({
      contactId: generateId(),
      fullname: '',
      jobTitle: '',
      email: '',
      phonenumber: '',
      notificationGroups: [],
    })
    setAddNew(true)
    containerRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const handleCloseClick = editing => {
    if (editing) setEditContact(defaultContact)
    else setAddNew(false)
  }

  const handleRemoveContactClick = () => setShowDeleteModal(true)

  const handleSaveClick = contact => {
    if (!updateStarted && contact.fullname.length > 0) {
      let updates = contacts.filter(a => a.contactId !== contact.contactId)
      saveAccount(account.clientId, {
        contacts: [contact, ...updates],
      })
    }
  }

  const handleDeleteClick = contact => {
    if (!updateStarted) {
      saveAccount(account.clientId, {
        contacts: contacts.filter(a => a.contactId !== contact.contactId),
      })
    }
  }

  const editContactProps = {
    handleCloseClick: handleCloseClick,
    handleRemoveContactClick: handleRemoveContactClick,
    handleSaveClick: handleSaveClick,
  }

  useEffect(() => {
    if (updatedAccount?.succeeded) {
      account.contacts = updatedAccount.content.contacts
      setShowDeleteModal(false)
      setContacts(updatedAccount.content.contacts)
      setEditContact(defaultContact)
      setAddNew(false)
    }
  }, [updatedAccount])

  return (
    <FlexContainer alignItems='left' direction='column'>
      <ContentBox>
        <TitleBox>
          <Header>Account Contacts</Header>
          <Body>Add or remove contacts for the account.</Body>
        </TitleBox>
      </ContentBox>
      <ContentBox className='pt-2'>
        <Button className='btn das-primary-bg text-white' onClick={handleAddNewClick}>
          Add New Contact
        </Button>
      </ContentBox>
      <ItemContainer
        ref={containerRef}
        className={theme.palette.type === 'dark' ? 'das-scroll-style-dark' : 'das-scroll-style'}>
        <div className='pt-4'>
          {addNew ? (
            <EditContact
              {...editContactProps}
              contact={editContact}
              editing={false}
              updateStarted={updateStarted}
            />
          ) : null}
        </div>
        {contacts.map(a => (
          <div key={a.contactId}>
            {a.contactId === editContact.contactId && !addNew ? (
              <EditContact
                {...editContactProps}
                contact={editContact}
                editing={true}
                updateStarted={updateStarted}
              />
            ) : (
              <Contact contact={a} handleEditClick={c => setEditContact(c)} />
            )}
          </div>
        ))}
      </ItemContainer>
      <DeleteContactModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={handleDeleteClick}
        contact={editContact}
        updateStarted={updateStarted}
      />
    </FlexContainer>
  )
}

const Contact = ({ contact, handleEditClick }) => (
  <Card
    noHover
    override='min-width: 500px;'
    width='650px'
    height='max-width: 1245px;'
    maxHeight='250px'
    padding='12px 15px'>
    <FlexContainer height='35px' direction='row'>
      <FlexContainer justifyContent='flex-start'>
        <Body color='foreground' margin='0px' fontSize='18px'>
          {contact.fullname}
        </Body>
        <Body margin='3px 0 0 10px'>{contact.jobTitle}</Body>
      </FlexContainer>
      <IconButton onClick={() => handleEditClick(contact)}>
        <BsPencil className='iconButton pull-right' />
      </IconButton>
    </FlexContainer>
    <HrLineBreak width='100%' />
    <FlexContainer flexDirection='row' justifyContent='flex-start' alignItems='top'>
      <Container>
        <Body color='foreground' fontWeight='500' margin='0'>
          Contact
        </Body>
        <FlexContainer alignItems='baseline' justifyContent='flex-start'>
          <a href={`mailto:${contact.email}`} target='_blank'>
            <ContactIcon color='secondary'>
              <FaEnvelope />
            </ContactIcon>
          </a>
          <a href={`mailto:${contact.email}`} target='_blank'>
            <Body
              override={`
                                overflow: hidden;
                                text-overflow: ellipsis;
                                max-width: 252px;`}
              color='foreground'
              margin='0px 10px 10px 10px'
              border='padding-left: 10px; border-left: solid 1px #EAE6E3;'>
              {contact.email}
            </Body>
          </a>
        </FlexContainer>
        <FlexContainer alignItems='baseline' justifyContent='flex-start'>
          <a href={`tel:${contact.phonenumber.replace(/[-.()]/g, '')}`} target='_blank'>
            <ContactIcon color='success'>
              <FaPhone />
            </ContactIcon>
          </a>
          <a href={`tel:${contact.phonenumber.replace(/[-.()]/g, '')}`} target='_blank'>
            <Body
              color='foreground'
              margin='0px 10px 10px 10px'
              border='padding-left: 10px; border-left: solid 1px #EAE6E3;'>
              {contact.phonenumber}
            </Body>
          </a>
        </FlexContainer>
      </Container>
      <Container>
        <Body color='foreground' fontWeight='500' margin='0'>
          Notification Group(s)
        </Body>
        <FlexContainer alignItems='baseline' justifyContent='flex-start' addProps={`flex-wrap: wrap;`}>
          {contact.notificationGroups?.map((userGroup, index) => (
            <GroupTag key={`${userGroup}-${index}`}>
              <GroupTagSpan>{userGroup}</GroupTagSpan>
            </GroupTag>
          ))}
        </FlexContainer>
      </Container>
    </FlexContainer>
  </Card>
)

const EditContact = props => {
  const {
    editing,
    contact,
    updateStarted,
    handleCloseClick,
    handleSaveClick,
    handleRemoveContactClick,
  } = props

  const [fullname, setFullname] = useState(contact.fullname)
  useEffect(() => {
    contact.fullname = fullname
  }, [fullname])

  const [jobTitle, setJobTitle] = useState(contact.jobTitle)
  useEffect(() => {
    contact.jobTitle = jobTitle
  }, [jobTitle])

  const [email, setEmail] = useState(contact.email)
  useEffect(() => {
    contact.email = email
  }, [email])

  const [phonenumber, setPhonenumber] = useState(contact.phonenumber)
  useEffect(() => {
    contact.phonenumber = phonenumber
  }, [phonenumber])

  return (
    <Card
      noHover
      width='650px'
      borderRadius='5px'
      style={{ maxWidth: '1245px' }}
      maxHeight='450px'
      margin='2px'
      padding='15px'>
      <FlexContainer direction='column' justifyContent='flex-start'>
        <FlexContainer>
          <Body color='foreground' margin='0px 10px 5px 6px'>
            <b>{editing ? 'EDIT' : 'ADD NEW'}</b> CONTACT
          </Body>
          <IconButton onClick={() => handleCloseClick(editing)}>
            <FaTimes className='closeButton' />
          </IconButton>
        </FlexContainer>
        <FlexContainer alignItems='baseline' justifyContent='flex-start'>
          <Input
            margin='0px 6px 6px 0px'
            variant='main'
            placeholder='Full Name'
            value={fullname}
            onChange={e => setFullname(e.target.value)}
          />
          <Input
            placeholder='Job Title'
            variant='main'
            value={jobTitle}
            onChange={e => setJobTitle(e.target.value)}
          />
        </FlexContainer>
      </FlexContainer>
      <HrLineBreak />
      <FlexContainer direction='row' alignItems='top'>
        <FlexContainer direction='column' className='contact-column-container'>
          <FlexContainer padding='5px 15px 0px' alignItems='baseline'>
            <Body color='foreground' fontWeight='500'>
              Contact
            </Body>
            <span />
          </FlexContainer>
          <FlexContainer padding='5px 15px 0px' alignItems='baseline' justifyContent='flex-start'>
            <span>
              <FaEnvelope />
            </span>
            <Body
              color='foreground'
              margin='0px 10px 10px 10px'
              border={`padding-left: 10px; border-left: solid 1px #dedede;`}>
              <Input
                placeholder='Email'
                variant='main'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Body>
            <span />
          </FlexContainer>
          <FlexContainer padding='5px 15px 0px' alignItems='baseline' justifyContent='flex-start'>
            <span>
              <FaPhone />
            </span>
            <Body
              color='foreground'
              margin='0px 10px 10px 10px'
              border={`padding-left: 10px; border-left: solid 1px #dedede;`}>
              <Input
                placeholder='Phone Number'
                variant='main'
                value={phonenumber}
                onChange={e => setPhonenumber(e.target.value)}
              />
            </Body>
            <span />
          </FlexContainer>
        </FlexContainer>
        <EditNotificationTags contact={contact} />
      </FlexContainer>
      {editing ? (
        <FlexContainer padding='5px 0px 0px 10px' alignItems='baseline' justifyContent='flex-end'>
          <Button onClick={handleRemoveContactClick} color='error' hover='lightest'>
            Remove Contact
          </Button>
          <ButtonSpinner
            className='btn das-primary-bg text-white'
            spin={updateStarted}
            spinContent={<SavingText>Saving..</SavingText>}
            onClick={() => handleSaveClick(contact)}>
            Save
          </ButtonSpinner>
        </FlexContainer>
      ) : (
        <FlexContainer padding='5px 0px 0px 10px' alignItems='baseline' justifyContent='flex-end'>
          <Button onClick={() => handleCloseClick(editing)} color='background' variant='main'>
            Cancel
          </Button>
          <ButtonSpinner
            className='btn das-primary-bg text-white'
            spin={updateStarted}
            spinContent={<SavingText>Saving..</SavingText>}
            onClick={() => handleSaveClick(contact)}>
            Add New
          </ButtonSpinner>
        </FlexContainer>
      )}
    </Card>
  )
}

const EditNotificationTags = ({ contact }) => {
  const [updatedGroups, setGroups] = useState(contact.notificationGroups)
  const [groupName, setGroupName] = useState('')

  const GroupTagItem = ({ text, click }) => (
    <GroupTag onClick={click}>
      <GroupTagSpan>{text}</GroupTagSpan>
      <GroupTagSpan>
        <FaTimes className='closeButton' />
      </GroupTagSpan>
    </GroupTag>
  )

  const handleAddGroupClick = () => {
    if (groupName.length > 0) {
      let groups = [...updatedGroups, groupName]
      contact.notificationGroups = groups
      setGroups(groups)
      setGroupName('')
    }
  }

  return (
    <FlexContainer direction='column' justifyContent='flex-start'>
      <FlexContainer padding='5px 15px 0px' alignItems='baseline'>
        <Body color='foreground' fontWeight='500' margin='0' padding='0 0 14px 0'>
          Notification Group(s)
        </Body>
        <span />
      </FlexContainer>
      <FlexContainer>
        <Input
          placeholder='Add Group'
          variant='main'
          value={groupName}
          onChange={e => setGroupName(e.target.value)}
          width='100%'
        />
        <Button color='secondary' variant='main' onClick={handleAddGroupClick}>
          Add
        </Button>
      </FlexContainer>
      <FlexContainer
        padding='5px 15px 0px'
        alignItems='baseline'
        addProps={`flex-wrap: wrap;`}
        justifyContent='flex-start'>
        {updatedGroups.length > 0 ? (
          updatedGroups.map((userGroup, index) => (
            <GroupTagItem
              key={`edit-${userGroup}-${index}`}
              text={userGroup}
              click={() => {
                contact.notificationGroups = updatedGroups.filter(group => group !== userGroup)
                setGroups(updatedGroups.filter(group => group !== userGroup))
              }}
            />
          ))
        ) : (
          <em>No Groups</em>
        )}
      </FlexContainer>
    </FlexContainer>
  )
}

const DeleteContactModal = ({ show, updateStarted, contact, onClose, onDelete }) => (
  <Modal show={show} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
    <Modal.Header closeButton>
      <Modal.Title id='contained-modal-title-vcenter'>Delete Contact</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Are you sure you want to delete this contact? This will permanently delete <b>{contact.fullname}</b>{' '}
        contact
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='secondary' onClick={onClose}>
        Close
      </Button>
      <ButtonSpinner
        className='btn das-primary-bg text-white'
        spin={updateStarted}
        spinContent={<SavingText>Deleting..</SavingText>}
        onClick={() => onDelete(contact)}>
        Delete Contact
      </ButtonSpinner>
    </Modal.Footer>
  </Modal>
)

const mapStateToProps = state => {
  return {
    account: state.account.account,
    updateStarted: state.account.updateStarted,
    updatedAccount: state.account.updatedAccount,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveAccount: (clientId, account) => {
      dispatch(updateAccountStartedAction())
      dispatch(updateAccountCompletedAction(clientId, account))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts)
