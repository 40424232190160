import styled from 'styled-components'

export const Line = styled.div`
  padding: 10px;
  margin-left: 10px;
  background-color: ${props => props.theme.background.dark};
  border-radius: 5px;
  font-size: 13px;
  color: ${props => props.theme.text.primary};

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    float: left;
  }

  span {
    font-size: 16px;
  }
`
