import { createAction } from 'redux-actions'
import ReviewServiceFactory, { RadarServices } from '../../services'

export const ActionTypes = {
  REVIEWS_NAVBAR_LOAD_FOLDERS: 'REVIEWS_NAVBAR_LOAD_FOLDERS',
  REVIEWS_NAVBAR_LOAD_FOLDERS_COMPLETED: 'REVIEWS_NAVBAR_LOAD_FOLDERS_COMPLETED',
  REVIEWS_NAVBAR_FOLDER_SELECTED: 'REVIEWS_NAVBAR_FOLDER_SELECTED',
  REVIEWS_NAVBAR_FILTER_SELECTED: 'REVIEWS_NAVBAR_FILTER_SELECTED',
  REVIEWS_FILTERS_SELECTED: 'REVIEWS_FILTERS_SELECTED',
  REVIEWS_FILTERSITEM_FORALL_SECTION_SELECTED: 'REVIEWS_FILTERSITEM_FORALL_SECTION_SELECTED',
  REVIEWS_FILTERS_CLEAR_REVIEWS: 'REVIEWS_FILTERS_CLEAR_REVIEWS',
  REVIEWS_FETCH_PAGED_REVIEWS: 'REVIEWS_FETCH_PAGED_REVIEWS',
}

export const ActionIDs = {
  MY_QUEUE: 'MY_QUEUE',
  EXPIRED_PROPOSALS: 'EXPIRED_PROPOSALS',
  PROPOSED: 'PROPOSED',
  ESCALATED: 'ESCALATED',
  DISPUTED: 'DISPUTED',
  DONE: 'DONE',
}

//---------------------------- Reviews List Actions

export const pagedItemSelectionAction = createAction(ActionTypes.REVIEWS_FETCH_PAGED_REVIEWS, page => ({
  page,
}))

//---------------------------- Navbar Filter Actions

export const navbarFiltersSelectedAction = createAction(
  ActionTypes.REVIEWS_NAVBAR_FILTER_SELECTED,
  active => ({ active })
)

export const navbarFolderSelectedAction = createAction(
  ActionTypes.REVIEWS_NAVBAR_FOLDER_SELECTED,
  (folderId, page, pageSize, query) => ({ folderId, page, pageSize, query })
)

export const navbarFoldersLoadAction = createAction(ActionTypes.REVIEWS_NAVBAR_LOAD_FOLDERS)

export const navbarFoldersLoadActionCompletedAction = createAction(
  ActionTypes.REVIEWS_NAVBAR_LOAD_FOLDERS_COMPLETED,
  async query => {
    let service = ReviewServiceFactory.create(RadarServices.Reviews),
      result = await service.getFolders(query)
    if (result.Ok) return { folders: result.Content }

    // TODO: We to do something with errors
    return null
  }
)

//---------------------------- Review Filters Actions

export const reviewsFiltersSelectedAction = createAction(
  ActionTypes.REVIEWS_FILTERS_SELECTED,
  (filters, filterQuery, page, pageSize) => ({ filters, filterQuery, page, pageSize })
)

export const clearFilteredReviewsAction = createAction(
  ActionTypes.REVIEWS_FILTERS_CLEAR_REVIEWS,
  filters => ({ filters })
)
