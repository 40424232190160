import styled from 'styled-components'
import Title from "../common/Title";
import BodyText from "../common/BodyText";
export const TitleBox = styled.div`
  display: inline-block;
  padding: 5px 0px;

  > p {
    color: rgb(144, 141, 138);
    font-size: 14px;
    line-height: 2;
  }
`

export const Header = styled(Title)`
  font-size: 1.4em;
  padding-bottom: 10px;
`

export const Body = styled(BodyText)`
  line-height: 1.5;
`