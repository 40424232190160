import RadarSession from './session'
import { Client } from '@microsoft/microsoft-graph-client'

export default class UserGraph {
    bearerToken
    graph

    constructor() {
        let session = new RadarSession(),
            state = session.getSessionState()
        if (state) {
            this.bearerToken = state.accessToken
            //Todo: should check
            this.graph = Client.initWithMiddleware({ authProvider: this })
        }
    }

    getAccessToken() {
        return new Promise(resolve => {
            resolve(this.bearerToken)
        })
    }

    api = path => this.graph?.api(path)

    async getUsers(name) {
        try {
            let response = await this.graph?.api(`/me/people/?$search=${name}`).get()
            return { Ok: true, Content: response.value || [] }
        } catch (err) {
            return { Ok: false, Content: err }
        }
    }

    async getPhoto(userId) {
        if (!userId) return null;
        let url = userId ? `/users/${userId}/photo/$value` : '/me/photos/64x64/$value'
        return await this.graph?.api(url).get()
    }
}
