import Service from './service'
import Globals from './global'

class KeywordService extends Service {
  async getKeywordCategories() {
    let retval = {},
      url = Globals.toURL('/keywords/categories')
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getKeywords() {
    let retval = {},
      url = Globals.toURL('/keywords')
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async saveKeyword(id, keyword) {
    let retval = {},
      url = Globals.toURL(`/keywords/${id}`)
    try {
      retval = await this.put(url, keyword)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async createKeyword(keyword) {
    let retval = {},
      url = Globals.toURL(`/keywords`)
    try {
      retval = await this.post(url, keyword)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async deleteKeyword(id) {
    let retval = {},
      url = Globals.toURL(`/keywords/${id}`)
    try {
      retval = await this.delete(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async scoreKeyword(keyword) {
    let retval = {},
      url = Globals.toURL(`/keywords/score?keyword=${keyword}`)
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
}

const KeywordServiceFactory = function () {}
KeywordServiceFactory.prototype.create = () => new KeywordService()

export default KeywordServiceFactory
