import styled from 'styled-components'
import Button from '../../common/Button'

const shadow = 'hsla(218, 50%, 10%, 0.1)'

export const Menu = styled.div`
  background-color: ${props => props.theme.background.main};
  border-radius: 4;
  box-shadow: 0 0 0 1px ${shadow}, 0 4px 11px ${shadow};
  margin-top: 8;
  position: absolute;
  z-index: 2;
`

export const Blanket = styled.div`
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1;
`

export const DropdownIndicator = styled.div`
  color: ${props => props.theme.foreground.lighter};
  height: 24px;
  width: 32px;
`

export const Svg = styled.svg`
  margin-right: -6;
`

export const Dropdown = styled.div`
  position: relative;
`

export const NotificationItemButton = styled(Button)`
  padding: 7px 8px 8px;
  margin: 0 0 0 10px;
  display: inline-block;
  border-radius: 3px 0 0 3px;
`

export const NotificationItemDropdownButton = styled(Button)`
  padding: 7px 0px 5px;
  margin: 0 0 0 1px;
  display: inline-block;
  border-radius: 0 3px 3px 0;
  width: 30px;
`
