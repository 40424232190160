import Service from './service'
import Globals from './global'

class ReviewSiteService extends Service {
  async getReviewSites() {
    let retval = {},
      url = Globals.toURL('/reviewsites')
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async saveReviewSites(reviewsites) {
    let retval = {},
      url = Globals.toURL('/reviewsites')
    try {
      retval = await this.put(url, reviewsites)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
}

const ReviewSiteServiceFactory = function () {}
ReviewSiteServiceFactory.prototype.create = () => new ReviewSiteService()

export default ReviewSiteServiceFactory
