import 'bootstrap/dist/css/bootstrap.css'
import flagsmith from 'flagsmith'
import './css/das.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, ReactReduxContext } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import * as FullStory from '@fullstory/browser'
import App from './App'
import configureStore from './store/configureStore'
import { FlagsmithProvider } from 'flagsmith/react'
// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const history = createBrowserHistory({ basename: baseUrl })
window.History = history
window.ApiUrl = window.location.origin + '/api/v1/'

// Set the environment for radar
let env=process.env.REACT_APP_ENV
let flagsmithEnvId=process.env.REACT_APP_FLAGSMITHKEY
if (env && env.length > 0) {
    window.RADAR_ENV = env
}

const store = configureStore(history)
const rootElement = document.getElementById('root')

// Full Story Integration
FullStory.init({ orgId: '13E4CE' })
FullStory.shutdown()
ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <ConnectedRouter history={history}>
      <FlagsmithProvider  options={{
        environmentID: flagsmithEnvId,
        api:'https://edge.api.flagsmith.com/api/v1/'
      }} api='https://edge.api.flagsmith.com/api/v1/' flagsmith={flagsmith}>
        <App />
      </FlagsmithProvider>
    </ConnectedRouter>
  </Provider>,
  rootElement
)
