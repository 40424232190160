import styled from 'styled-components'
import Input from '../../../common/Input'

export const FilterContainer = styled.div`
  min-height: 10px;
  height: 300px;
  overflow-y: scroll;
  margin: 12px 0;
  padding-bottom: 48px;
`

export const FilterItem = styled.div`
  padding: 12px;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.background.dark};
  }

  h5 {
    font-size: 15px;
    font-weight: 500;
    margin: 0 12px 0 0;
  }

  && p {
    margin-top: 8px;
    margin-bottom: 0;
    white-space: pre-wrap;
  }
`

export const SearchInputContainer = styled.div`
  position: relative;

  svg {
    color: ${props => props.theme.primary.main};
    font-size: 1em;
    position: absolute;
    top: 25%;
    margin-left: 10px;
  }

  input {
    margin-top: 0px;
  }
`

export const SearchInput = styled(Input)`
  display: block;
  width: 100%;
  margin: 10px 0;
  padding-left: 36px;
`

export const Tag = styled.span`
  background-color: ${props => {
    switch (props.sentiment) {
      case 'Positive':
        return props.theme.success.main
      case 'Negative':
        return props.theme.error.main
      case 'Neutral':
        return props.theme.warning.light
      default:
        return props.theme.primary.main
    }
  }};
  border-radius: 15px;
  color: #fff;
  padding: 3px 8px;
  font-size: 10px;
  text-align: center;
  margin-right: 5px;
`
