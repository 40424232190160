import reduceReducers from 'reduce-reducers'
import { handleAction } from 'redux-actions'
import { ActionTypes } from '../actions/notifications'

const defaultState = {}

const handleAddNotificationReducer = handleAction(
  ActionTypes.NOTIFICATION_ADD_NOTIFICATION,
  {
    next(state, action) {
      return {
        ...state,
        notification: {
          type: action.payload.type,
          message: action.payload.message,
          timestamp: new Date().now,
        },
      }
    },
  },
  defaultState
)

const handleDismissNotificationReducer = handleAction(
  ActionTypes.NOTIFICATION_DISMISS_NOTIFICATION,
  {
    next(state, action) {
      return {
        ...state,
        notification: null,
      }
    },
  },
  defaultState
)

const initialState = {
  notification: null,
}

export default () =>
  reduceReducers(initialState, handleAddNotificationReducer, handleDismissNotificationReducer)
