import Service from './service'
import Globals from './global'

class ClientContactsService extends Service {
  async getContactsByClient(id) {
    let retval = {},
      url = Globals.toURL(`/configurations/${id}/clients/contacts`)
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
}

const ClientContactsServiceFactory = function () {}
ClientContactsServiceFactory.prototype.create = () => new ClientContactsService()

export default ClientContactsServiceFactory
