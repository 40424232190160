import Service from './service'
import Globals from './global'

class ConfigurationsService extends Service {
  async getAuthSettings() {
    let retval = {},
      url = Globals.toURL('/configuration')
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
}

const ConfigurationsServiceFactory = function () {}
ConfigurationsServiceFactory.prototype.create = () => new ConfigurationsService()

export default ConfigurationsServiceFactory
