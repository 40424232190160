import Service from './service'
import Globals from './global'

class TagService extends Service {
  async createTag(tag) {
    let retval = {},
      url = Globals.toURL(`/tags`)
    try {
      retval = await this.post(url, tag)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getTags(criteria) {
    let retval = {},
      url = Globals.toURL(`/tags`, criteria)
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getTagIds() {
    let retval = {},
      url = Globals.toURL('/tags/ids')
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getTagsByClientId(clientId) {
    let retval = {},
      url = Globals.toURL(`/tags/client?clientId=${clientId}`)
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async updateTag(tag) {
    let retval = {},
      url = Globals.toURL(`/tags/${tag.tagId}`)
    try {
      retval = await this.put(url, tag)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async deleteTag(tagId) {
    let retval = {},
      url = Globals.toURL(`/tags/${tagId}`)
    try {
      retval = await this.delete(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
}

const TagServiceFactory = function () {}
TagServiceFactory.prototype.create = () => new TagService()

export default TagServiceFactory
