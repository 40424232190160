import Service from './service'
import Globals from './global'

class EmailTemplatesService extends Service {
  async createTemplate(template) {
    let retval = {},
      url = Globals.toURL('/templates/email')
    try {
      retval = await this.post(url, template)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getTemplate(id) {
    let retval = {},
      url = Globals.toURL(`/templates/email/${id}`)
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getTemplates(page, count) {
    let retval = {},
      url = Globals.toURL('/templates/email', { page, count })
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async getTemplateIds() {
    let retval = {},
      url = Globals.toURL('/templates/emails/ids')
    try {
      retval = await this.get(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async updateTemplate(template) {
    let retval = {},
      url = Globals.toURL(`/templates/email/${template.templateId}`)
    try {
      retval = await this.put(url, template)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }

  async deleteTemplate(template) {
    let retval = {},
      url = Globals.toURL(`/templates/email/${template.templateId}`)
    try {
      retval = await this.delete(url)
    } catch (e) {
      console.error(e)
      console.error(`${url} failed with: ${e.Message}`)
    }
    return retval
  }
}

const EmailTemplatesServiceFactory = function () {}
EmailTemplatesServiceFactory.prototype.create = () => new EmailTemplatesService()

export default EmailTemplatesServiceFactory
