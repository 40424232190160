import reduceReducers from 'reduce-reducers'
import { handleAction } from 'redux-actions'
import { ActionTypes } from '../actions/response-templates'

const defaultState = {}

const handlePageSelectedReducer = handleAction(
  ActionTypes.RESPONSE_TEMPLATE_PAGE_SELECTED,
  {
    next(state, action) {
      return {
        ...state,
        page: action.payload.page,
      }
    },
  },
  defaultState
)

const initialState = {
  page: 1,
  pageSize: 1000,
}

export default () => reduceReducers(initialState, handlePageSelectedReducer)
