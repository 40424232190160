import React from 'react'
import Button from '@mui/material/Button'
import { Spinner } from './ButtonSpinner.S'

function ButtonSpinner(props) {
  const { spin, spinContent, spinnerProps, children } = props
  const buttonProps = {}
  Object.entries(props)
    .filter(a => !['spin', 'spinContent', 'spinnerProps', 'children'].includes(a[0]))
    .forEach(a => (buttonProps[a[0]] = a[1]))

  return (
    <Button {...buttonProps}>
      {spin ? (
        <>
          <Spinner {...spinnerProps} />
          {spinContent}
        </>
      ) : (
        <>{children}</>
      )}
    </Button>
  )
}

export default ButtonSpinner
