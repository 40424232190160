import React from 'react'
import { makeStyles } from '@mui/styles'
import CardContent from '@mui/material/CardContent'
import MomentUtils from '@date-io/moment'
import { FilterCard, FilterCardHeadar, useCardStyles } from './Card'
import FlexContainer from '../FlexContainer'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiInput-input': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  span: {
    color: `${theme.palette.primary.main} !important`,
    fontSize: '16px',
    fontWeight: 'bold',
  },
}))

export default function ({ title, selected, onFilterChanged }) {
  const classes = useStyles()
  const cardClasses = useCardStyles()

    const handleClearAllClick = () => onFilterChanged({ startDate: null, endDate: null, startDateActual:null,endDateActual:null })

  const handleStartDateChange = date => {
       let actualDate = date;
      if (date) date = date.format('YYYY-MM-DDT00:00:00')
      console.log(actualDate);
      onFilterChanged({ startDate: date, startDateActual: actualDate, endDate: selected.endDate, endDateActual: selected.endDateActual })
  }

    const handleEndDateChange = date => {
    let actualDate = date;
    if (date) date = date.format('YYYY-MM-DDT23:59:59')
        onFilterChanged({ startDate: selected.startDate, startDateActual: selected.startDateActual, endDate: date, endDateActual: actualDate })
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <FilterCard className={cardClasses.card}>
        <FilterCardHeadar title={title} selectAll={false} onActionClick={handleClearAllClick} />
        <CardContent className={cardClasses.cardContent}>
          <FlexContainer justifyContent='start'>
            <DatePicker
              label='From'
              value={selected.startDateActual}
              onChange={handleStartDateChange}
              inputFormat='MM/DD/yyyy'
              renderInput={params => <TextField {...params} />}
            />
            &nbsp;&nbsp;
            <DatePicker
              label='To'
              value={selected.endDateActual}
              onChange={handleEndDateChange}
              inputFormat='MM/DD/yyyy'
              renderInput={params => <TextField {...params} />}
            />
          </FlexContainer>
        </CardContent>
      </FilterCard>
    </LocalizationProvider>
  )
}
