import styled from 'styled-components'

// The sentiment span receieves the array of sentiments
// that should ONLY include 1 item, and then we compute
// the color & text for display. -Bryan Mierke 12/17/18
export const SentimentSpan = styled.span`
  padding: 0px;
  color: ${props => props.theme.background.main};
  border-radius: 15px;
  padding: 4px 16px;
  margin-right: 10px;
  background-color: ${props => {
    if (props.sentiment === 'Negative') {
      return props.theme.error.main
    } else if (props.sentiment === 'Neutral') {
      return props.theme.warning.main
    } else if (props.sentiment === 'Positive') {
      return props.theme.success.main
    }
  }};
  &::after {
    content: '${props => props.sentiment}';
  }
`

export const Textarea = styled.textarea`
  border: 1px solid ${props => props.theme.foreground.lightest};
  background-color: ${props => props.theme.background.main};
  color: ${props => props.theme.text.primary};
  display: ${props => (props.display ? props.display : 'block')};
  font-size: ${props => (props.fontSize ? props.fontSize : '16.5px')};
  padding: ${props => (props.padding ? props.padding : '12px 0px 0px 16px')};
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '200px')};
  margin: ${props => (props.margin ? props.margin : 'inherit')};
  resize: ${props => (props.resize ? props.resize : 'none')};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  &:active,
  &:focus {
    outline: 1px solid ${props => props.theme.secondary.main};
  }
  &:invalid {
    background-color: ${props => props.theme.error.lightest};
    border: 3px solid ${props => props.theme.error.dark};
  }
`

export const ItemContainer = styled.div`
  padding: 0px;
  height: calc(100vh - 325px) !important;
  overflow-y: auto;
  width: auto;
  margin-top: 5px;
`
