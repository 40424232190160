import React from 'react'
import ButtonContainer from './Button.S'

function Button(props) {
  const {
    margin,
    padding,
    fontSize,
    className,
    children,
    color,
    variant,
    hover,
    onClick,
    disabled,
    override,
    width,
    height,
    id,
  } = props

  return (
    <ButtonContainer
      id={id}
      margin={margin}
      padding={padding}
      fontSize={fontSize}
      className={className}
      color={color || 'primary'}
      variant={variant}
      hover={hover}
      onClick={onClick}
      disabled={disabled}
      override={override}
      width={width}
      height={height}>
      {children}
    </ButtonContainer>
  )
}

export default Button
