import styled from 'styled-components'

export const Banner = styled.div`
  background-color: red;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

export const Errors = styled.pre`
  padding: 10px;
  color: #fff;
  overflow: scroll-y;
  height: 400px;
`

export const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;

  & * {
    color: #fff;
    cursor: pointer;
  }
`
