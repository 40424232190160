import styled from 'styled-components'

export const Tabs = styled.ul`
  padding-left: 20px;
  padding-top: 20px;
  z-index: 1;
`

export const TabItem = styled.li`
  height: 40px;
  z-index: 1;
  cursor: pointer;
`

export const Nav = styled.nav`
  position: relative;
  display: flex;
  flex-flow: row nowrap !important;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${props => props.theme.background.dark};
  height: 60px;
`
