import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Manager, Reference, Popper } from 'react-popper'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import Avatar from '@mui/material/Avatar'
import { Link } from 'react-router-dom'
import { FaSignOutAlt } from 'react-icons/fa'
import { userLogoutAction } from '../../store/actions/user'
import { AccountContainer, AccountMenu } from './Account.S'
import FeatureFlag from '../common/FlagFeature'
import FullStoryIndicator from '../common/FullStory'
import UserGraph from '../../lib/msgraph'

function Account({ user, notifyUserLogout, userLoggedIn, normalMode }) {
  const [accountRef, setAccountRef] = useState()
  const [popperRef, setPopperRef] = useState()
  const [expanded, setExpanded] = useState(false)
  const [profileUrl, setProfileUrl] = useState('')
  const [fullStoryEnabled, setFullStoryEnabled] = useState(false)
  const [borderColor, setBorderColor] = useState('#ce751d')

  const getPhoto = async () => {
    let graph = new UserGraph()
    let img = await graph.getPhoto()
    if (img) setProfileUrl(URL.createObjectURL(img))
  }

  const handleClose = () => {
    setExpanded(false)
    notifyUserLogout()
  }

  const getNameInitials = () => {
    if (user.fullname) {
      let names = user.fullname.split(' '),
        firstL = names[0].substring(0, 1).toUpperCase(),
        lastL = names[names.length - 1].substring(0, 1).toUpperCase()
      return <span>{names.length > 1 ? firstL + lastL : firstL}</span>
    }
    return <></>
  }

  const handleHasFeature = enabled => setFullStoryEnabled(enabled)

  useEffect(() => {
    function handleClickOutside(event) {
      if (accountRef && !accountRef.contains(event.target) && popperRef && !popperRef.contains(event.target))
        setExpanded(false)
    }

    document.addEventListener('mousedown', handleClickOutside)

    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  useEffect(() => {
    getPhoto()
  }, [userLoggedIn])

  useEffect(() => {
    setBorderColor(normalMode ? '#ce751d' : '#FFF')
  }, [normalMode])

  return (
    <>
      <FeatureFlag feature='fullstory' onHasFeature={handleHasFeature}>
        <FullStoryIndicator enable={userLoggedIn} userId={user.username} displayName={user.fullname} />
      </FeatureFlag>

      <Manager>
        <Reference>
          {({ ref }) => (
            <AccountContainer ref={ref}>
              <div ref={setAccountRef} className='d-flex' onClick={() => setExpanded(!expanded)}>
                <Avatar
                  src={profileUrl}
                  style={{
                    color: '#FFF',
                    backgroundColor: '#FE9143',
                    border: fullStoryEnabled ? `2px solid ${borderColor}` : 'none',
                  }}>
                  {getNameInitials()}
                </Avatar>
                <div className='d-flex align-items-center p-2'>
                  <div className='pr-1 ' style={{ cursor: 'pointer' }}>
                    {user.fullname}
                  </div>
                  {!expanded ? <IoIosArrowDown /> : <IoIosArrowUp />}
                </div>
              </div>
            </AccountContainer>
          )}
        </Reference>
        {expanded && (
          <Popper placement='bottom'>
            {({ ref, style, placement, arrowProps }) => (
              <AccountMenu ref={ref} data-placement={placement} style={{ ...style }}>
                <div className='mt-1 p-1' ref={setPopperRef}>
                  <div className='p-1'>
                    <Link to={'/logout'} onClick={handleClose}>
                      <FaSignOutAlt color={'rgb(239, 126, 45)'} />
                      <span className='pl-1 text-secondary'>Log out</span>
                    </Link>
                  </div>
                </div>
                <div className='arrow' ref={arrowProps.ref} style={arrowProps.style} />
              </AccountMenu>
            )}
          </Popper>
        )}
      </Manager>
    </>
  )
}

function mapStateToProps(state) {
  return {
    userLoggedIn: state.user.loggedIn,
    user: state.user.profile || {},
    normalMode: state.user.normalMode,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    notifyUserLogout: () => dispatch(userLogoutAction()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)
