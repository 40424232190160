import './ProgressBar.css'
import React, { useEffect } from 'react'
import NProgress from 'nprogress'

export default function PageProgressBar() {
  useEffect(() => {
    NProgress.configure({ showSpinner: false })
    NProgress.start()

    return () => NProgress.done()
  }, [])

  return <></>
}
