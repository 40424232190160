import styled from 'styled-components'
import Title from '../common/Title'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(theme => ({
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
  keywordChip: {
    margin: theme.spacing(0.5),
    fontWeight: 600,
  },
  keywordChipColor: {
    backgroundColor: `${theme.palette.error.main} !important`,
    color: '#FFF',
  },
  categoryChip: {
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  headerChip: {
    color: '#FFF',
    backgroundColor: theme.palette.error.main,
  },
  button: {
    color: '#FFF',
    width: '100%',
  },
  textField: {
    width: '100%',
  },
  avatar: {
    color: '#FFF !important',
    marginLeft: '5px',
  },
}))

export const TitleBox = styled.div`
  display: inline-block;
  padding: 5px 0px;

  > p {
    color: rgb(144, 141, 138);
    font-size: 14px;
    line-height: 2;
  }
`

export const Header = styled(Title)`
  font-size: 1.4em;
  padding-bottom: 10px;
`

export const EditorContainer = styled.div`
  border: solid 1px
    ${props => (props.theme.type === 'dark' ? props.theme.foreground.light : props.theme.foreground.lighter)};
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 10px;
  margin-right: 0px;
  padding: 15px;
  background: ${props =>
    props.theme.type === 'dark' ? props.theme.background.dark : props.theme.background.main};

  .header {
    position: relative;
    > span {
      margin-bottom: 5px;
      font-weight: bolder;
      text-transform: uppercase;
      color: ${props => props.theme.text.primary};
      opacity: 0.5;
    }

    > .closeButton {
      position: absolute;
      top: 0px;
      right: 0px;
      color: gray;
      border: none;
      font-weight: bold;
      font-size: 20px;

      &:hover {
        color: rgb(254, 145, 67);
        cursor: pointer;
      }
    }
  }
`
export const SavingText = styled.div`
  color: #fff;
  padding-top: 1px;
`

export const ScoreContainer = styled.div`
  background-color: ${props => props.color};
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  color: #fff;
  font-weight: 600;
  height: 40px;
  width: 200px !important;
  padding: 5px;
`
