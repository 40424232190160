import * as msal from '@azure/msal-browser'
import RadarServiceFactory, { RadarServices } from '../services'

const StorageConfig = 'authconfig'
const StorageAccount = 'authAccount'

export default class RadarAuth {
  auth
  account

  constructor() {
    let config = JSON.parse(localStorage.getItem(StorageConfig))
    if (config) this.auth = new msal.PublicClientApplication(config)

    this.account = this._getFromStorage(StorageAccount)
  }

  handleRedirect(handleAuthentication) {
    return this.auth
      .handleRedirectPromise()
      .then(response => {
        //console.log(response)
        this._handleRedirect(response, handleAuthentication)
      })
      .catch(err => {
        console.error(err)
      })
  }

  async signIn() {
    await this._initialize()
    this.auth.loginRedirect({
      scopes: ['user.read'],
    })
  }

  signOut() {
    localStorage.clear()
  }

  async checkSession() {
    if (this.auth && this.account) {
      let request = {
        scopes: ['user.read'],
        account: this.account,
      }

      let token = await this.auth.acquireTokenSilent(request).catch(error => {
        console.warn('silent token acquisition fails. acquiring token using redirect')
        if (error instanceof msal.InteractionRequiredAuthError) {
          return this.auth.acquireTokenRedirect(request)
        } else {
          console.warn(error)
        }
      })
      return {
        Ok: token ? true : false,
        Content: token,
      }
    }

    return { Ok: false }
  }

  _handleRedirect(resp, handleAuthentication) {
    if (resp !== null) {
      this.account = resp.account
      this._saveToStorage(StorageAccount, this.account)
    }

    handleAuthentication(
      resp !== null
        ? {
            Ok: true,
            Content: {
              accessToken: resp.accessToken,
              profile: {
                username: resp.account.username,
                email: resp.idTokenClaims.email,
                fullname: resp.idTokenClaims.name,
              },
            },
          }
        : { Ok: false, Message: 'Failed to authenticate with active directory' }
    )
  }

  async _initialize() {
    if (!this.auth) {
      let service = RadarServiceFactory.create(RadarServices.Auth)
      let result = await service.getAuthSettings()
      if (result) {
        const config = {
          auth: result.Content,
          cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
          },
        }
        this.auth = new msal.PublicClientApplication(config)
        this._saveToStorage(StorageConfig, config)
      }
    }
  }

  _saveToStorage(key, content) {
    localStorage.setItem(key, JSON.stringify(content))
  }

  _getFromStorage(key) {
    let json = localStorage.getItem(key)
    return json ? JSON.parse(json) : null
  }
}
