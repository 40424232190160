import React, { useState } from 'react'
import { Transition, animated } from 'react-spring/renderprops.cjs'
import { Divider, Menu, MenuItem, useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import ButtonSpinner from '../../common/ButtonSpinner'
import FlexContainer from '../../common/FlexContainer'
import { ResolutionMap, ResolutionsButtonContainer, Text } from './ResolutionButtons.S'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { connect } from 'react-redux'
import { addNotificationAction } from '../../../store/actions/notifications'
import { reviewDisplayReasonCommentAction } from '../../../store/actions/review'

function ResolutionButton({ review, updateStarted, onResolutionButtonClick, setDisplayReasonComment }) {
    const [resolutionText, setResolutionText] = useState('')
    const savingButtonStyle = { color: '#FFF', padding: '1px' }
    const spinContent = <div style={savingButtonStyle}>Saving..</div>
    const theme = useTheme()

    const checkResolutionsButtons = resolutions => {
        switch (true) {
            case resolutions.includes('ApproveProposedResponse'):
            case resolutions.includes('RejectProposedResponse'):
            case resolutions.includes('MarkResponseProposed'):
            case resolutions.includes('MarkManuallyPosted'):
            case resolutions.includes('Close'):
            case resolutions.includes('DoNotRespond'):
                return true
            default:
                return false
        }
    }

    const ProposedDisputedButtons = ({ loading, text, acceptResolution, rejectResolution }) => (
        <FlexContainer justifyContent='flex-end'>
            <h4>{text}: </h4>
            <ButtonSpinner
                key={ResolutionMap[acceptResolution].text}
                disableElevation
                style={{
                    color: '#FFF',
                    backgroundColor:
                        theme.palette[ResolutionMap[acceptResolution].color][ResolutionMap[acceptResolution].variant],
                    margin: '4px 0px 6px 4px',
                }}
                variant='contained'
                spin={loading && resolutionText === acceptResolution}
                spinContent={spinContent}
                onClick={() => handlePostResolution(acceptResolution)}>
                {ResolutionMap[acceptResolution].text}
            </ButtonSpinner>
            <ButtonSpinner
                key={ResolutionMap[rejectResolution].text}
                disableElevation
                style={{
                    color: '#FFF',
                    backgroundColor:
                        theme.palette[ResolutionMap[rejectResolution].color][ResolutionMap[rejectResolution].variant],
                    margin: '4px 0px 8px 4px',
                }}
                variant='contained'
                spin={loading}
                spinContent={spinContent}
                onClick={() => handlePostResolution(rejectResolution)}>
                {ResolutionMap[rejectResolution].text}
            </ButtonSpinner>
        </FlexContainer>
    )

    const handlePostResolution = (resolution, reason, popupState) => {
        if (updateStarted) return
        setDisplayReasonComment(false)
        if (popupState) {
            popupState.close()
            setDisplayReasonComment(false)
        }
        setResolutionText(resolution)
        onResolutionButtonClick(resolution, reason)
    }
    return (
        <ResolutionsButtonContainer className='col-md-6'>
            <Transition
                native
                items={review.resolutions}
                from={{ opacity: 0, height: 0, transform: 'scale(1)' }}
                enter={[{ opacity: 1, height: 'auto' }]}
                leave={[{ transform: 'scale(1)', opacity: 0.5 }, { opacity: 0 }, { height: 0 }]}>
                {resolution => props => {
                    if (resolution === "MarkResponseProposed" && review.disputeProposed) return null;
                    ResolutionMap[resolution] && (props.order = ResolutionMap[resolution].order)
                    if (resolution === 'RejectProposedResponse') return null
                    else if (resolution === 'ApproveProposedResponse')
                        return (
                            <animated.span style={props} className='transitiongroup-item'>
                                <ProposedDisputedButtons
                                    acceptResolution='ApproveProposedResponse'
                                    rejectResolution='RejectProposedResponse'
                                    text='PROPOSED'
                                    loading={updateStarted}
                                />
                            </animated.span>
                        )
                    else if (resolution === 'MarkPublishedDirectly') {
                        return (
                            <animated.span style={props} className='transitiongroup-item'>
                                <Button
                                    key={ResolutionMap[resolution].key}
                                    disableElevation
                                    style={{
                                        color: '#FFF',
                                        backgroundColor:
                                            theme.palette[ResolutionMap[resolution].color][ResolutionMap[resolution].variant],
                                        margin: `4px 0px ${review.resolutions.includes('ApproveProposedResponse') ? '6px' : '8px'
                                            } 4px`,
                                    }}
                                    onClick={() => handlePostResolution(resolution)}>
                                    {`Publish to ${review.reviewSite}`}
                                </Button>
                            </animated.span>
                        )
                    } else if (resolution === 'DoNotRespond') {
                        return (
                            <animated.span style={props} className='transitiongroup-item'>
                                {ResolutionMap[resolution] && (
                                    <div>
                                        <PopupState variant='popover' popupId='demo-popup-menu'>
                                            {popupState => (
                                                <React.Fragment>
                                                    <ButtonSpinner
                                                        endIcon={<KeyboardArrowDownIcon />}
                                                        {...bindTrigger(popupState)}
                                                        key={ResolutionMap[resolution].text}
                                                        disableElevation
                                                        style={{
                                                            color: '#FFF',
                                                            backgroundColor:
                                                                theme.palette[ResolutionMap[resolution].color][
                                                                ResolutionMap[resolution].variant
                                                                ],
                                                            margin: `4px 0px ${review.resolutions.includes('ApproveProposedResponse') ? '6px' : '8px'
                                                                } 4px`,
                                                        }}
                                                        variant='contained'
                                                        spin={updateStarted && resolutionText === resolution}
                                                        spinContent={spinContent}
                                                    // onClick={() => handlePostResolution(resolution)}
                                                    >
                                                        {ResolutionMap[resolution].text}
                                                    </ButtonSpinner>
                                                    <Menu {...bindMenu(popupState)}>
                                                        <MenuItem
                                                            onClick={() =>
                                                                handlePostResolution(resolution, 'Response already live', popupState)
                                                            }>
                                                            Response already live
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() =>
                                                                handlePostResolution(resolution, 'Dealer requested no response', popupState)
                                                            }>
                                                            Dealer requested no response
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => handlePostResolution(resolution, 'Old review', popupState)}>
                                                            Old review
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => handlePostResolution(resolution, 'Not Live', popupState)}>
                                                            Not Live
                                                        </MenuItem>
                                                        <MenuItem
                                                            onClick={() => handlePostResolution(resolution, 'No Access - Alert Already Sent Out', popupState)}>
                                                            No Access - Alert Already Sent Out
                                                        </MenuItem>
                                                        <Divider sx={{ my: 0.5 }} />
                                                        <MenuItem
                                                            onClick={() => {
                                                                popupState.close()
                                                                setDisplayReasonComment(true)
                                                            }}>
                                                            Other
                                                        </MenuItem>
                                                    </Menu>
                                                </React.Fragment>
                                            )}
                                        </PopupState>
                                    </div>
                                )}
                            </animated.span>
                        )
                    } else
                        return (
                            <animated.span style={props} className='transitiongroup-item'>
                                {ResolutionMap[resolution] && (
                                    <ButtonSpinner
                                        key={ResolutionMap[resolution].text}
                                        disableElevation
                                        style={{
                                            color: '#FFF',
                                            backgroundColor:
                                                theme.palette[ResolutionMap[resolution].color][ResolutionMap[resolution].variant],
                                            margin: `4px 0px ${review.resolutions.includes('ApproveProposedResponse') ? '6px' : '8px'
                                                } 4px`,
                                        }}
                                        variant='contained'
                                        spin={updateStarted && resolutionText === resolution}
                                        spinContent={spinContent}
                                        onClick={() => handlePostResolution(resolution)}>
                                        {ResolutionMap[resolution].text}
                                    </ButtonSpinner>
                                )}
                            </animated.span>
                        )
                }}
            </Transition>
            {!checkResolutionsButtons(review.resolutions) && <Text>No actions available</Text>}
        </ResolutionsButtonContainer>
    )
}
function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        setDisplayReasonComment: display => {
            dispatch(reviewDisplayReasonCommentAction(display))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResolutionButton)
